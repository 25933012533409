<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'SuccessMessagePaymentFrontOffice' | translate }}</h3>
  </div>

  <div class="row">
    <div class="col">
      <div class="kiosk-container">

        <div class="result-container">

          <p style="text-align: justify;">{{ 'IntroSuccessMessagePaymentFrontOffice' | translate }}</p>

        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <button (click)="finishCheckin()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonFinishFrontOffice' | translate }}</button>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">

    </div>
    <div class="col text-center">

    </div>
  </div>

</div>
