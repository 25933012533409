import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { LocalStorageServiceService } from '../services/localStorageService/local-storage-service.service';
import { KioskTokenService } from '../services/kioskToken/kiosk-token.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication/authentication.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public localStorage: LocalStorageServiceService, public kioskTokenService: KioskTokenService, private authenticationService: AuthenticationService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const $this = this;
        let authToken: string = $this.localStorage.get('aty');
        if ($this.authenticationService.currentUserValue != undefined) {
            authToken = $this.authenticationService.currentUserValue.Token;
        }
        if (authToken == null && environment.kioskId != null) {

            return $this.kioskTokenService.GenerateApiToken().pipe(tap(resp => {
                $this.localStorage.set('aty', resp);
                authToken = resp;
            }), switchMap(resp => {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${authToken}`
                    }
                });

                return next.handle(request);
            }));

        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            return next.handle(request);
        }
    }
}