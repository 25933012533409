import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CompanyService } from 'src/app/services/company/company.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { CompanyModel } from 'src/app/shared/models/CompanyModel.model';
import * as _ from 'underscore';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements AfterViewInit, AfterContentChecked {
  company: CompanyModel = {
    id: 0,
    name: null,
    apiKey: null,
    backofficeLimits: 0,
    contact: null,
    email: null,
    hasSEFIntegration: false,
    hasWalkinFunctionality: false,
    kioskLimits: 0,
    licenseExpirationDate: null,
    localsLimits: 0,
    logo: null,
    nif: null,
    serverHost: null
  };
  arrayOfRequests: any[] = [];
  enableSave: boolean = true;
  hasWalkin: boolean;

  constructor(private companyService: CompanyService, private loadingService: LoadingService,
    private editDataServiceService: EditDataServiceService, private router: Router, private cdref: ChangeDetectorRef, private utilitiesService: UtilitiesService) {
  }
  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    if ($this.editDataServiceService.companyIdToEdit != 0) {
      $this.arrayOfRequests.push($this.companyService.GetCompanyById($this.editDataServiceService.companyIdToEdit));
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              if ($this.editDataServiceService.companyIdToEdit != 0) {
                let currentCompany = resp as CompanyModel;

                if (currentCompany != null) {
                  $this.company = $this.toLowerKeys(currentCompany) as CompanyModel;
                }

                $this.editDataServiceService.companyIdToEdit = 0;
                $this.checkFields();
              }
              break;
          }
        }
        $this.loadingService.toggle("");
      });
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.company);
    const notRequiredFields = ['id', 'apiKey', 'bakcofficeLimits', 'hasSEFIntegration', 'hasWalkinFunctionality',
      'logo', 'serverHost', 'subcompanies'];
    const requiredNumber = [];

    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.company[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.company[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }
  
  ngAfterContentChecked(): void {
    const $this = this;
    $this.checkFields();
    $this.cdref.detectChanges();
  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  clear() {
    const $this = this;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/companies"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.companyService.SaveCompany($this.company).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/companies"]);
    });
  }
}