import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { BookingColibriModel } from 'src/app/shared/models/BookingColibriModel.model';

@Injectable({
  providedIn: 'root'
})
export class ColibriConnectionService {

  constructor(private http: HttpClient, private router: Router) { }

  public SearchReservation(argBookingId: string, argGuestLastName: string, argDateFrom: string, argGuestContactNumber: string): Observable<any> {

    const $this = this;

    const searchParameters = {
      bookingId: argBookingId,
      guestLastName: argGuestLastName,
      guestContactNumber: argGuestContactNumber,
      dateFrom: argDateFrom
    };

    return $this.http.post<any>(environment.apiUrl + 'api/v1/ColibriIntegration/SearchReservation', searchParameters).pipe();

  }

  public UpdateBookingData(dataToUpdate: BookingColibriModel): Observable<BookingColibriModel> {
    const $this = this;

    return $this.http.post<BookingColibriModel>(environment.apiUrl + 'api/v1/ColibriIntegration/UpdateBookingData', dataToUpdate).pipe();
  }

  public TotalToPay(argBookingId: string, argAdultCounter: string, argKidCounter: string): Observable<BookingColibriModel> {
    const $this = this;

    const dataToRecalculate = {
      bookingId: argBookingId,
      adultCounter: argAdultCounter,
      kidCounter: argKidCounter
    };

    return $this.http.post<BookingColibriModel>(environment.apiUrl + 'api/v1/ColibriIntegration/RefreshTotalToPay', dataToRecalculate).pipe();
  }

  public UpdateGuestsCounter(argBookingId: string, argAdultCounter: string, argKidCounter: string): Observable<boolean> {
    const $this = this;

    const dataToRecalculate = {
      bookingId: argBookingId,
      adultCounter: argAdultCounter,
      kidCounter: argKidCounter
    };

    return $this.http.post<boolean>(environment.apiUrl + 'api/v1/ColibriIntegration/UpdateGuestsCounters', dataToRecalculate).pipe();
  }

  public PayBookingWithCreditCard(argBookingId: string, argAmountToPay: string, argYkioskBookingId: string,
    argSeriesCard: string, argCardNumber: string, argExpireDate: string, argCardHolderName: string): Observable<boolean> {
    const $this = this;

    const paymentDetails = {
      "bookingId": argBookingId,
      "amountToPay": argAmountToPay,
      "ykioskBookingId": argYkioskBookingId,
      "seriesCard": argSeriesCard,
      "cardNumber": argCardNumber,
      "expireDate": argExpireDate,
      "cardHolderName": argCardHolderName
    };

    return $this.http.post<boolean>(environment.apiUrl + 'api/v1/ColibriIntegration/PayBookingWithCreditCard', paymentDetails).pipe();
  }
}
