import { AfterViewInit, Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { TermService } from 'src/app/services/term/term.service';
import { CreateBookingModel } from 'src/app/shared/models/CreateBookingModel.model';
import * as moment from 'moment';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { BookingColibriModel } from 'src/app/shared/models/BookingColibriModel.model';
import { forkJoin } from 'rxjs';
import { BookingModel } from 'src/app/shared/models/BookingModel.model';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})

export class BookingFormComponent implements AfterViewInit {
  acceptedTerm: SafeHtml;
  currentBooking: any;
  transactions: any;
  displayedColumnsTransactions: string[] = ['description', 'amountPaid', 'status', 'messageError'];
  kiosks: DictionaryModel[];
  arrayOfRequests: any[] = [];
  clientSign = null;

  constructor(private bookingService: BookingService, private loadingService: LoadingService, private editDataServiceService: EditDataServiceService,
    private termService: TermService, private dictionariesService: DictionariesService,
    private domSanitizer: DomSanitizer, private router: Router, private utilitiesService: UtilitiesService) {
  }

  booking: CreateBookingModel = {
    Id: 0,
    Name: null,
    BookingNumber: null,
    CreateDate: null,
    EditDate: null,
    ChannelBookingNumber: null,
    NumberOfNights: null,
    Transactions: [],
    TermId: 0,
    AcceptNy: false,
    KioskId: 0,
    Tags: null,
    DataToSend: null
  };

  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.dictionariesService.GetKiosksDictionary());

    if ($this.editDataServiceService.bookingIdToEdit != 0) {
      $this.arrayOfRequests.push($this.bookingService.GetBookingById($this.editDataServiceService.bookingIdToEdit));
    }

    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              $this.kiosks = resp as DictionaryModel[];
              break;
            case 1:
              let bookingModelResp = resp as BookingModel;
              let bookingColibriModel = new BookingColibriModel();
              if (bookingModelResp.dataToSend != "" && bookingModelResp.dataToSend != null) {
                bookingColibriModel = $this.toLowerKeys(JSON.parse(bookingModelResp.dataToSend)) as BookingColibriModel;
              }

              $this.booking = {
                Id: bookingModelResp.id,
                CreateDate: moment(bookingModelResp.createDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'),
                EditDate: moment(bookingModelResp.editDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'),
                Name: bookingModelResp.name,
                BookingNumber: bookingModelResp.bookingNumber,
                Transactions: bookingModelResp.transactions,
                TermId: bookingModelResp.termId,
                AcceptNy: bookingModelResp.acceptNy,
                ChannelBookingNumber: bookingModelResp.channelBookingNumber,
                KioskId: bookingModelResp.kioskId,
                NumberOfNights: bookingModelResp.numberOfNights,
                Tags: bookingModelResp.tags,
                DataToSend: bookingColibriModel
              };

              $this.transactions = bookingModelResp.transactions;
              $this.currentBooking = bookingModelResp;

              if ($this.booking.TermId > 0) {
                $this.termService.GetTermById($this.booking.TermId).subscribe((termResp) => {
                  $this.acceptedTerm = $this.domSanitizer.bypassSecurityTrustHtml(termResp.text);
                  $this.loadingService.toggle("");
                  $this.termService.GetTermAcceptedInfo($this.booking.Id).subscribe((termAccepted) => {

                    if(termAccepted.clientSign != undefined && termAccepted.clientSign != null && termAccepted.clientSign.length){
                      $this.clientSign = 'data:image/png;base64,' + termAccepted.clientSign;
                    }

                  });
                });
              } else {
                $this.loadingService.toggle("");
              }
              break;
          }
        }
        if ($this.editDataServiceService.bookingIdToEdit == 0) {
          $this.loadingService.toggle("");
        }else{
          $this.editDataServiceService.bookingIdToEdit = 0;
        }
      });
  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/bookings"]);
  }

}
