import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { BookingModel } from 'src/app/shared/models/BookingModel.model';
import * as moment from 'moment';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements AfterViewChecked {

  displayedColumns: string[] = ['select', 'id', 'date', 'reservation_n', 'channel_reservation_n', 'name', 'n_nights', 'tags'];
  dataSource: MatTableDataSource<BookingModel>;
  editMode: boolean = false;
  numSelected: number;
  columnList: string[];
  filters: FiltersModel = {
    ColumnsToSearch: [],
    CurrentPage: 0,
    PageSize: 10,
    OrderColumn: null,
    OrderType: null,
    SearchText: null,
    FilterTags: []
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService, private bookingService: BookingService, 
    private editDataServiceService: EditDataServiceService, private cdRef: ChangeDetectorRef) {

    const $this = this;

    $this.bookingService.GetAllColumnNames().subscribe((resp: string[]) => {
      $this.columnList = resp;
    });

    $this.loadingService.toggle("");
    $this.refreshList();
  }

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const $this = this;
    if ($this.dataSource == null || $this.dataSource == undefined) {
      return false;
    } else {
      $this.numSelected = $this.selection.selected.length;
      const numRows = $this.dataSource.data.length;
      return $this.numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngAfterViewChecked() {
    const $this = this;
    $this.cdRef.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete() {
    const $this = this;
    const selectedIds = $this.selection.selected.map(({ id }) => id);
    $this.loadingService.toggle("");
    $this.bookingService.DeleteListOfBookings(selectedIds).subscribe(() => {
      $this.refreshList();
    });
  }

  edit() {
    const $this = this;
    $this.editDataServiceService.bookingIdToEdit = $this.selection.selected[0]['id'];
    $this.router.navigate(["backoffice/bookings/booking-form"]);
  }

  search() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.refreshList();
  }

  refreshList() {
    const $this = this;
    $this.selection = new SelectionModel<any>(true, []);
    $this.numSelected = 0;

    if($this.paginator != null){
      $this.filters.PageSize = $this.paginator.pageSize;
      $this.filters.CurrentPage = $this.paginator.pageIndex;
    }
    
    $this.bookingService.GetAllBookings($this.filters).subscribe((resp: { bookings: BookingModel[]; }) => {
      if (resp != null) {
        for (let index = 0; index < resp.bookings.length; index++) {
          const bookingItem: BookingModel = resp.bookings[index];
          bookingItem.createDate = moment(bookingItem.createDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
        }
        $this.dataSource = new MatTableDataSource(resp.bookings);
        $this.dataSource.paginator = $this.paginator;
        $this.dataSource.sort = $this.sort;
        $this.loadingService.toggle("");
      }
    });
  }
}
