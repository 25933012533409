import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreDataService } from '../services/storeData/store-data.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  constructor(private router: Router, private storeDataService: StoreDataService) { }

  ngOnInit(): void {
  }

  finishCheckin(){
    const $this = this;
    /*localStorage.removeItem('cb');
    localStorage.removeItem('guests');
    localStorage.removeItem('currentGuest');*/
    $this.storeDataService.cb = '';
    $this.storeDataService.guests = '';
    $this.storeDataService.currentGuest = '';
    $this.router.navigate(['/']);
  }

}
