import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditDataServiceService {

  sefDocumentIdToEdit: number = 0;
  kioskIdToEdit: number = 0;
  roomTypeIdToEdit: number = 0;
  boardTypeIdToEdit: number = 0;
  languageIdToEdit: number = 0;
  translationIdToEdit: number = 0;
  configIdToEdit: number = 0;
  bookingIdToEdit: number = 0;
  userIdToEdit: number = 0;
  emailIdToEdit: number = 0;
  termIdToEdit: number = 0;
  localIdToEdit: number = 0;
  appMessageIdToEdit: number = 0;
  companyIdToEdit: number = 0;
  subcompanyIdToEdit: number = 0;
  pmsSettingIdToEdit: number = 0;

  constructor() { }
}
