import { EmailTypeModel } from "./EmailTypeModel.model";
import { LanguageModel } from "./LanguageModel.model";

export class EmailModel {
    id: number;
    name: string;
    subject: string;
    body: string;
    languageId: number;
    language: LanguageModel;
    subcompanyId: number;
    emailTypeId: number;
    emailType: EmailTypeModel;
}