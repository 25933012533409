<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'ToPayDescriptionFrontOffice' | translate }} {{ totalToPayDue }} €</h3>
  </div>

  <div class="row">
    <div class="col">
      <div class="kiosk-container">

        <div class="result-container">

          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="cardName">{{ 'CardNameTextFrontOffice' | translate }}</label>
              <input type="text" [(ngModel)]="cardName" (keyup)="changeValue($event)" class="form-control" id="cardName"
                placeholder="{{ 'CardNameTextFrontOffice' | translate }}">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="cardNumber">{{ 'CardNumberTextFrontOffice' | translate }}</label>
              <input type="number" maxlength="16" [(ngModel)]="cardNumber" (keyup)="changeValue($event)"
                class="form-control" id="cardNumber" placeholder="{{ 'CardNumberTextFrontOffice' | translate }}">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4 col-sm-12">
              <label for="cvv">CVV</label>
              <input type="number" maxlength="3" [(ngModel)]="cvv" (keyup)="changeValue($event)" class="form-control"
                id="cvv" placeholder="CVV">
            </div>
            <div class="form-group col-md-8 col-sm-12">
              <label>{{ 'ExpirationDateTextFrontOffice' | translate }}</label>
              <select [(ngModel)]="cardMonth" (change)="changeValue($event)"
                class="form-control date-select month-select">
                <option selected>Selecione o Mês...</option>
                <option *ngFor="let month of listOfMonths;  index as i" value="{{ i+1 }}">{{ month }}</option>
              </select>
              <select [(ngModel)]="cardYear" (change)="changeValue($event)" class="form-control date-select">
                <option selected>Selecione o Ano...</option>
                <option *ngFor="let year of listOfYears;" value="{{ year }}">{{ year }}</option>
              </select>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <button (click)="prevPage()" type="button"
        class="btn btn-lg btn-secondary">{{ 'ButtonBackFrontOffice' | translate }}</button>
    </div>
    <div class="col text-center">
      <button [disabled]="!enablePayButton" (click)="pay()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonPayFrontOffice' | translate }}</button>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">

    </div>
    <div class="col text-center">

    </div>
  </div>

</div>

<ng-template #contentError let-c="close" let-d="dismiss">
  <div class="modal-header">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-info"
      viewBox="0 0 16 16">
      <path
        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
      <circle cx="8" cy="4.5" r="1" />
    </svg>
    <h6 class="modal-title" id="modal-basic-title">{{ 'AlertMessageTitleFrontOffice' | translate }}</h6>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'PaymentMessageErrorFrontOffice' | translate }}</p>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
