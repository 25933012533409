export class UserModel {
    id: number;
    name: string;
    username: string;
    password: string;
    pin: string;
    accessToConfiguration: boolean;
    email: string;
    userGroupId: number;
    activeNy: boolean;
    activationToken: string;
    languageId: number;
    subcompanyId: number;
}