import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
import { LoadingService } from '../services/loading/loading.service';
import { ColibriConnectionService } from '../services/colibriConnection/colibri-connection.service';
import { SefConnectionService } from '../services/sefConnection/sef-connection.service';
import { SEFModel } from '../shared/models/SEFModel.model';
import { BookingColibriModel } from '../shared/models/BookingColibriModel.model';
import * as _ from 'underscore';
import { GuestCountColibriModel } from '../shared/models/GuestCountColibriModel.model';
import { StoreDataService } from '../services/storeData/store-data.service';
import { UtilitiesService } from '../services/utilities-service/utilities.service';
@Component({
  selector: 'app-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['./edit-booking.component.css']
})
export class EditBookingComponent implements OnInit {

  menuPage: number = 1;
  currentBooking: BookingColibriModel;
  guestsList: Array<SEFModel> = [];
  deleteMoveNy: boolean = false;
  disableAddAdults: boolean = false;
  disableAddKids: boolean = false;
  adultCounter: number = 0;
  kidCounter: number = 0;

  constructor(private location: Location, private exitCheckinService: ExitCheckinService, private router: Router,
    private loadingService: LoadingService, private colibriConnectionService: ColibriConnectionService,
    private sefConnectionService: SefConnectionService, private storeDataService: StoreDataService, private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
    const $this = this;
    /*let guestsString = localStorage.getItem('guests');
    let currentBookingString = localStorage.getItem('cb');*/

    let guestsString = $this.storeDataService.guests;
    let currentBookingString = $this.storeDataService.cb;

    if (!$this.utilitiesService.isNullOrEmpty(guestsString)) {
      $this.guestsList = JSON.parse(guestsString);
    }

    if (!$this.utilitiesService.isNullOrEmpty(currentBookingString)) {
      $this.currentBooking = JSON.parse(currentBookingString);
      let guestCounts = $this.currentBooking.guestCounts;

      for (let index = 0; index < guestCounts.length; index++) {
        const element = guestCounts[index];

        if (element.isAdult) {
          $this.adultCounter += element.count;
        }
        else {
          $this.kidCounter += element.count;
        }
      }

      $this.disableAddAdults = $this.adultCounter >= parseInt($this.currentBooking.maxAdult);
      $this.disableAddKids = $this.kidCounter >= parseInt($this.currentBooking.maxChildren);
    }
  }

  prevPage(): void {
    const $this = this;

    if ($this.menuPage == 1) {
      $this.location.back();
    } else {
      $this.menuPage = 1;
    }
  }

  exit() {
    const $this = this;
    $this.exitCheckinService.openPopup();
  }

  navigation($event, isDeleteMove) {
    const $this = this;

    $this.deleteMoveNy = isDeleteMove;

    switch ($event.target.id) {
      case "editNumberOfGuests":
        $this.menuPage = 2;
        break;
      default:
        $this.menuPage = 3;
        break;
    }
  }

  guestEdit(guest: SEFModel) {
    const $this = this;

    if ($this.deleteMoveNy) {

      $this.loadingService.toggle("");

      $this.sefConnectionService.RemoveSEFDocumentsBySEFDocumentId(guest.yKioskSEFDocumentId).subscribe(resp => {

        $this.guestsList = _.without($this.guestsList, _.findWhere($this.guestsList, {
          yKioskSEFDocumentId: guest.yKioskSEFDocumentId
        }));

        if (guest.isAdult) {
          $this.adultCounter--;
        }
        else {
          $this.kidCounter--;
        }

        if (_.findWhere($this.currentBooking.guestCounts, { isAdult: true }) != null) {
          _.findWhere($this.currentBooking.guestCounts, { isAdult: true }).count = $this.adultCounter;
        }

        if (_.findWhere($this.currentBooking.guestCounts, { isAdult: false }) == null) {
          let newKidCounter: GuestCountColibriModel = {
            count: $this.kidCounter,
            isAdult: false
          };
          $this.currentBooking.guestCounts.push(newKidCounter);
        } else {
          _.findWhere($this.currentBooking.guestCounts, { isAdult: false }).count = $this.kidCounter;
        }

        $this.currentBooking.numberOfGuests = ($this.adultCounter + $this.kidCounter).toString();

        $this.colibriConnectionService.TotalToPay($this.currentBooking.bookingNumber, $this.adultCounter.toString(), $this.kidCounter.toString()).subscribe(total => {
          
          if(total.totalToPayDue != null){
            $this.currentBooking.totalToPayDue = total.totalToPayDue;
          }
          
          $this.colibriConnectionService.UpdateBookingData($this.currentBooking).subscribe(resp => {
            //localStorage.setItem('cb', JSON.stringify(resp));
            $this.storeDataService.cb = JSON.stringify(resp);
            $this.loadingService.toggle("");

            /*localStorage.setItem('cb', JSON.stringify($this.currentBooking));
            localStorage.setItem('guests', JSON.stringify($this.guestsList));*/
            $this.storeDataService.cb = JSON.stringify($this.currentBooking);
            $this.storeDataService.guests = JSON.stringify($this.guestsList);

            $this.prevPage();
          })
        });

      });

    } else {
      //localStorage.setItem('currentGuest', JSON.stringify(guest));
      $this.storeDataService.currentGuest = JSON.stringify(guest);
      $this.router.navigate(['sef-form']);
    }

  }

  addAdult() {
    const $this = this;
    $this.adultCounter++;
    $this.addGuest();
  }

  addKid() {
    const $this = this;
    $this.kidCounter++;
    $this.addGuest();
  }

  addGuest() {
    const $this = this;
    $this.loadingService.toggle("");

    if (_.findWhere($this.currentBooking.guestCounts, { isAdult: true }) != null) {
      _.findWhere($this.currentBooking.guestCounts, { isAdult: true }).count = $this.adultCounter;
    }

    if (_.findWhere($this.currentBooking.guestCounts, { isAdult: false }) == null) {
      let newKidCounter: GuestCountColibriModel = {
        count: $this.kidCounter,
        isAdult: false
      };
      $this.currentBooking.guestCounts.push(newKidCounter);
    } else {
      _.findWhere($this.currentBooking.guestCounts, { isAdult: false }).count = $this.kidCounter;
    }

    $this.currentBooking.numberOfGuests = ($this.adultCounter + $this.kidCounter).toString();

    $this.colibriConnectionService.TotalToPay($this.currentBooking.bookingNumber, $this.adultCounter.toString(), $this.kidCounter.toString()).subscribe(total => {
        
        if(total.totalToPayDue != null){
          $this.currentBooking.totalToPayDue = total.totalToPayDue;
        }

        $this.colibriConnectionService.UpdateBookingData($this.currentBooking).subscribe(resp => {
          //localStorage.setItem('cb', JSON.stringify(resp));
          $this.storeDataService.cb = JSON.stringify(resp);

          $this.loadingService.toggle("");

          let newGuest: SEFModel = {
            guestName: $this.currentBooking.ownerGuest,
            guestFirstName: "",
            guestLastName: "",
            nationality: "",
            dateOfBirth: "",
            birthplace: "",
            documentIdentification: "",
            documentType: "",
            issuingCountry: "",
            checkin: $this.currentBooking.checkin,
            checkout: $this.currentBooking.checkout,
            countryOfResidence: "",
            residenceCity: "",
            mobileNumber: "",
            email: "",
            documentImage: "",
            yKioskBookingId: 0,
            yKioskSEFDocumentId: 0,
            yKioskStateId: 0,
            isAdult: 0,
            localId: 0,
            isProductionMode: 0,
            colibriGuestId: 0,
            guestGender: "",
            idExpireDate: ""
          };

          /*localStorage.setItem('currentGuest', JSON.stringify(newGuest));
          localStorage.setItem('cb', JSON.stringify($this.currentBooking));*/

          $this.storeDataService.currentGuest = JSON.stringify(newGuest);
          $this.storeDataService.cb = JSON.stringify($this.currentBooking);

          $this.router.navigate(['sef-form']);
        });
      });


  }

}
