import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { SeedDataServiceService } from 'src/app/services/seedDataService/seed-data-service.service';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit {

  constructor(private seedDataServiceService: SeedDataServiceService, private loadingService: LoadingService) { }

  fileInput: any;

  ngOnInit(): void {
  }

  uploadData() {
    const $this = this;
    $this.loadingService.toggle("");
    let reader = new FileReader();
    reader.readAsDataURL($this.fileInput.files[0]);
    reader.onload = () => {

      if (reader.result != null) {
        let base64File = reader.result as string;

        $this.seedDataServiceService.seedData(base64File.split(',')[1]).subscribe(() => {
          $this.fileInput = null;
          $this.loadingService.toggle("");
        });

      } else {
        $this.fileInput = null;
        $this.loadingService.toggle("");
      }
    };
  }
}
