import { Component, OnInit } from '@angular/core';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private exitCheckinService: ExitCheckinService) {

  }

  ngOnInit(): void {
  }
  
  partteam(){
    window.open("https://www.partteams.com", "_blank");
  }

  terms(){
    const $this = this;
    $this.exitCheckinService.openTermsPopup();
  }

}
