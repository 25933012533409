import { LanguageModel } from "./LanguageModel.model";

export class TermModel {
    id: number;
    text: string;
    termId: number;
    languageId: number;
    language: LanguageModel;
    publishDate: string;
    lastVersionNy: boolean;
}