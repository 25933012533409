import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PmsIntegratorService } from 'src/app/services/pms-integrator-service/pms-integrator.service';
import { PmsSettingService } from 'src/app/services/pms-setting-service/pms-setting.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { OperationType } from 'src/app/shared/models/Utilities/operation-type';
import { PMSType } from 'src/app/shared/models/Utilities/pmstype';
import { ServiceCallType } from 'src/app/shared/models/Utilities/service-call-type';
import { DocumentStateType } from 'src/app/shared/models/enums/document-state-type';
import { PublicPMSSettingModel } from 'src/app/shared/models/public-pmssetting-model';
import * as _ from "underscore";

@Component({
  selector: 'app-integration-form',
  templateUrl: './integration-form.component.html',
  styleUrls: ['./integration-form.component.scss']
})
export class IntegrationFormComponent implements OnInit, OnDestroy {

  constructor(private pmsSettingService: PmsSettingService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService, private router: Router, private utilitiesService: UtilitiesService,
    private pmsIntegratorService: PmsIntegratorService, private snackBar: MatSnackBar, private dictionariesService: DictionariesService) { }

  pmsSetting: PublicPMSSettingModel = {
    id: 0,
    pmsSettings: {
      delayMilliseconds: null,
      hourToSync: null,
      integratorApiUrl: null,
      targetAuthentication: null,
      targetUrl: null,
      pmsHotelId: null,
      pmsOperationType: null,
      pmsPassword: null,
      pmsType: PMSType.None,
      pmsUrl: null,
      pmsUsername: null,
      serviceCallType: null,
      subcompanyId: 0,
      createState: null
    },
    pmsType: PMSType.None
  };
  enableSave: boolean;
  pmsTypes = this.getEnumOptions(PMSType);
  subcompanies: DictionaryModel[];
  arrayOfRequests: any[] = [];

  getEnumOptions(enumObj: any) {
    return Object.keys(enumObj)
      .filter(key => !isNaN(Number(enumObj[key])))
      .map(key => ({ value: enumObj[key], name: key }));
  }

  ngOnInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());

    if ($this.editDataServiceService.pmsSettingIdToEdit != 0) {
      $this.arrayOfRequests.push($this.pmsSettingService.GetPMSSettingById($this.editDataServiceService.pmsSettingIdToEdit));
      $this.editDataServiceService.pmsSettingIdToEdit = 0;
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 1:
              $this.pmsSetting = resp;
              $this.checkFields();
              break;
          }
        }
        $this.loadingService.toggle("");
      });

  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/integrations"]);
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.pmsSetting.pmsSettings);
    const notRequiredFields = ['id', 'pmsOperationType', 'serviceCallType', 'pmsType', 'createState'];
    const requiredNumber = ['delayMilliseconds'];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.pmsSetting.pmsSettings[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.pmsSetting.pmsSettings[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.pmsSetting.pmsType = $this.pmsSetting.pmsSettings.pmsType;
    $this.pmsSetting.pmsSettings.pmsOperationType = OperationType.SyncData;
    $this.pmsSetting.pmsSettings.serviceCallType = ServiceCallType.YKioskHOTEL;
    $this.pmsSetting.pmsSettings.createState = DocumentStateType.Inactive;

    $this.pmsSettingService.SaveOrUpdatePMSSetting($this.pmsSetting).subscribe((resp) => {
      $this.pmsSetting.id = resp.id;
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/integrations"]);
    });
  }

  initIntegrator() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.pmsIntegratorService.InitializePMSSync().subscribe(resp => {
      $this.loadingService.toggle("");
      if (resp) {
        $this.snackBar.open("Integrador Inicializado com Sucesso...");
      } else {
        $this.snackBar.open("Falha ao Inicializar Integrador...");
      }

      setTimeout(() => {
        $this.snackBar.dismiss();
      }, 10000);
    });
  }

  forceSync() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.pmsIntegratorService.ForceSyncManual().subscribe(resp => {
      $this.loadingService.toggle("");
      if (resp) {
        $this.snackBar.open("Sincronização Manual Despoletada...");
      } else {
        $this.snackBar.open("Falha ao Despoletar Sincronização Manual...");
      }

      setTimeout(() => {
        $this.snackBar.dismiss();
      }, 10000);
    });
  }

  ngOnDestroy(): void {
    const $this = this;
  }

}
