import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { TermsListModel } from 'src/app/shared/models/TermsListModel.model';
import { CreateTermModel } from 'src/app/shared/models/CreateTermModel.model';
import { TermModel } from 'src/app/shared/models/TermModel.model';
import { TermAcceptanceModel } from 'src/app/shared/models/term-acceptance-model';

@Injectable({
  providedIn: 'root'
})
export class TermService {
  
  private userToken:string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { 
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue?.Token;
  }

  public GetAllColumnNames(): Observable<string[]>{
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/Term/GetAllColumnNames', header).pipe();
  }

  public GetAllActiveTerms(): Observable<any> {

    const $this = this;

    return $this.http.get<any>(environment.apiUrl + 'api/v1/Term/ActiveTermsWeb').pipe();

  }

  public GetTermById(id:number): Observable<any>{
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.get<any>(environment.apiUrl + 'api/v1/Term/GetTermByIdWeb/' + id, header).pipe();
  }

  public GetTermByIdBackoffice(id:number): Observable<any>{
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.get<any>(environment.apiUrl + 'api/v1/Term/GetTermByIdWebBackoffice/' + id, header).pipe();
  }

  public AcceptTerm(currentBookingId, acceptTerm, currentTermId): Observable<any> {

    const $this = this;

    return $this.http.post<any>(environment.apiUrl + 'api/v1/Booking/AcceptTerm', {BookingId: currentBookingId, AcceptNy: acceptTerm, TermId: currentTermId}).pipe();
  }

  public TermsAcceptedInBooking(argBookingId): Observable<boolean> {

    const $this = this;

    return $this.http.get<boolean>(environment.apiUrl + 'api/v1/Booking/TermsAccepted/' + argBookingId).pipe();

  }

  public GetAllTerms(filterParameters: FiltersModel): Observable<TermsListModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }
    return $this.http.post<TermsListModel>(environment.apiUrl + 'api/v1/Term/WithPagination', filterParameters, header).pipe();
  }

  public SaveTerm(dataToSave: CreateTermModel):Observable<TermModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }
    return $this.http.post<TermModel>(environment.apiUrl + 'api/v1/Term/SaveWeb', dataToSave, header).pipe();
  }

  public GetTermAcceptedInfo(argBookingId: number): Observable<TermAcceptanceModel>{
    const $this = this;

    return $this.http.get<TermAcceptanceModel>(environment.apiUrl + 'api/v1/Term/GetTermAcceptedInfo/' + argBookingId).pipe();
  }

}
