import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend  } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KioskTokenService {

  constructor(private http: HttpClient, private httpBackend: HttpBackend) { }

  public GenerateApiToken(): Observable<any> {

    const $this = this;

    $this.http = new HttpClient($this.httpBackend);

    return $this.http.get<any>(environment.apiUrl + 'api/v1/Kiosk/GenerateApiToken/' + environment.kioskId, {responseType: 'text' as 'json'}).pipe();
  }
}
