import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard.ts';
import { HomeComponent } from './home/home.component';
import { SearchBookingComponent } from './search-booking/search-booking.component';
import { ResultViewComponent } from './result-view/result-view.component';
import { NotFoundViewComponent } from './not-found-view/not-found-view.component';
import { TermsComponent } from './terms/terms.component';
import { GuestsNumberComponent } from './guests-number/guests-number.component';
import { SefFormComponent } from './sef-form/sef-form.component';
import { EditBookingComponent } from './edit-booking/edit-booking.component';
import { NextGuestComponent } from './next-guest/next-guest.component';
import { PaymentComponent } from './payment/payment.component';
import { SuccessfullComponent } from './successfull/successfull.component';
import { FinishComponent } from './finish/finish.component';
import { DirectSearchComponent } from './direct-search/direct-search.component';


import { LoginComponent } from './backoffice/login/login.component';
import { BookingsComponent } from './backoffice/bookings/bookings.component';
import { BookingFormComponent } from './backoffice/booking-form/booking-form.component';
import { SefDocumentsComponent } from './backoffice/sef-documents/sef-documents.component';
import { SefDocumentFormComponent } from './backoffice/sef-document-form/sef-document-form.component';
import { KiosksComponent } from './backoffice/kiosks/kiosks.component';
import { KioskFormComponent } from './backoffice/kiosk-form/kiosk-form.component';
import { UsersComponent } from './backoffice/users/users.component';
import { UserFormComponent } from './backoffice/user-form/user-form.component';
import { LocalsComponent } from './backoffice/locals/locals.component';
import { LocalFormComponent } from './backoffice/local-form/local-form.component';
import { TermsComponent as TermsComponentBackoffice } from './backoffice/terms/terms.component';
import { TermFormComponent } from './backoffice/term-form/term-form.component';
import { EmailFormComponent } from './backoffice/email-form/email-form.component';
import { EmailsComponent } from './backoffice/emails/emails.component';
import { LanguagesComponent } from './backoffice/languages/languages.component';
import { LanguageFormComponent } from './backoffice/language-form/language-form.component';
import { TranslationFormComponent } from './backoffice/translation-form/translation-form.component';
import { TranslationsComponent } from './backoffice/translations/translations.component';
import { ConfigFormComponent } from './backoffice/config-form/config-form.component';
import { AppMsgsComponent } from './backoffice/app-msgs/app-msgs.component';
import { AppMsgFormComponent } from './backoffice/app-msg-form/app-msg-form.component';
import { ImportDataComponent } from './backoffice/import-data/import-data.component';
import { CompaniesComponent } from './backoffice/companies/companies.component';
import { CompanyFormComponent } from './backoffice/company-form/company-form.component';
import { SubcompanyFormComponent } from './backoffice/subcompany-form/subcompany-form.component';
import { SubcompaniesComponent } from './backoffice/subcompanies/subcompanies.component';
import { IntegrationsComponent } from './backoffice/integrations/integrations.component';
import { IntegrationFormComponent } from './backoffice/integration-form/integration-form.component';
import { CashHoldingsComponent } from './backoffice/cash-holdings/cash-holdings.component';
import { CashOperationsComponent } from './backoffice/cash-operations/cash-operations.component';
import { TransactionsComponent } from './backoffice/transactions/transactions.component';
import { LevelsComponent } from './backoffice/levels/levels.component';
import { RoomTypesComponent } from './backoffice/room-types/room-types.component';
import { BoardTypesComponent } from './backoffice/board-types/board-types.component';
import { BoardTypeFormComponent } from './backoffice/board-type-form/board-type-form.component';
import { RoomTypeFormComponent } from './backoffice/room-type-form/room-type-form.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
        data: {
            pageTitle: 'Home',
            customLayout: 0
        }
    },
    {
        path: 'home',
        component: HomeComponent,
        data: {
            pageTitle: 'Home',
            customLayout: 1
        }
    },
    {
        path: 'search-booking',
        component: SearchBookingComponent,
        data: {
            pageTitle: 'Search Booking',
            customLayout: 1
        }
    },
    {
        path: 'search-result',
        component: ResultViewComponent,
        data: {
            pageTitle: 'Search Result',
            customLayout: 1
        }
    },
    {
        path: 'booking-not-found',
        component: NotFoundViewComponent,
        data: {
            pageTitle: 'Booking Not Found',
            customLayout: 1
        }
    },
    {
        path: 'terms',
        component: TermsComponent,
        data: {
            pageTitle: 'Terms',
            customLayout: 1
        }
    },
    {
        path: 'guest-number-edit',
        component: GuestsNumberComponent,
        data: {
            pageTitle: 'Guest Numbers',
            customLayout: 1
        }
    },
    {
        path: 'sef-form',
        component: SefFormComponent,
        data: {
            pageTitle: 'SEF Data',
            customLayout: 1
        }
    },
    {
        path: 'edit-booking',
        component: EditBookingComponent,
        data: {
            pageTitle: 'Edit Booking',
            customLayout: 1
        }
    },
    {
        path: 'next-guest',
        component: NextGuestComponent,
        data: {
            pageTitle: 'Next Guest',
            customLayout: 1
        }
    },
    {
        path: 'payment',
        component: PaymentComponent,
        data: {
            pageTitle: 'Payment',
            customLayout: 1
        }
    },
    {
        path: 'finish',
        component: SuccessfullComponent,
        data: {
            pageTitle: 'Success',
            customLayout: 1
        }
    },
    {
        path: 'end',
        component: FinishComponent,
        data: {
            pageTitle: 'End',
            customLayout: 1
        }
    },
    {
        path: 'direct-search/:bN/:gN',
        component: DirectSearchComponent,
        data: {
            pageTitle: 'Direct Search',
            customLayout: 0
        }
    },
    {
        path: 'backoffice',
        component: LoginComponent,
        data: {
            pageTitle: 'Administração',
            customLayout: 2
        }
    },
    {
        path: 'backoffice/bookings',
        component: BookingsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Bookings',
            pageIcon: 'calendar_today',
            customLayout: 3
        }
    },
    {
        path: 'backoffice/bookings/booking-form',
        component: BookingFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Booking',
            pageIcon: 'calendar_today',
            customLayout: 3
        }
    },
    {
        path: 'backoffice/sef-documents',
        component: SefDocumentsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Hóspedes',
            pageIcon: 'groups',
            customLayout: 3
        }
    },
    {
        path: 'backoffice/sef-documents/sef-document-form',
        component: SefDocumentFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Hóspede',
            pageIcon: 'person',
            customLayout: 3
        }
    },
    {
        path: 'backoffice/kiosks',
        component: KiosksComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Quiosques',
            pageIcon: 'desktop_windows',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/kiosks/kiosk-form',
        component: KioskFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Quiosque',
            pageIcon: 'desktop_windows',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/room-types',
        component: RoomTypesComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Tipos de Quarto',
            pageIcon: 'bed',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/room-types/room-type-form',
        component: RoomTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Tipo de Quarto',
            pageIcon: 'bed',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/board-types',
        component: BoardTypesComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Tipos de Pensão',
            pageIcon: 'brunch_dining',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/board-types/board-type-form',
        component: BoardTypeFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Tipo de Pensão',
            pageIcon: 'brunch_dining',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/cash-holdings',
        component: CashHoldingsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Manutenções',
            pageIcon: 'attach_money',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/cash-operations',
        component: CashOperationsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Operações de Caixa',
            pageIcon: 'savings',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/transactions',
        component: TransactionsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Operações',
            pageIcon: 'payments',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/levels',
        component: LevelsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Níveis',
            pageIcon: 'stacked_bar_chart',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Utilizadores',
            pageIcon: 'account_circle',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/users/user-form',
        component: UserFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Utilizador',
            pageIcon: 'account_circle',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/locals',
        component: LocalsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Locais',
            pageIcon: 'location_on',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/locals/local-form',
        component: LocalFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Local',
            pageIcon: 'location_on',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/integrations',
        component: IntegrationsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Integrações',
            pageIcon: 'integration_instructions',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/integrations/integration-form',
        component: IntegrationFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Definições da Integração',
            pageIcon: 'integration_instructions',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/terms',
        component: TermsComponentBackoffice,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Termos & Condições',
            pageIcon: 'grading',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/terms/term-form',
        component: TermFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Termos & Condições',
            pageIcon: 'grading',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/emails',
        component: EmailsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Emails',
            pageIcon: 'email',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/emails/email-form',
        component: EmailFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Email a Enviar',
            pageIcon: 'email',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/languages',
        component: LanguagesComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Idiomas',
            pageIcon: 'language',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/languages/language-form',
        component: LanguageFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Idioma',
            pageIcon: 'language',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/translations',
        component: TranslationsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Traduções',
            pageIcon: 'translate',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/translations/translation-form',
        component: TranslationFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Tradução',
            pageIcon: 'translate',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/app-msgs',
        component: AppMsgsComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Lista de Mensagens Aplicacionais',
            pageIcon: 'textsms',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/app-msgs/app-msg-form',
        component: AppMsgFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Mensagem Aplicacional',
            pageIcon: 'textsms',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/config',
        component: ConfigFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Configuração',
            pageIcon: 'settings',
            customLayout: 3,
            roles: { IsAdministrator: true }
        }
    },
    {
        path: 'backoffice/import-data',
        component: ImportDataComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Importação de Dados',
            pageIcon: 'import_contacts',
            customLayout: 3,
            roles: { IsSuperUser: true }
        }
    },
    {
        path: 'backoffice/companies',
        component: CompaniesComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Empresas',
            pageIcon: 'apartment',
            customLayout: 3,
            roles: { IsSuperUser: true }
        }
    },
    {
        path: 'backoffice/companies/company-form',
        component: CompanyFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Empresa',
            pageIcon: 'apartment',
            customLayout: 3,
            roles: { IsSuperUser: true }
        }
    },
    {
        path: 'backoffice/subcompanies',
        component: SubcompaniesComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Subempresas',
            pageIcon: 'corporate_fare',
            customLayout: 3,
            roles: { IsSuperUser: true }
        }
    },
    {
        path: 'backoffice/subcompanies/subcompany-form',
        component: SubcompanyFormComponent,
        canActivate: [AuthGuard],
        data: {
            pageTitle: 'Subempresa',
            pageIcon: 'corporate_fare',
            customLayout: 3,
            roles: { IsSuperUser: true }
        }
    }
];

export const appRoutingModule = RouterModule.forRoot(routes);
