import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoomTypeListModel } from 'src/app/shared/models/room-type-list-model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { RoomTypeModel } from 'src/app/shared/models/room-type-model';

@Injectable({
  providedIn: 'root'
})
export class RoomTypesService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/RoomType/GetAllColumnNames', header).pipe();
  }

  public GetRoomTypeById(argId: number): Observable<RoomTypeModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<RoomTypeModel>(environment.apiUrl + 'api/v1/RoomType/' + argId, header).pipe(map((response: RoomTypeModel) => {

      if(response.roomPhotos != null && response.roomPhotos.length){
        for (let i = 0; i < response.roomPhotos.length; i++) {
          const currentPhoto = response.roomPhotos[i];
          currentPhoto.fileToDisplay = "data:image/webp;base64," + currentPhoto.file;
        }
      }

      return response;
    }));
  }

  public GetAllRoomTypes(filterParameters: FiltersModel): Observable<RoomTypeListModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<RoomTypeListModel>(environment.apiUrl + 'api/v1/RoomType/GetRoomTypes', filterParameters, header).pipe();
  }

  public SaveRoomType(argDataToSave: RoomTypeModel): Observable<RoomTypeModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<RoomTypeModel>(environment.apiUrl + 'api/v1/RoomType', argDataToSave, header).pipe();
  }

  public DeleteLstOfRoomTypes(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
        .set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/RoomType', options).pipe();
  }
}
