import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const $this = this;
        const currentUser = $this.authenticationService.currentUserValue;

        if (currentUser && $this.authenticationService.isExpired) {
            $this.authenticationService.logout();
        };

        if (currentUser) {

            if (route.data.roles && route.data.roles.IsAdministrator && route.data.roles.IsAdministrator != currentUser.IsAdministrator) {
                $this.router.navigate(['/backoffice/bookings'], { queryParams: { returnUrl: state.url } });
                return false;
            }

            if (route.data.roles && route.data.roles.IsSuperUser && route.data.roles.IsSuperUser != currentUser.IsSuperUser) {
                $this.router.navigate(['/backoffice/bookings'], { queryParams: { returnUrl: state.url } });
                return false;
            }

            return true;
        }

        $this.router.navigate(['/backoffice'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}