<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px">
        <div class="row row-form-container">
          <form>
            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Código</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.kioskCode"
                    (keyup)="checkFields()"
                    name="kioskCode"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-9 col-md-9 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.name"
                    (keyup)="checkFields()"
                    name="name"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <div class="kiosk-logo-container input-full-width">
                  <input
                    style="display: none"
                    type="file"
                    accept="image/x-png,image/jpeg"
                    (change)="onFileChanged($event)"
                    name="logo"
                    #fileInput
                  />
                  <div class="kiosk-logo-column">
                    <mat-icon
                      aria-hidden="false"
                      *ngIf="!hasFile"
                      class="logo-container-icon"
                      aria-label="Image"
                      >image</mat-icon
                    >
                    <img
                      [src]="selectedImagePreview"
                      *ngIf="hasFile"
                      class="logo-image"
                    />
                  </div>

                  <div class="kiosk-logo-column">
                    <div class="document-btn-container-col">
                      <div class="document-btn-container-wrapper">
                        <button
                          class="form-button button-form-ptt"
                          (click)="fileInput.click()"
                          mat-raised-button
                          color="btn-custom"
                        >
                          <mat-icon aria-hidden="false">file_upload</mat-icon>
                          UPLOAD
                        </button>
                      </div>
                    </div>
                    <div class="document-btn-container-col">
                      <div class="document-btn-container-wrapper">
                        <button
                          class="form-button button-form-ptt"
                          (click)="clear()"
                          mat-raised-button
                          color="btn-custom"
                        >
                          <mat-icon aria-hidden="false">
                            delete_forever
                          </mat-icon>
                          REMOVER
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Subempresa</mat-label>
                  <mat-select
                    [(ngModel)]="kiosk.subcompanyId"
                    (selectionChange)="checkFields()"
                    name="subcompanyId"
                  >
                    <mat-option
                      *ngFor="let subcompany of subcompanies"
                      [value]="subcompany.id"
                    >
                      {{ subcompany.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Grupos</mat-label>
                  <mat-select
                    [(ngModel)]="kiosk.groupId"
                    (selectionChange)="checkFields()"
                    name="groupId"
                  >
                    <mat-option *ngFor="let group of groups" [value]="group.id">
                      {{ group.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Idioma por Defeito</mat-label>
                  <mat-select
                    [(ngModel)]="kiosk.languageId"
                    (selectionChange)="checkFields()"
                    name="languageId"
                  >
                    <mat-option
                      *ngFor="let languages of languages"
                      [value]="languages.id"
                    >
                      {{ languages.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome do Servidor</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.serverName"
                    (keyup)="checkFields()"
                    name="serverName"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Endereço do Servidor</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.serverAdress"
                    (keyup)="checkFields()"
                    name="serverAdress"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div
                  class="input-full-width mat-form-field-flex slide-container"
                >
                  <mat-slide-toggle
                    [(ngModel)]="kiosk.activeNy"
                    (change)="checkFields()"
                    name="activeNy"
                    >Ativo?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width time-input">
                  <mat-label>Hora de Reinício</mat-label>
                  <input
                    matInput
                    type="time"
                    [(ngModel)]="kiosk.kioskRestartHour"
                    (keyup)="checkFields()"
                    (change)="checkFields()"
                    name="kioskRestartHour"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width time-input">
                  <mat-label>Hora Limite de Check-in</mat-label>
                  <input
                    matInput
                    type="time"
                    [(ngModel)]="kiosk.checkinTimeLimit"
                    (keyup)="checkFields()"
                    (change)="checkFields()"
                    name="checkinTimeLimit"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nº de Horas de Antecedência do Check-in</mat-label>
                  <input
                    matInput
                    type="number"
                    min="0"
                    (keyup)="checkFields()"
                    [(ngModel)]="kiosk.nHoursEarlyCheckin"
                    name="nHoursEarlyCheckin"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width time-input">
                  <mat-label>Até que Horas é Considerado (Early Check-in)</mat-label>
                  <input
                    matInput
                    type="time"
                    [(ngModel)]="kiosk.earlyCheckinMaxHour"
                    (keyup)="checkFields()"
                    (change)="checkFields()"
                    name="earlyCheckinMaxHour"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Taxa de Early Check-in</mat-label>
                  <input
                    matInput
                    type="number"
                    min="0"
                    step="0.01"
                    (keyup)="checkFields()"
                    [(ngModel)]="kiosk.earlyCheckinTax"
                    name="earlyCheckinTax"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div
                [ngClass]="{
                  'col-xl-3 col-md-3 col-sm-12': hasWalkin,
                  'col-xl-6 col-md-6 col-sm-12': !hasWalkin
                }"
              >
                <div
                  class="input-full-width mat-form-field-flex slide-container"
                >
                  <mat-slide-toggle
                    [(ngModel)]="kiosk.isKioskWeb"
                    (change)="checkFields()"
                    name="isKioskWeb"
                    >Quiosque Web?
                  </mat-slide-toggle>
                </div>
              </div>
              <div *ngIf="hasWalkin" class="col-xl-3 col-md-3 col-sm-12">
                <div
                  class="input-full-width mat-form-field-flex slide-container"
                >
                  <mat-slide-toggle
                    [(ngModel)]="kiosk.isWalkinEnable"
                    (change)="checkFields()"
                    name="isWalkinEnable"
                    >Ativar Walk-in?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Endereço do Quiosque</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.kioskAddress"
                    (keyup)="checkFields()"
                    name="kioskAddress"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div
                  class="input-full-width mat-form-field-flex slide-container"
                >
                  <mat-slide-toggle
                    [(ngModel)]="kiosk.useMB"
                    (change)="checkFields()"
                    name="useMB"
                    >Usa Multibanco?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div
                  class="input-full-width mat-form-field-flex slide-container"
                >
                  <mat-slide-toggle
                    [(ngModel)]="kiosk.useCash"
                    (change)="checkFields()"
                    name="useCash"
                    >Usa Dinheiro?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Email a Notificar Alertas</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.emailsToNotifyCardDispenserAlerts"
                    (keyup)="checkFields()"
                    name="emailsToNotifyCardDispenserAlerts"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div
                class="input-full-width mat-form-field-flex slide-container"
              >
                <mat-slide-toggle
                  [(ngModel)]="kiosk.useOnScreenSignature"
                  (change)="checkFields()"
                  name="useOnScreenSignature"
                  >Usa Assinatura no Ecrã?
                </mat-slide-toggle>
              </div>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Tipo de Dispensadores de Cartões</mat-label>
                  <mat-select
                    [(ngModel)]="kiosk.cardDispenserType"
                    (selectionChange)="checkFields()"
                    name="cardDispenserType"
                  >
                    <mat-option
                      *ngFor="
                        let cardDispenserType of cardDispenserTypes
                          | enumToArray
                      "
                      [value]="cardDispenserType.index"
                    >
                      {{ cardDispenserType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Porta COM Dispensador de Cartões</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.cardDispenserCOMPort"
                    (keyup)="checkFields()"
                    name="cardDispenserCOMPort"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Porta COM Leitor QRCode</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.qrCodeReaderCOMPort"
                    (keyup)="checkFields()"
                    name="qrCodeReaderCOMPort"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Tipo de Encoder de Cartões</mat-label>
                  <mat-select
                    [(ngModel)]="kiosk.cardEncoderType"
                    (selectionChange)="checkFields()"
                    name="cardEncoderType"
                  >
                    <mat-option
                      *ngFor="
                        let encoderType of encoderTypes
                          | enumToArray
                      "
                      [value]="encoderType.index"
                    >
                      {{ encoderType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Host do Encoder de Cartões</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.encoderHost"
                    (keyup)="checkFields()"
                    name="encoderHost"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Porta do Encoder de Cartões</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.encoderPort"
                    (keyup)="checkFields()"
                    name="encoderPort"
                  />
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome do Encoder de Chaves</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.encoderName"
                    (keyup)="checkFields()"
                    name="encoderName"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Tags</mat-label>
                  <input
                    matInput
                    [(ngModel)]="kiosk.tags"
                    (keyup)="checkFields()"
                    name="tags"
                  />
                  <mat-hint>Tags Separadas por Vírgulas</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button
              class="form-button button-form-ptt"
              (click)="goBack()"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button
              class="form-button button-form-ptt"
              (click)="saveData()"
              [disabled]="!enableSave"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
