import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { CreateLanguageModel } from 'src/app/shared/models/CreateLanguageModel.model';
import { LanguageModel } from 'src/app/shared/models/LanguageModel.model';
import * as _ from "underscore";

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss']
})
export class LanguageFormComponent implements AfterViewInit {

  constructor(private languageService: LanguageService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService,
    private router: Router, private utilitiesService: UtilitiesService) {
  }

  enableSave: boolean;
  currentLanguage: LanguageModel = new LanguageModel();
  languagePhoto: string;
  logoDisplay: string;
  language: CreateLanguageModel = {
    id: 0,
    description: null,
    iso: null,
    languageImage: null
  };

  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    if ($this.editDataServiceService.languageIdToEdit != 0) {
      $this.languageService.GetLanguageById($this.editDataServiceService.languageIdToEdit).subscribe(resp => {
        $this.language = resp;
        $this.logoDisplay = 'data:image/png;base64,' + resp.languageImage;
        $this.currentLanguage = resp;
        $this.checkFields();
        $this.loadingService.toggle("");
      });

      $this.editDataServiceService.languageIdToEdit = 0;
    }else{
      $this.loadingService.toggle("");
    }
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.language);
    const notRequiredFields = ['id'];
    const requiredNumber = [];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.language[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.language[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  uploadFile(files: FileList) {
    const $this = this;
    if (files.length > 0) {
      let file = files.item(0);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        if (reader.result != null) {
          let base64File = reader.result as string;
          $this.language.languageImage = base64File.split(',')[1];
          $this.logoDisplay = base64File;
          $this.checkFields();
        } else {
          $this.loadingService.toggle("");
        }
      };
    }
  }

  clearLogo() {
    const $this = this;
    $this.language.languageImage = null;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/languages"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.languageService.SaveLanguage($this.language).subscribe((resp) => {
      $this.language.id = resp;
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/languages"]);
    });
  }
}