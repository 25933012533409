import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { forkJoin } from 'rxjs';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { EmailService } from 'src/app/services/email/email.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { CreateEmailModel } from 'src/app/shared/models/CreateEmailModel.model';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { EmailModel } from 'src/app/shared/models/EmailModel.model';
import * as _ from "underscore";


@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss']
})
export class EmailFormComponent implements OnInit, OnDestroy {

  constructor(private emailService: EmailService, private dictionariesService: DictionariesService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService, private router: Router, private utilitiesService: UtilitiesService) { }

  email: CreateEmailModel = {
    id: 0,
    body: null,
    emailTypeId: 0,
    languageId: 0,
    name: null,
    subcompanyId: 0,
    subject: null
  };
  currentEmail: EmailModel = new EmailModel();
  languages: DictionaryModel[];
  subcompanies: DictionaryModel[];
  emailTypes: DictionaryModel[];
  enableSave: boolean;
  editor: Editor;
  html: string;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  arrayOfRequests: any[] = [];

  dataSourceTags = [
    { tag: '#CODES', description: 'Códigos de Acesso' },
    { tag: '#GUEST', description: 'Nome do Hóspede Principal' },
    { tag: '#BOOKINGNUMBER', description: 'Número da Reserva' }
  ];
  displayedColumns: string[] = ['tag', 'description'];


  ngOnInit(): void {
    const $this = this;
    $this.editor = new Editor();
    $this.html = '';
    $this.arrayOfRequests.push($this.dictionariesService.GetLanguageDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetEmailTypesDictionary());

    $this.loadingService.toggle("");

    if ($this.editDataServiceService.emailIdToEdit != 0) {
      $this.arrayOfRequests.push($this.emailService.GetEmailToSendByIdOnline($this.editDataServiceService.emailIdToEdit));
    }

    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {
        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];
          switch (i) {
            case 0:
              $this.languages = resp as DictionaryModel[];
              break;
            case 1:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 2:
              $this.emailTypes = resp as DictionaryModel[];
              break;
            case 3:
              $this.email = resp as EmailModel;
              $this.html = decodeURIComponent(escape(window.atob($this.email.body)));
              $this.currentEmail = resp as EmailModel;
              $this.editDataServiceService.emailIdToEdit = 0;
              break;
          }
        }
        $this.checkFields();
        $this.loadingService.toggle("");
      });
  }

  checkFields() {
    const $this = this;
    if (!$this.utilitiesService.isNullOrEmpty($this.html)) {
      $this.email.body = window.btoa(unescape(encodeURIComponent($this.html)));
    }
    const objKeys = _.keys($this.email);
    const notRequiredFields = ['id', 'language', 'emailType'];
    const requiredNumber = ['subcompanyId', 'languageId', 'emailTypeId'];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.email[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.email[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/emails"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.emailService.SaveEmailOnline($this.email).subscribe((resp) => {
      $this.email.id = resp.id;
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/emails"]);
    });
  }

  ngOnDestroy(): void {
    const $this = this;
    $this.editor.destroy();
  }

}
