import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { CountriesDictionaryModel } from 'src/app/shared/models/CountriesDictionaryModel.model';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {

  constructor(private http: HttpClient, private router: Router) { }

  public GetICAOCountriesDictionary(): Observable<CountriesDictionaryModel[]> {

    const $this = this;

    return $this.http.get<CountriesDictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/ICAOCountries').pipe();

  }

  public GetSEFDocumentTypesSEFCodeDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/SEFDocumentTypesCode').pipe();

  }

  public GetKiosksDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/Kiosks').pipe();

  }

  public GetLocalsDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/Locals').pipe();

  }

  public GetGroupDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/Groups').pipe();

  }

  public GetSubcompanyDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/Subcompanies').pipe();

  }

  public GetCompanyDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/Companies').pipe();

  }

  public GetLanguageDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/Languages').pipe();

  }

  public GetUserGroupDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/UserGroups').pipe();
  }

  public GetEmailTypesDictionary(): Observable<DictionaryModel[]> {

    const $this = this;

    return $this.http.get<DictionaryModel[]>(environment.apiUrl + 'api/v1/Dictionary/EmailTypes').pipe();
  }
}


