<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'SearchReservationFrontOffice' | translate }}</h3>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-8">
      <div class="kiosk-container">
        <div *ngIf="questionNumber == 0" class="question question-one">
          <label for="questionOne">{{ 'SearchFirstQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionOne" [(ngModel)]="questionOne"
            (keyup)="changeValue($event)" aria-describedby="emailHelp"
            placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 1" class="question question-two">
          <label for="questionTwo">{{ 'SearchSecondQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionTwo" [(ngModel)]="questionTwo"
            (keyup)="changeValue($event)" aria-describedby="emailHelp"
            placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 2" class="question question-three">
          <label for="questionThree">{{ 'SearchThirdQuestionFrontOffice' | translate }}</label>
          <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Portugal" [maxLength]="15"
            [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National"
            name="phone" id="questionThree" (ngModelChange)="changeValue($event)" [(ngModel)]="questionThree">
          </ngx-intl-tel-input>
        </div>
        <div *ngIf="questionNumber == 3" class="question question-four">
          <label for="questionFour">{{ 'SearchLastQuestionFrontOffice' | translate }}</label>
          <input type="date" class="form-control" id="questionFour" [(ngModel)]="questionFour"
            (keyup)="changeValue($event)" (change)="changeValue($event)" aria-describedby="emailHelp"
            placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <button (click)="prevPage()" type="button"
        class="btn btn-lg btn-secondary">{{ 'ButtonBackFrontOffice' | translate }}</button>
    </div>
    <div class="col text-center">
      <button (click)="nextPage()" [disabled]="disableNextButton" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonNextFrontOffice' | translate }}</button>
    </div>
  </div>

  <div class="row row-action-two">
    <div class="col text-center">
      <p (click)="exit()" class="link-button btn-lg cancel-button">{{ 'ButtonCancelFrontOffice' | translate }}</p>
    </div>
    <div class="col text-center">
      <p *ngIf="showIdontKnowButton" (click)="nextPage()" class="link-button btn-lg idontknow-button">
        {{ 'ButtonIDontKnowFrontOffice' | translate }}</p>
    </div>
  </div>

</div>
