import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  showLoading: boolean = false;
  showAlert: boolean = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService,
    private router: Router, private loadingService: LoadingService) { }

  ngOnInit(): void {
    const $this = this;

    $this.loginForm = $this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onLogin() {

    const $this = this;
    $this.loadingService.toggle("");
    $this.authService.login($this.loginForm.value).subscribe(resp => {

      $this.loadingService.toggle("");
      $this.router.navigate(['/backoffice/bookings']);

    }, error => {

      $this.loginForm.reset();
      $this.loadingService.toggle("");

    });

  }

}
