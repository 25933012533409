import { TranslationStateType } from "./enums/translation-state-type";
import { TranslationType } from "./enums/translation-type";

export class TranslationModel {
    id: number;
    description: string;
    translationKey: string;
    languageId: number;
    state?: TranslationStateType;
    type?: TranslationType;
    boardTypeId?: number;
    roomTypeId?: number;
}