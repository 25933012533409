<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'SuccessTitleSearchResultFrontOffice' | translate }}</h3>
  </div>

  <div class="row">
    <div class="col">
      <div class="kiosk-container">

        <div class="result-container">
          <ul class="list-group">
            <li class="list-group-item">{{ 'ReservationNumberTextSearchResultFrontOffice' | translate }} <b>{{ bookingNumber }}</b></li>
            <li class="list-group-item">{{ 'HolderGuestSearchResultFrontOffice' | translate }} <b>{{ guestOwner }}</b></li>
            <li class="list-group-item">{{ 'BuildingSearchResultFrontOffice' | translate }} <b></b></li>
            <li class="list-group-item">{{ 'NumberOfGuestsSearchResultFrontOffice' | translate }} <b>{{ numberOfGuests }}</b></li>
            <li class="list-group-item">{{ 'NumberOfNightsSearchResultFrontOffice' | translate }} <b>{{ numberOfNights }}</b></li>
            <li class="list-group-item">{{ 'CheckInOutSearchResultFrontOffice' | translate }} <b>{{ checkinCheckout }}</b></li>
          </ul>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <button (click)="prevPage()" type="button"
        class="btn btn-lg btn-secondary">{{ 'ButtonBackFrontOffice' | translate }}</button>
    </div>
    <div class="col text-center">
      <button (click)="nextPage()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonNextFrontOffice' | translate }}</button>
    </div>
  </div>

  <div class="row row-action-two">
    <div class="col text-center">
      <p (click)="exit()" class="link-button btn-lg cancel-button">{{ 'ButtonCancelFrontOffice' | translate }}</p>
    </div>
    <div class="col text-center">
      
    </div>
  </div>

</div>
