import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { KioskService } from '../services/kiosk/kiosk.service';
import { LoadingService } from '../services/loading/loading.service';
import { StoreDataService } from '../services/storeData/store-data.service';

@Component({
  selector: 'app-nav-home',
  templateUrl: './nav-home.component.html',
  styleUrls: ['./nav-home.component.css']
})
export class NavHomeComponent implements OnInit {

  logoSrc: string = "";

  constructor(private kioskService: KioskService, private loadingService: LoadingService,
    private storeDataService: StoreDataService) {

  }

  ngOnInit() {
    let $this = this;
    
    $this.loadingService.toggle("");
    
    $this.kioskService.GetKioskByIpAddress().subscribe(resp => {

      if (resp != null && resp.id > 0) {
        $this.loadingService.toggle("");
        $this.storeDataService.setQuiosqueInfo(resp);
        $this.logoSrc = "data:image/png;base64," + resp.logo;
      }else{
        window.location.href = "http://ykioskhotel.com/";
      }

    });
  }

}
