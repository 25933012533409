import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading: any = {
    IsLoading: false,
    LoadingMessage: ''
  };

  @Output() change: EventEmitter<any> = new EventEmitter();

  toggle(loadingMessage: string) {
    
    const $this = this;

    $this.loading = {
      IsLoading: !$this.loading.IsLoading,
      LoadingMessage: loadingMessage
    }

    $this.change.emit($this.loading);

  }
  
}
