import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { CreateTranslationModel } from 'src/app/shared/models/CreateTranslationModel.model';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { TranslationModel } from 'src/app/shared/models/TranslationModel.model';
import * as _ from "underscore";


@Component({
  selector: 'app-translation-form',
  templateUrl: './translation-form.component.html',
  styleUrls: ['./translation-form.component.scss']
})
export class TranslationFormComponent implements AfterViewInit {

  constructor(private translationService: TranslationService, private dictionariesService: DictionariesService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService, private router: Router, private utilitiesService: UtilitiesService) { }

  currentTranslation: TranslationModel = new TranslationModel();
  translation: CreateTranslationModel = {
    id: 0,
    description: null,
    translationKey: null,
    languageId: 0
  };
  languages: DictionaryModel[];
  enableSave: boolean;
  arrayOfRequests: any[] = [];

  ngAfterViewInit(): void {
    const $this = this;

    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.dictionariesService.GetLanguageDictionary());
    if ($this.editDataServiceService.translationIdToEdit != 0) {
      $this.arrayOfRequests.push($this.translationService.GetTranslationById($this.editDataServiceService.translationIdToEdit));
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];
          switch (i) {
            case 0:
              $this.languages = resp as DictionaryModel[];
              break;
            case 1:
              $this.translation = resp as TranslationModel;
              $this.currentTranslation = resp as TranslationModel;
              $this.editDataServiceService.termIdToEdit = 0;
              break;
          }
        }
        $this.checkFields();
        $this.loadingService.toggle("");
      });
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.translation);
    const notRequiredFields = ['id', 'language'];
    const requiredNumber = [];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.translation[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          console.log(key);
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.translation[key] == 0) {
            $this.enableSave = false;

            console.log(key);
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/translations"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.translationService.SaveTranslation($this.translation).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/translations"]);
    });
  }
}
