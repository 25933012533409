import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CompanyService } from 'src/app/services/company/company.service';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LocalService } from 'src/app/services/local/local.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { LocalsListModel } from 'src/app/shared/models/LocalsListModel.model';
import { LocalModel } from 'src/app/shared/models/LocalModel.model';
import { LicenseLimitsModel } from 'src/app/shared/models/LicenseLimitsModel.model';
import * as _ from 'underscore';
import { PMSIntegrationType } from 'src/app/shared/models/Utilities/PMSIntegrationType';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { ProtelStatusService } from 'src/app/services/integration/protel-status-service/protel-status.service';
import { ProtelStatusModel } from 'src/app/shared/models/protel-status-model';

@Component({
  selector: 'app-local-form',
  templateUrl: './local-form.component.html',
  styleUrls: ['./local-form.component.scss']
})
export class LocalFormComponent implements OnInit {
  local: LocalModel = {
    id: 0,
    locationKeyColibri: null,
    accessKeyColibri: null,
    hotelUnitCodeSEF: null,
    establishmentSEF: null,
    accessKeySEF: null,
    name: null,
    abbreviationSEF: null,
    addressSEF: null,
    localitySEF: null,
    postalCodeSEF: null,
    postalZoneSEF: null,
    telephoneSEF: null,
    faxSEF: null,
    contactNameSEF: null,
    contactEmailSEF: null,
    subcompanyId: 0,
    pmsIntegrationType: PMSIntegrationType.None,
    sefIntegrationEnable: false,
    protelEndPoint: null,
    protelHotelId: null,
    protelStatusToProvideCard: []
  };
  subcompanies: DictionaryModel[];
  lstProtelStatus: ProtelStatusModel[];
  arrayOfRequests: any[] = [];
  enableSave: boolean = true;
  pmsTypes = PMSIntegrationType;
  localsLimits: number;
  filters: FiltersModel = {
    ColumnsToSearch: [],
    CurrentPage: 0,
    PageSize: 10,
    OrderColumn: null,
    OrderType: null,
    SearchText: null,
    FilterTags: []
  };
  hasSEF: boolean;

  constructor(private localService: LocalService, private loadingService: LoadingService, private editDataServiceService: EditDataServiceService,
    private companyService: CompanyService, private dictionariesService: DictionariesService, private router: Router, private utilitiesService: UtilitiesService,
    private protelStatusService: ProtelStatusService) {
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.local);
    const notRequiredFields = ['id', 'sefIntegrationEnable', 'pmsIntegrationType', 'groups', 'subcompany', 'protelStatusToProvideCard', 'protelStatus'];

    switch ($this.local.pmsIntegrationType) {
      case PMSIntegrationType.Colibri:
        notRequiredFields.push('protelEndPoint', 'protelHotelId');
        break;
      case PMSIntegrationType.Protel:
        notRequiredFields.push('locationKeyColibri', 'accessKeyColibri');
        break;
      default:
        notRequiredFields.push('protelEndPoint', 'protelHotelId', 'locationKeyColibri', 'accessKeyColibri');
        break;
    }

    if (!$this.local.sefIntegrationEnable) {
      notRequiredFields.push('hotelUnitCodeSEF', 'establishmentSEF', 'accessKeySEF', 'abbreviationSEF', 'addressSEF', 'localitySEF',
        'postalCodeSEF', 'postalZoneSEF', 'telephoneSEF', 'faxSEF', 'contactNameSEF', 'contactEmailSEF');
    }

    const requiredNumber = ['subcompanyId'];

    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.local[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.local[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  ngOnInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.companyService.GetCompanyLimits());
    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());

    if ($this.editDataServiceService.localIdToEdit != 0) {
      $this.arrayOfRequests.push($this.localService.GetLocalById($this.editDataServiceService.localIdToEdit));
    } else {
      $this.arrayOfRequests.push($this.localService.GetAllLocals($this.filters));
    }

    $this.arrayOfRequests.push($this.protelStatusService.GetAll());

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              let licenseLimits = resp as LicenseLimitsModel;
              $this.localsLimits = licenseLimits.localsLimits;
              $this.hasSEF = licenseLimits.hasSEFIntegration;
              break;
            case 1:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 2:
              if ($this.editDataServiceService.localIdToEdit != 0) {
                $this.local = resp as LocalModel;
              } else {
                let localsList = resp as LocalsListModel;
                if (localsList.itemsCounter >= $this.localsLimits) {
                  $this.goBack();
                  $this.loadingService.toggle("");
                  return;
                }
              }
              $this.editDataServiceService.localIdToEdit = 0;
              break;
            case 3:
              $this.lstProtelStatus = resp as ProtelStatusModel[];
              break;
          }
        }


        $this.checkFields();
        $this.loadingService.toggle("");
      });

  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  clear() {
    const $this = this;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/locals"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.localService.SaveLocal($this.local).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/locals"]);
    });
  }
}
