<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'TitleBookingEditFrontOffice' | translate }}</h3>
  </div>

  <div class="row">
    <div class="col">
      <div class="kiosk-container">

        <div class="result-container booking-guests-container">
          <div class="guest-list-container">
            <div *ngIf="menuPage == 1" class="btn-group btn-group-vertical" role="group" aria-label="Edit Group">
              <button type="button" id="editNumberOfGuests" (click)="navigation($event, false)" class="btn btn-lg btn-dark">
                {{ 'ButtonEditNumberOfGuestsFrontOffice' | translate }}
              </button>
              <button type="button" id="editDataOfGuests" (click)="navigation($event, false)" class="btn btn-lg btn-dark">
                {{ 'ButtonEditDataOfGuestsFrontOffice' | translate }}
              </button>
            </div>
            <div *ngIf="menuPage == 2" class="btn-group btn-group-vertical" (click)="addAdult()" role="group" aria-label="Edit Group">
              <button type="button" [disabled]="disableAddAdults" class="btn btn-lg btn-dark">
                {{ 'ButtonAddGuestAdultFrontOffice' | translate }}
              </button>
              <button type="button" [disabled]="disableAddKids" (click)="addKid()" class="btn btn-lg btn-dark">
                {{ 'ButtonAddGuestKidFrontOffice' | translate }}
              </button>
              <button type="button" id="removeGuests" (click)="navigation($event, true)" class="btn btn-lg btn-dark">
                {{ 'ButtonRemoveGuestFrontOffice' | translate }}
              </button>
            </div>
            <div *ngIf="menuPage == 3" class="btn-group btn-group-vertical" role="group" aria-label="Edit Group">
              <button type="button" (click)="guestEdit(guest)" *ngFor="let guest of guestsList" class="btn btn-lg btn-dark">{{ guest.guestName }}</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <button (click)="prevPage()" type="button"
        class="btn btn-lg btn-secondary">{{ 'ButtonBackFrontOffice' | translate }}</button>
    </div>
    <div class="col text-center">
    </div>
  </div>

  <div class="row row-action-two">
    <div class="col text-center">
      <p (click)="exit()" class="link-button btn-lg cancel-button">{{ 'ButtonCancelFrontOffice' | translate }}</p>
    </div>
    <div class="col text-center">

    </div>
  </div>

</div>
