<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <form>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Tipo de PMS (*)</mat-label>
              <mat-select
                (selectionChange)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.pmsType"
                name="pmsType"
              >
                <mat-option
                  *ngFor="let pmsType of pmsTypes"
                  [value]="pmsType.value"
                >
                  {{ pmsType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Delay <small>[em segundos]</small> (*)</mat-label>
              <input
                matInput
                type="number"
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.delayMilliseconds"
                name="delayMilliseconds"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width time-input">
              <mat-label>Horário de Sincronização (*)</mat-label>
              <input
                matInput
                type="time"
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.hourToSync"
                name="hourToSync"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>ID Hotel (*)</mat-label>
              <input
                matInput
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.pmsHotelId"
                name="pmsHotelId"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Utilizador API PMS (*)</mat-label>
              <input
                matInput
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.pmsUsername"
                name="pmsUsername"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width time-input">
              <mat-label>Password API PMS (*)</mat-label>
              <input
                matInput
                type="password"
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.pmsPassword"
                name="pmsPassword"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>URL API PMS (*)</mat-label>
              <input
                matInput
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.pmsUrl"
                name="pmsUrl"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Token (*)</mat-label>
              <input
                matInput
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.targetAuthentication"
                name="targetAuthentication"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>URL do Destino de Dados (*)</mat-label>
              <input
                matInput
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.targetUrl"
                name="targetUrl"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>URL do Integrador (*)</mat-label>
              <input
                matInput
                (keyup)="checkFields()"
                [(ngModel)]="pmsSetting?.pmsSettings.integratorApiUrl"
                name="integratorApiUrl"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Subempresa</mat-label>
              <mat-select
                [(ngModel)]="pmsSetting?.pmsSettings.subcompanyId"
                (selectionChange)="checkFields()"
                name="subcompanyId"
              >
                <mat-option
                  *ngFor="let subcompany of subcompanies"
                  [value]="subcompany.id"
                >
                  {{ subcompany.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <button
              mat-raised-button
              color="primary"
              [disabled]="!enableSave"
              (click)="initIntegrator()"
              class="input-full-width"
            >
              Iniciar o Integrador
            </button>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <button
              mat-raised-button
              color="primary"
              [disabled]="!enableSave"
              (click)="forceSync()"
              class="input-full-width"
            >
              Forçar Integração
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button
              class="form-button button-form-ptt"
              (click)="goBack()"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button
              class="form-button button-form-ptt"
              (click)="saveData()"
              [disabled]="!enableSave"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
