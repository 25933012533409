import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { ColibriConnectionService } from '../services/colibriConnection/colibri-connection.service';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
import { LoadingService } from '../services/loading/loading.service';
import { StoreDataService } from '../services/storeData/store-data.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { UtilitiesService } from '../services/utilities-service/utilities.service';
@Component({
  selector: 'app-search-booking',
  templateUrl: './search-booking.component.html',
  styleUrls: ['./search-booking.component.css']
})
export class SearchBookingComponent implements OnInit {

  disableNextButton: boolean = true;
  showIdontKnowButton: boolean = false;
  questionNumber: number = 0;
  questionOne: string;
  questionTwo: string;
  questionThree: string;
  questionFour: string;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Portugal, CountryISO.Spain];
  numberInputValue: any;

  constructor(private colibriConnectionService: ColibriConnectionService, private exitCheckinService: ExitCheckinService, private router: Router,
    private loadingService: LoadingService, private storeDataService: StoreDataService, private utilitiesService: UtilitiesService) {

  }

  ngOnInit(): void {
  }

  nextPage() {
    const $this = this;
    let arrQuestionCombinations: Array<any> = [];
    arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionTwo), !$this.utilitiesService.isNullOrEmpty($this.numberInputValue)]);
    arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionTwo), !$this.utilitiesService.isNullOrEmpty($this.numberInputValue), !$this.utilitiesService.isNullOrEmpty($this.questionFour)]);
    arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionTwo), !$this.utilitiesService.isNullOrEmpty($this.numberInputValue)]);
    arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionFour)]);

    let counterTrue = _.filter(arrQuestionCombinations[$this.questionNumber], (x) => { return x; });

    $this.showIdontKnowButton = $this.questionNumber == 1 || counterTrue.length < 2;

    if ($this.questionNumber == 0) {
      $this.showIdontKnowButton = true;
    }

    if ($this.questionNumber == 2) {
      $this.showIdontKnowButton = false;
    }

    if (counterTrue.length >= 2) {
      $this.searchBooking();
    } else {
      $this.questionNumber++;
      $this.disableNextButton = true;
    }
  }

  prevPage() {
    const $this = this;

    if ($this.questionNumber == 0) {

      $this.exitCheckinService.openPopup();

    } else {

      $this.questionNumber--;

      let arrQuestionCombinations: Array<any> = [];
      arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionTwo), !$this.utilitiesService.isNullOrEmpty($this.numberInputValue)]);
      arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionTwo), !$this.utilitiesService.isNullOrEmpty($this.numberInputValue), !$this.utilitiesService.isNullOrEmpty($this.questionFour)]);
      arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionTwo), !$this.utilitiesService.isNullOrEmpty($this.questionThree)]);
      arrQuestionCombinations.push([!$this.utilitiesService.isNullOrEmpty($this.questionOne), !$this.utilitiesService.isNullOrEmpty($this.questionFour)]);

      let counterTrue = _.filter(arrQuestionCombinations[$this.questionNumber], (x) => { return x; });

      $this.showIdontKnowButton = $this.questionNumber == 1 || counterTrue.length < 2;

      if ($this.questionNumber == 0) {
        $this.showIdontKnowButton = false;
        if ($this.questionOne.length) {
          $this.disableNextButton = false;
        }
      }

    }
  }

  changeValue(event: any) {
    const $this = this;

    if (event === null || event.target === undefined) {
      if (event != null && event.target === undefined) {
        $this.disableNextButton = event.internationalNumber == "";
        $this.numberInputValue = event.number;
      }
    } else {
      switch (event.target.id) {
        case "questionOne":
          $this.disableNextButton = $this.questionOne.length <= 0;
          break;
        case "questionTwo":
          $this.disableNextButton = $this.questionTwo.length <= 0;
          break;
        case "questionThree":
          $this.disableNextButton = $this.questionThree.length > 0;
          break;
        case "questionFour":
          $this.disableNextButton = $this.questionFour.length <= 0;
          break;
      }
    }
  }

  searchBooking() {

    const $this = this;
    $this.loadingService.toggle("");

    $this.colibriConnectionService.SearchReservation($this.questionOne, $this.questionTwo, $this.questionFour, $this.numberInputValue).subscribe((resp) => {
      $this.loadingService.toggle("");
      if (!$this.utilitiesService.isNullOrEmpty(resp.bookingNumber)) {
        //localStorage.setItem('cb', JSON.stringify(resp));
        $this.storeDataService.cb = JSON.stringify(resp);
        $this.router.navigate(['/search-result']);
      } else {
        $this.router.navigate(['/booking-not-found']);
      }

    });

  }

  exit() {
    const $this = this;
    $this.exitCheckinService.openPopup();
  }

}
