import { GuestCountColibriModel } from "./GuestCountColibriModel.model";

export class BookingColibriModel {
    ownerGuest: string;
    bookingNumber: string;
    bookingChannelNumber: string;
    checkin: string;
    checkout: string;
    numberOfNights: string;
    numberOfGuests: string;
    email: string;
    phoneNumber: string;
    error: string;
    result: string;
    guestCounts: Array<GuestCountColibriModel>; 
    maxAdult: string;
    maxChildren: string;
    totalBookingPrice: string;
    currencyCodeTotalBooking: string;
    totalToPayDue: string;
    currencyCodeDue: string;
    yKioskBookingId: number;
}