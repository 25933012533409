<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px">
        <div class="row row-form-container">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Dados Gerais">
              <div class="container container-form-general-data">
                <form>
                  <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Nome</mat-label>
                        <input
                          [readonly]="isEdit"
                          matInput
                          [(ngModel)]="boardType.name"
                          (keyup)="checkFields()"
                          name="name"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Subempresa</mat-label>
                        <mat-select
                          [(ngModel)]="boardType.subcompanyId"
                          (selectionChange)="checkFields()"
                          name="companyId"
                        >
                          <mat-option
                            *ngFor="let subcompany of subcompanies"
                            [value]="subcompany.id"
                          >
                            {{ subcompany.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Estado</mat-label>
                        <mat-select
                          [(ngModel)]="boardType.state"
                          (selectionChange)="checkFields()"
                          name="cardEncoderType"
                        >
                          <mat-option
                            *ngFor="
                              let documentStateType of documentStateTypes
                                | enumToArray
                            "
                            [value]="documentStateType.index"
                          >
                            {{ documentStateType.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </mat-tab>
            <mat-tab label="Traduções">
              <div class="container container-form-general-data">
                <form>
                  <ng-container *ngFor="let language of languages; trackBy: trackByLanguageId">
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-sm-12">
                        <mat-toolbar>
                          <mat-icon>translate</mat-icon>
                          <span class="language-description">{{ language.description }}</span>
                        </mat-toolbar>
                        <mat-divider></mat-divider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-sm-12">
                        <mat-form-field class="input-full-width">
                          <mat-label>Nome</mat-label>
                          <input
                            matInput
                            [ngModel]="getTranslation(language.id)"
                            (ngModelChange)="setTranslation(language.id, $event)"
                            name="translation_name_{{language.id}}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </form>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button
              class="form-button button-form-ptt"
              (click)="goBack()"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button
              class="form-button button-form-ptt"
              (click)="saveData()"
              [disabled]="!enableSave"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
