import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { EmailModel } from 'src/app/shared/models/EmailModel.model';
import { EmailListModel } from 'src/app/shared/models/EmailListModel.model';
import { CreateEmailModel } from 'src/app/shared/models/CreateEmailModel.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private userToken: string;

  constructor(private http: HttpClient, private router: Router, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }
  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/Email/GetAllColumnNames', header).pipe();
  }

  public GetEmailById(id: number): Observable<EmailModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<EmailModel>(environment.apiUrl + 'api/v1/Email/' + id, header).pipe();
  }

  public GetEmailToSendByIdOnline(id: number): Observable<EmailModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<EmailModel>(environment.apiUrl + 'api/v1/Email/GetEmailToSendByIdOnline/' + id, header).pipe();
  }

  public GetAllEmails(filterParameters: FiltersModel): Observable<EmailListModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<EmailListModel>(environment.apiUrl + 'api/v1/Email/GetAllEmailToSends', filterParameters, header).pipe();
  }

  public SaveEmail(dataToSave: CreateEmailModel): Observable<EmailModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<EmailModel>(environment.apiUrl + 'api/v1/Email', dataToSave, header).pipe();
  }

  public SaveEmailOnline(dataToSave: CreateEmailModel): Observable<EmailModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<EmailModel>(environment.apiUrl + 'api/v1/Email/SaveEmailOnline', dataToSave, header).pipe();
  }

  public DeleteListOfEmail(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    let listToDelete = JSON.stringify(listOfDocuments);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }).set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/Email', options).pipe();
  }
}
