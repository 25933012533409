import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxEditorModule } from 'ngx-editor';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomTranslateService } from './services/custom-translate/custom-translate.service';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { SearchBookingComponent } from './search-booking/search-booking.component';
import { appRoutingModule } from './app.routing';
import { FooterComponent } from './footer/footer.component';
import { ResultViewComponent } from './result-view/result-view.component';
import { NotFoundViewComponent } from './not-found-view/not-found-view.component';
import { TermsComponent } from './terms/terms.component';
import { SafeHtmlPipe } from './pipes/safeHtml/safe-html.pipe';
import { LoadingComponent } from './loading/loading.component';
import { GuestsNumberComponent } from './guests-number/guests-number.component';
import { SefFormComponent } from './sef-form/sef-form.component';
import { EditBookingComponent } from './edit-booking/edit-booking.component';
import { NextGuestComponent } from './next-guest/next-guest.component';
import { PaymentComponent } from './payment/payment.component';
import { SuccessfullComponent } from './successfull/successfull.component';
import { NavHomeComponent } from './nav-home/nav-home.component';
import { FooterHomeComponent } from './footer-home/footer-home.component';
import { FinishComponent } from './finish/finish.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirectSearchComponent } from './direct-search/direct-search.component';
import { TokenInterceptor } from './auth/token.interceptor';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { LoginComponent } from './backoffice/login/login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FlexLayoutModule } from '@angular/flex-layout';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';

import { BookingsComponent } from './backoffice/bookings/bookings.component';
import { BookingFormComponent } from './backoffice/booking-form/booking-form.component';
import { SefDocumentsComponent } from './backoffice/sef-documents/sef-documents.component';
import { SefDocumentFormComponent } from './backoffice/sef-document-form/sef-document-form.component';
import { KiosksComponent } from './backoffice/kiosks/kiosks.component';
import { KioskFormComponent } from './backoffice/kiosk-form/kiosk-form.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { UsersComponent } from './backoffice/users/users.component';
import { UserFormComponent } from './backoffice/user-form/user-form.component';
import { LocalsComponent } from './backoffice/locals/locals.component';
import { LocalFormComponent } from './backoffice/local-form/local-form.component';
import { TermsComponent as TermsComponentBackoffice } from './backoffice/terms/terms.component';
import { TermFormComponent } from './backoffice/term-form/term-form.component';
import { EmailsComponent } from './backoffice/emails/emails.component';
import { EmailFormComponent } from './backoffice/email-form/email-form.component';
import { LanguagesComponent } from './backoffice/languages/languages.component';
import { LanguageFormComponent } from './backoffice/language-form/language-form.component';
import { TranslationsComponent } from './backoffice/translations/translations.component';
import { TranslationFormComponent } from './backoffice/translation-form/translation-form.component';
import { ConfigFormComponent } from './backoffice/config-form/config-form.component';
import { AppMsgsComponent } from './backoffice/app-msgs/app-msgs.component';
import { AppMsgFormComponent } from './backoffice/app-msg-form/app-msg-form.component';
import { ImportDataComponent } from './backoffice/import-data/import-data.component';
import { CompaniesComponent } from './backoffice/companies/companies.component';
import { CompanyFormComponent } from './backoffice/company-form/company-form.component';
import { SubcompaniesComponent } from './backoffice/subcompanies/subcompanies.component';
import { SubcompanyFormComponent } from './backoffice/subcompany-form/subcompany-form.component';
import { IntegrationsComponent } from './backoffice/integrations/integrations.component';
import { IntegrationFormComponent } from './backoffice/integration-form/integration-form.component';
import { PMSTypeEnumDescriptionPipe } from './pipes/pmstype-enum-description.pipe';
import { CashHoldingsComponent } from './backoffice/cash-holdings/cash-holdings.component';
import { CashOperationsComponent } from './backoffice/cash-operations/cash-operations.component';
import { TransactionsComponent } from './backoffice/transactions/transactions.component';
import { LevelsComponent } from './backoffice/levels/levels.component';
import { ReceiptDialogComponent } from './backoffice/receipt-dialog/receipt-dialog.component';
import { RoomTypesComponent } from './backoffice/room-types/room-types.component';
import { BoardTypesComponent } from './backoffice/board-types/board-types.component';
import { BoardTypeFormComponent } from './backoffice/board-type-form/board-type-form.component';
import { RoomTypeFormComponent } from './backoffice/room-type-form/room-type-form.component';
import { FilterByPropertyPipe } from './pipes/filter-by-property.pipe';
import { CustomSnackBarComponent } from './backoffice/custom-snack-bar/custom-snack-bar.component';
import { PhotoPreviewDialogComponent } from './backoffice/photo-preview-dialog/photo-preview-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    SearchBookingComponent,
    FooterComponent,
    ResultViewComponent,
    NotFoundViewComponent,
    TermsComponent,
    SafeHtmlPipe,
    LoadingComponent,
    GuestsNumberComponent,
    SefFormComponent,
    EditBookingComponent,
    NextGuestComponent,
    PaymentComponent,
    SuccessfullComponent,
    NavHomeComponent,
    FooterHomeComponent,
    FinishComponent,
    DirectSearchComponent,
    LoginComponent,
    BookingsComponent,
    BookingFormComponent,
    SefDocumentsComponent,
    SefDocumentFormComponent,
    KiosksComponent,
    KioskFormComponent,
    EnumToArrayPipe,
    UsersComponent,
    UserFormComponent,
    LocalsComponent,
    LocalFormComponent,
    TermsComponentBackoffice,
    TermFormComponent,
    EmailsComponent,
    EmailFormComponent,
    LanguagesComponent,
    LanguageFormComponent,
    TranslationsComponent,
    TranslationFormComponent,
    ConfigFormComponent,
    AppMsgsComponent,
    AppMsgFormComponent,
    ImportDataComponent,
    CompaniesComponent,
    CompanyFormComponent,
    SubcompaniesComponent,
    SubcompanyFormComponent,
    IntegrationsComponent,
    IntegrationFormComponent,
    PMSTypeEnumDescriptionPipe,
    CashHoldingsComponent,
    CashOperationsComponent,
    TransactionsComponent,
    LevelsComponent,
    ReceiptDialogComponent,
    RoomTypesComponent,
    BoardTypesComponent,
    BoardTypeFormComponent,
    RoomTypeFormComponent,
    FilterByPropertyPipe,
    CustomSnackBarComponent,
    PhotoPreviewDialogComponent
  ],
  imports: [
    NgxEditorModule,
    MaterialFileInputModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateService,
        deps: [HttpClient]
      }
    }),
    MatNativeDateModule,
    HttpClientModule,
    FormsModule,
		ReactiveFormsModule,
    NgbModule,
    NgxIntlTelInputModule,
    appRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule
  ],
  providers: [NgxImageCompressService, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  }, MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
