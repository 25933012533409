<div class="container page-container-yk">
    <div class="row page-header">
      <h3 class="page-header">{{ 'TitleTermsFrontOffice' | translate }}</h3>
    </div>
  
    <div class="row">
      <div class="col">
        <div class="kiosk-container">
          <div class="result-container">
            <div [innerHtml]='termContent'></div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col text-center">
        <button (click)="prevPage()" type="button"
          class="btn btn-lg btn-secondary">{{ 'ButtonRevokeFrontOffice' | translate }}</button>
      </div>
      <div class="col text-center">
        <button (click)="nextPage()" type="button"
          class="btn btn-lg btn-success">{{ 'ButtonAcceptFrontOffice' | translate }}</button>
      </div>
    </div>
  
    <div class="row row-action-two">
      <div class="col text-center">
        <p (click)="exit()" class="link-button btn-lg cancel-button">{{ 'ButtonCancelFrontOffice' | translate }}</p>
      </div>
      <div class="col text-center">
        
      </div>
    </div>
  
  </div>
  