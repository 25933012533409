import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import * as _ from "underscore";
import { LoadingService } from 'src/app/services/loading/loading.service';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { TermModel } from 'src/app/shared/models/TermModel.model';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { CreateTermModel } from 'src/app/shared/models/CreateTermModel.model';
import { TermService } from 'src/app/services/term/term.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';


@Component({
  selector: 'app-term-form',
  templateUrl: './term-form.component.html',
  styleUrls: ['./term-form.component.scss']
})
export class TermFormComponent implements OnInit, OnDestroy {

  constructor(private termService: TermService, private dictionariesService: DictionariesService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService, private router: Router, private utilitiesService: UtilitiesService) { }

  term: CreateTermModel = {
    id: 0,
    text: null,
    languageId: 0,
    publishDate: null,
    lastVersionNy: false
  };
  currentTerm: TermModel = new TermModel();
  languages: DictionaryModel[];
  subcompanies: DictionaryModel[];
  termTypes: DictionaryModel[];
  enableSave: boolean;
  editor: Editor;
  html: string;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  arrayOfRequests: any[] = [];

  ngOnInit(): void {
    const $this = this;
    $this.editor = new Editor();
    $this.html = '';
    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.dictionariesService.GetLanguageDictionary());
    if ($this.editDataServiceService.termIdToEdit != 0) {
      $this.arrayOfRequests.push($this.termService.GetTermByIdBackoffice($this.editDataServiceService.termIdToEdit));
    }

    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {
        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];
          switch (i) {
            case 0:
              $this.languages = resp as DictionaryModel[];
              break;
            case 1:
              $this.term = resp as TermModel;
              $this.html = decodeURIComponent(escape(window.atob($this.term.text)));
              $this.currentTerm = resp as TermModel;
              $this.editDataServiceService.termIdToEdit = 0;
              break;
          }
          $this.checkFields();
        }
        
        $this.loadingService.toggle("");
      });
  }

  checkFields() {
    const $this = this;
    if (!$this.utilitiesService.isNullOrEmpty($this.html)) {
      $this.term.text = window.btoa(unescape(encodeURIComponent($this.html)));
    }
    const objKeys = _.keys($this.term);
    const notRequiredFields = ['id', 'termId', 'language'];
    const requiredNumber = [];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.term[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.term[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/terms"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.term.publishDate = moment($this.term.publishDate).toISOString();

    $this.termService.SaveTerm($this.term).subscribe((resp) => {
      $this.term.id = resp.id;
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/terms"]);
    });
  }

  ngOnDestroy(): void {
    const $this = this;
    $this.editor.destroy();
  }

}
