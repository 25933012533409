import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  public convertKeysToFirstLetterUppercase(data: any): any {
    if (!data || typeof data !== 'object') {
      return data;
    }

    const result: any = {};

    Object.keys(data).forEach(key => {
      const firstLetterUppercaseKey = key.charAt(0).toUpperCase() + key.slice(1);
      result[firstLetterUppercaseKey] = data[key];
    });

    return result;
  }

  public isNullOrEmpty(str): boolean {
    return (!str || /^\s*$/.test(str));
  }
}
