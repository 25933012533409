export enum PaymentMethod {
    None,
    Cash,
    ATM
}

export const PaymentMethodLabel = new Map<PaymentMethod, string>([
    [PaymentMethod.None, 'Erro no Encoding do Cartão'],
    [PaymentMethod.Cash, 'Dinheiro'],
    [PaymentMethod.ATM, 'Multibanco']
  ]);
