<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <form>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Descrição (*)</mat-label>
              <input matInput [(ngModel)]="translation.description" (change)="checkFields()" name="description">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Chave de Tradução</mat-label>
              <input matInput [(ngModel)]="translation.translationKey" name="translationKey">
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Idioma (*)</mat-label>
              <mat-select [(ngModel)]="translation.languageId" (selectionChange)="checkFields()" name="languageId">
                <mat-option *ngFor="let language of languages" [value]="language.id">
                  {{ language.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
