import { Component, OnInit } from '@angular/core';
import { StoreDataService } from '../services/storeData/store-data.service';
import { LoadingService } from '../services/loading/loading.service';
import { LocalService } from '../services/local/local.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  localName: string;
  constructor(private storeDataService: StoreDataService, private localService: LocalService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    const $this = this;

    $this.storeDataService.change.subscribe((quiosqueInfo: any) => {
      if (quiosqueInfo != null) {
        $this.loadingService.toggle("");
        $this.localService.GetKioskLocal(quiosqueInfo.id).subscribe((resp) => {
          $this.localName = resp.name;
          $this.loadingService.toggle("");
        });
      }
    });
  }

}
