import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { KioskService } from '../services/kiosk/kiosk.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language/language.service';
import { LoadingService } from '../services/loading/loading.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  logoSrc: string = "";
  listOfLanguages: any[] = [];

  constructor(private kioskService: KioskService, private loadingService: LoadingService,
    private languageService: LanguageService, private translate: TranslateService) {

    let $this = this;

    $this.languageService.GetLanguages().subscribe(resp => {
      if (resp != null) {

        for (let i = 0; i < resp.length; i++) {
          const language = resp[i];
          $this.listOfLanguages.push({ id: language.id, description: language.description, languageImage: "data:image/png;base64," + language.languageImage, iso: language.iso });
        }

        setTimeout(() => {
          let el = document.querySelectorAll('.language-item');
          if (el.length > 0) {
            let currentLanguage = $this.translate.currentLang != undefined ? JSON.parse($this.translate.currentLang).id : undefined;
            if (currentLanguage === undefined) {
              el[0].classList.add('active');
            } else {
              let langIndex = _.findLastIndex($this.listOfLanguages, { id: currentLanguage });
              el[langIndex].classList.add('active');
            }
          }
        }, 0);

      }

    });

  }

  ngOnInit() {
    let $this = this;
    $this.loadingService.toggle("");
    $this.kioskService.GetKioskByIpAddress().subscribe(resp => {

      if (resp != null) {
        $this.logoSrc = "data:image/png;base64," + resp.logo;
        $this.loadingService.toggle("");
      }

    });
  }

  changeLanguage(event: any, language: any) {

    const $this = this;

    let el = document.querySelectorAll('.language-item');
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove('active');
    }

    const hasClass = event.currentTarget.classList.contains('active');

    if (!hasClass) {
      event.currentTarget.classList.add('active');
    }

    $this.loadingService.toggle("");
    $this.translate.use(JSON.stringify(language));

    setTimeout(() => { $this.loadingService.toggle(""); }, 3000);

  }
}
