import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
import { LoadingService } from '../services/loading/loading.service';
import { TermService } from '../services/term/term.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StoreDataService } from '../services/storeData/store-data.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  termContent: SafeHtml;
  currentTerm: any;
  currentBooking: any;
  //currentBooking = JSON.parse(localStorage.getItem('cb'));

  constructor(private exitCheckinService: ExitCheckinService, private router: Router, private termService: TermService,
    private domSanitizer: DomSanitizer, private loadingService: LoadingService, private storeDataService: StoreDataService,
    private translate: TranslateService) { }

  ngOnInit(): void {

    const $this = this;

    $this.currentBooking = JSON.parse($this.storeDataService.cb);

    $this.loadingService.toggle("");

    if ($this.exitCheckinService.renderTerm.observers.length == 0) {

      $this.exitCheckinService.renderTerm.subscribe((safeHtml: string) => {
        $this.termContent = $this.domSanitizer.bypassSecurityTrustHtml(safeHtml);
        $this.loadingService.toggle("");
      });

    }

    $this.termService.GetAllActiveTerms().subscribe(resp => {

      let currentLanguage = JSON.parse($this.translate.currentLang);

      if(currentLanguage != null){
        $this.currentTerm = _.findWhere(resp, {languageId: currentLanguage.id});
      }else{
        $this.currentTerm = resp[0];
      }

      $this.termContent = $this.domSanitizer.bypassSecurityTrustHtml($this.currentTerm.text);
      $this.loadingService.toggle("");

    });

  }

  nextPage() {

    const $this = this;

    $this.loadingService.toggle("");

    $this.termService.AcceptTerm($this.currentBooking.yKioskBookingId, true, $this.currentTerm.id).subscribe(resp => {
      $this.loadingService.toggle("");
      $this.router.navigate(['/guest-number-edit']);
    });

  }

  prevPage() {
    const $this = this;
    $this.router.navigate(['/search-result']);
  }

  exit() {
    const $this = this;
    $this.exitCheckinService.openPopup();
  }

}
