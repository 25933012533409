import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { MoneyLevelsService } from 'src/app/services/moneyLevels/money-levels.service';
import { KioskModel } from 'src/app/shared/models/KioskModel.model';
import { TubeType, TubeTypeLabel } from 'src/app/shared/models/enums/tube-type';
import { KioskTubeLevelModel } from 'src/app/shared/models/kiosk-tube-level-model';
import { MoneyLevelsModel } from 'src/app/shared/models/money-levels-model';
import * as _ from 'underscore';

@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.scss']
})
export class LevelsComponent {

  moneyLevels: MoneyLevelsModel;
  differentKiosks: KioskModel[];
  moneyLevelsByKiosk: any[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService, private moneyLevelsService: MoneyLevelsService, private cdRef: ChangeDetectorRef) {

    const $this = this;

    $this.loadingService.toggle("");

    $this.moneyLevelsService.GetMoneyLevels().subscribe((resp: MoneyLevelsModel) => {
      $this.moneyLevels = resp;

      for (let i = 0; i < $this.moneyLevels.kioskCashboxLevels.length; i++) {
        const level = $this.moneyLevels.kioskCashboxLevels[i];
        level.snapTime = moment(level.snapTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY  HH:mm:ss');
      }

      for (let i = 0; i < $this.moneyLevels.kioskTubeLevels.length; i++) {
        const level = $this.moneyLevels.kioskTubeLevels[i];
        level.snapTime = moment(level.snapTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY  HH:mm:ss');
      }

      $this.getDistinctKioskIds($this.moneyLevels);
      $this.loadingService.toggle("");
    });
  }

  getDistinctKioskIds(argData: MoneyLevelsModel) {
    const $this = this;
    const tubeLevelsKioskIds = _.pluck(argData.kioskTubeLevels, 'kiosk');
    const cashboxLevelsKioskIds = _.pluck(argData.kioskCashboxLevels, 'kiosk');
    const allKioskIds = tubeLevelsKioskIds.concat(cashboxLevelsKioskIds);
    $this.differentKiosks = _.uniq(allKioskIds, (kiosk) => kiosk.id);

    for (let i = 0; i < $this.differentKiosks.length; i++) {
      const currentKiosk = $this.differentKiosks[i];
      $this.moneyLevelsByKiosk.push(currentKiosk);
      _.extend($this.moneyLevelsByKiosk[i], {tubeLevels:_.where(argData.kioskTubeLevels, {kioskId: currentKiosk.id})});
      _.extend($this.moneyLevelsByKiosk[i], {cashboxLevels:_.where(argData.kioskCashboxLevels, {kioskId: currentKiosk.id})});
    }
  }

  getTubeTypeLabel(type: TubeType): string {
    return TubeTypeLabel.get(type) || '-';
  }

}
