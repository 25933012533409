import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment/moment';
import { ColibriConnectionService } from '../services/colibriConnection/colibri-connection.service';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
import { LoadingService } from '../services/loading/loading.service';
import { DictionariesService } from '../services/dictionaries/dictionaries.service';
import { SefConnectionService } from '../services/sefConnection/sef-connection.service';
import { BookingService } from '../services/booking/booking.service';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SEFModel } from '../shared/models/SEFModel.model';
import { ChangeDetectorRef } from '@angular/core';
import { BookingColibriModel } from '../shared/models/BookingColibriModel.model';
import { StoreDataService } from '../services/storeData/store-data.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CustomTranslateService } from '../services/custom-translate/custom-translate.service';
import {TranslateService} from '@ngx-translate/core';
import { UtilitiesService } from '../services/utilities-service/utilities.service';

@Component({
  selector: 'app-sef-form',
  templateUrl: './sef-form.component.html',
  styleUrls: ['./sef-form.component.css']
})
export class SefFormComponent implements AfterViewInit {

  selectedImagePreview: string = "../../assets/imgs/scan_erro.png";
  selectedFile: File;
  imageBase64: string;
  guestGenderOptions: any = [];
  disableNextButton: boolean = true;
  questionNumber: number = 0;
  documentInput: string;
  questionOne: string;
  questionTwo: string;
  questionThree: string;
  questionFour: string;
  questionFifth: any;
  questionSixth: string;
  questionSeventh: string;
  questionEighth: string;
  questionNinth: string;
  questionTenth: string;
  questionEleventh: string;
  questionTwelfth: string;
  questionThirteenth: string;
  questionFourteenth: string;
  sefCountries: Array<any> = [];
  sefCountryNames: Array<string> = [];
  sefDocumentTypes: Array<any> = [];
  sefDocumentTypeName: Array<string> = [];
  currentGuest: SEFModel;
  guestsList: Array<SEFModel> = [];
  currentBooking: BookingColibriModel;
  adultCounter: number = 0;
  kidCounter: number = 0;
  guestIndex: number = 0;
  principalGuest: boolean = false;
  hasFile: boolean = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Portugal, CountryISO.Spain];
  numberInputValue: any;
  phoneForm = new UntypedFormGroup({
		phone: new UntypedFormControl(undefined, [Validators.required])
	});

  @Input()
  email: string | boolean
  @ViewChild('instanceCountry', { static: false }) instanceCountry: NgbTypeahead;
  @ViewChild('instanceDocumentType', { static: false }) instanceDocumentType: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private colibriConnectionService: ColibriConnectionService, private exitCheckinService: ExitCheckinService, private router: Router,
    private loadingService: LoadingService, private dictionariesService: DictionariesService, private cdRef: ChangeDetectorRef,
    private sefConnectionService: SefConnectionService, private bookingService: BookingService, private storeDataService: StoreDataService,
    private imageCompress: NgxImageCompressService, private customTranslateService: CustomTranslateService, private translate: TranslateService, private utilitiesService: UtilitiesService) {

  }

  ngAfterViewInit(): void {

    const $this = this;

    /*let guestsString = localStorage.getItem('guests');
    let currentBookingString = localStorage.getItem('cb');*/

    $this.customTranslateService.change.subscribe((genderByLanguage: any) => {
      $this.guestGenderOptions = genderByLanguage;
    });

    $this.customTranslateService.changeGenderLanguage(JSON.parse($this.translate.currentLang).iso);

    let guestsString = $this.storeDataService.guests;
    let currentBookingString = $this.storeDataService.cb;

    if (!$this.utilitiesService.isNullOrEmpty(guestsString)) {
      $this.guestsList = JSON.parse(guestsString);
    }

    if (!$this.utilitiesService.isNullOrEmpty(currentBookingString)) {
      $this.currentBooking = JSON.parse(currentBookingString);

      let guestCounts = $this.currentBooking.guestCounts;

      for (let index = 0; index < guestCounts.length; index++) {
        const element = guestCounts[index];

        if (element.isAdult) {
          $this.adultCounter += element.count;
        }
        else {
          $this.kidCounter += element.count;
        }
      }
    }

    $this.loadingService.toggle("");

    $this.dictionariesService.GetICAOCountriesDictionary().subscribe(resp => {

      $this.sefCountries = resp;

      for (let index = 0; index < $this.sefCountries.length; index++) {
        const element = $this.sefCountries[index];
        $this.sefCountryNames.push(element.name);
      }

      $this.dictionariesService.GetSEFDocumentTypesSEFCodeDictionary().subscribe(resp => {

        $this.sefDocumentTypes = resp;

        for (let index = 0; index < $this.sefDocumentTypes.length; index++) {
          const element = $this.sefDocumentTypes[index];
          $this.sefDocumentTypeName.push(element.name);
        }

        //let currentGuestString: string = localStorage.getItem('currentGuest');
        let currentGuestString: string = $this.storeDataService.currentGuest;

        if (!$this.utilitiesService.isNullOrEmpty(currentGuestString)) {
          $this.currentGuest = JSON.parse(currentGuestString);
          if (!$this.utilitiesService.isNullOrEmpty($this.currentGuest.documentImage)) {
            $this.selectedImagePreview = "data:image/png;base64," + $this.currentGuest.documentImage;
            $this.hasFile = true;
          }
          $this.disableNextButton = !$this.hasFile;
          $this.questionTwo = $this.currentGuest.guestFirstName;
          $this.questionThree = $this.currentGuest.guestLastName;
          $this.questionFour = _.findWhere($this.sefCountries, { icaoCode: $this.currentGuest.nationality })?.name;
          $this.questionFifth = moment($this.currentGuest.dateOfBirth, "DD/MM/YY").format("YYYY-MM-DD");
          $this.questionSixth = $this.currentGuest.birthplace;
          $this.questionSeventh = $this.currentGuest.documentIdentification;
          $this.questionEighth = _.findWhere($this.sefDocumentTypes, { sefCode: $this.currentGuest.documentType })?.name;
          $this.questionNinth = _.findWhere($this.sefCountries, { icaoCode: $this.currentGuest.issuingCountry })?.name;
          $this.questionTenth = _.findWhere($this.sefCountries, { icaoCode: $this.currentGuest.countryOfResidence })?.name;
          $this.questionEleventh = $this.currentGuest.residenceCity;
          $this.questionTwelfth = $this.currentGuest.mobileNumber;
          $this.questionThirteenth = $this.currentGuest.email;
          $this.questionFourteenth = $this.currentGuest.guestGender;

          $this.cdRef.detectChanges();

          $this.phoneForm.valueChanges.subscribe( () => {
            $this.cdRef.detectChanges()
          });

          $this.phoneForm.controls.phone.setValue($this.currentGuest.mobileNumber);

        }

        $this.loadingService.toggle("");

        $this.principalGuest = $this.currentGuest?.yKioskSEFDocumentId == 0 && $this.guestsList.length == 0;

        if (!$this.principalGuest) {
          $this.principalGuest = $this.currentGuest?.yKioskSEFDocumentId > 0 && _.findLastIndex($this.guestsList, { yKioskSEFDocumentId: $this.currentGuest.yKioskSEFDocumentId }) == 0;
        }


      });

    });

  }

  searchCountry = (text$: Observable<string>) => {
    const $this = this;
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = $this.click$.pipe(filter(() => !$this.instanceCountry.isPopupOpen()));
    const inputFocus$ = $this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? $this.sefCountryNames
        : $this.sefCountryNames.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 8))
    );
  }

  searchDocumentType = (text$: Observable<string>) => {
    const $this = this;
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = $this.click$.pipe(filter(() => !$this.instanceDocumentType.isPopupOpen()));
    const inputFocus$ = $this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? $this.sefDocumentTypeName
        : $this.sefDocumentTypeName.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 8))
    );
  }

  selectValueCountries($event) {
    let $this = this;

    if ($event.item != null) {
      $this.disableNextButton = _.where($this.sefCountries, { "name": $event.item }).length != 1;
    }
  }

  selectDocumentTypes($event) {
    let $this = this;

    if ($event.item != null) {
      $this.disableNextButton = _.where($this.sefDocumentTypes, { "name": $event.item }).length != 1;
    }
  }

  nextPage() {
    const $this = this;

    if(!$this.principalGuest && $this.questionNumber == 10){
      $this.questionNumber = 12;
    }

    if ($this.questionNumber >= 13) {

      $this.loadingService.toggle("");

      $this.currentGuest.guestFirstName = $this.questionTwo;
      $this.currentGuest.guestLastName = $this.questionThree;
      $this.currentGuest.guestName = $this.questionTwo + " " + $this.questionThree;
      $this.currentGuest.nationality = _.findWhere($this.sefCountries, { name: $this.questionFour })?.icaoCode;
      $this.currentGuest.dateOfBirth = moment($this.questionFifth, "YYYY-MM-DD").format("DD/MM/YY");
      $this.currentGuest.birthplace = $this.questionSixth;
      $this.currentGuest.documentIdentification = $this.questionSeventh;
      $this.currentGuest.documentType = _.findWhere($this.sefDocumentTypes, { name: $this.questionEighth })?.sefCode;
      $this.currentGuest.issuingCountry = _.findWhere($this.sefCountries, { name: $this.questionNinth })?.icaoCode;
      $this.currentGuest.countryOfResidence = _.findWhere($this.sefCountries, { name: $this.questionTenth })?.icaoCode;
      $this.currentGuest.residenceCity = $this.questionEleventh;
      $this.currentGuest.mobileNumber = $this.numberInputValue;
      $this.currentGuest.email = $this.questionThirteenth;
      $this.currentGuest.guestGender = $this.questionFourteenth;

      let age = moment().diff(moment($this.currentGuest.dateOfBirth, "DD/MM/YY"), 'years', false);

      $this.currentGuest.isAdult = age > 10 ? 1 : 0;

      if (_.findWhere($this.guestsList, { yKioskSEFDocumentId: $this.currentGuest.yKioskSEFDocumentId })) {
        $this.guestIndex = _.findLastIndex($this.guestsList, { yKioskSEFDocumentId: $this.currentGuest.yKioskSEFDocumentId });
        $this.guestsList[$this.guestIndex] = $this.currentGuest;
      } else {
        $this.currentGuest.yKioskBookingId = $this.currentBooking.yKioskBookingId;
        $this.guestsList.push($this.currentGuest);
      }

      if(_.findWhere($this.guestsList,{documentIdentification:null})){

            $this.guestsList[$this.guestIndex] = $this.currentGuest;
            $this.guestIndex++
            $this.storeDataService.currentGuest = JSON.stringify($this.guestsList[$this.guestIndex]);
            $this.storeDataService.guests = JSON.stringify($this.guestsList);
            $this.router.navigate(['next-guest']);
            
            $this.loadingService.toggle("");

      }else{

        $this.sefConnectionService.SaveInDataBase($this.guestsList).subscribe(resp => {

          $this.guestsList = resp;
          $this.currentBooking.numberOfGuests = ($this.adultCounter + $this.kidCounter).toString();

          $this.storeDataService.cb = JSON.stringify($this.currentBooking);
          $this.storeDataService.guests = JSON.stringify($this.guestsList);
          $this.storeDataService.currentGuest = "";

          if (parseInt($this.currentBooking.numberOfGuests) == $this.guestsList.length) {
            $this.router.navigate(['guest-number-edit']);
          } else {
            let guestOwner = {
              guestName: $this.currentBooking.ownerGuest,
              guestFirstName: "",
              guestLastName: "",
              nationality: "",
              dateOfBirth: "",
              birthplace: "",
              documentIdentification: "",
              documentType: "",
              issuingCountry: "",
              checkin: $this.currentBooking.checkin,
              checkout: $this.currentBooking.checkout,
              countryOfResidence: "",
              residenceCity: "",
              mobileNumber: "",
              email: "",
              documentImage: "",
              yKioskBookingId: 0,
              yKioskSEFDocumentId: 0,
              yKioskStateId: 0,
              isAdult: 0,
              localId: 0,
              isProductionMode: 0,
            };

            $this.storeDataService.currentGuest = JSON.stringify(guestOwner);

            $this.router.navigate(['next-guest']);
          }

          $this.loadingService.toggle("");
        });
      }

    } else {
      $this.questionNumber++;
      $this.disableNextButton = true;

      switch ($this.questionNumber) {
        case 1:
          $this.formValidation("questionTwo");
          break;
        case 2:
          $this.formValidation("questionThree");
          break;
        case 3:
          $this.formValidation("questionFour");
          break;
        case 4:
          $this.formValidation("questionFifth");
          break;
        case 5:
          $this.formValidation("questionSixth");
          break;
        case 6:
          $this.formValidation("questionSeventh");
          break;
        case 7:
          $this.formValidation("questionEighth");
          break;
        case 8:
          $this.formValidation("questionNinth");
          break;
        case 9:
          $this.formValidation("questionTenth");
          break;
        case 10:
          $this.formValidation("questionEleventh");
          break;
        case 11:
          $this.formValidation("questionTwelfth");
          break;
        case 12:
          $this.formValidation("questionThirteenth");
          break;
          case 13:
            $this.formValidation("questionFourteenth");
            break;
      }
    }
  }

  onFileChanged(event) {
    const $this = this;
    $this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($this.selectedFile);
    reader.onload = () => {
      $this.selectedImagePreview = reader.result.toString();

      if ($this.selectedImagePreview.length > 0 && $this.selectedImagePreview.split(',').length > 0 && $this.selectedImagePreview.length <= 10000000) {

        $this.imageCompress.compressFile($this.selectedImagePreview, -2, 100, 50).then(result => {
          $this.currentGuest.documentImage = result.split(',')[1];

          $this.hasFile = true;
          $this.disableNextButton = !$this.hasFile;
        });

      } else {
        $this.disableNextButton = !$this.hasFile;
      }

    };
  }

  prevPage() {
    const $this = this;

    if ($this.questionNumber == 0) {

      $this.exitCheckinService.openPopup();

    } else {

      $this.questionNumber--;

      if ($this.questionNumber == 0) {
        if ($this.selectedImagePreview.length) {
          $this.disableNextButton = false;
        }
      }

    }
  }

  clear() {
    const $this = this;
    $this.selectedImagePreview = "../../assets/imgs/scan_erro.png";
    $this.documentInput = "";
    $this.hasFile = false;
    $this.disableNextButton = !$this.hasFile;

  }

  changeValue(event: any) {
    const $this = this;
    if (event === null || (event != undefined && event.target === undefined)) {
      if (event != null && event.target === undefined) {
        $this.disableNextButton = event.internationalNumber == "";
        $this.numberInputValue = event.number;
      }
    } else {
      if(event != null && event != undefined && event.target != undefined){
        $this.formValidation(event.target.id);
      }
    }
  }

  formValidation(currentPage: string) {
    const $this = this;
    switch (currentPage) {
      case "questionOne":
        $this.disableNextButton = $this.selectedImagePreview.length <= 0;
        break;
      case "questionTwo":
        $this.disableNextButton = $this.questionTwo.length <= 0 || $this.questionTwo.length > 40;
        break;
      case "questionThree":
        $this.disableNextButton = $this.questionThree.length <= 0 || $this.questionThree.length > 40;
        break;
      case "questionFour":
        $this.disableNextButton = _.where($this.sefCountries, { name: $this.questionFour })?.length != 1;
        break;
      case "questionFifth":
        let inputDate = moment($this.questionFifth, "YYYY-MM-DD");
        $this.disableNextButton = !inputDate.isValid() || inputDate > moment();
        break;
      case "questionSixth":
        $this.disableNextButton = $this.questionSixth == null || $this.questionSixth.length <= 0 || $this.questionSixth.length > 30;
        break;
      case "questionSeventh":
        let sameValue = _.findWhere($this.guestsList, { documentIdentification: $this.questionSeventh });
        let alreadyScan:boolean = sameValue != null;
        let edit:SEFModel = $this.currentGuest.yKioskSEFDocumentId > 0 ? _.findWhere($this.guestsList, { yKioskSEFDocumentId: $this.currentGuest.yKioskSEFDocumentId }) : null;

        if(alreadyScan && edit != null){
          alreadyScan = sameValue != edit;
        }

        $this.disableNextButton = $this.questionSeventh == null || $this.questionSeventh.length <= 0 || alreadyScan || $this.questionSeventh.length > 16;
        break;
      case "questionEighth":
        $this.disableNextButton = _.where($this.sefDocumentTypes, { "name": $this.questionEighth })?.length != 1;
        break;
      case "questionNinth":
        $this.disableNextButton = _.where($this.sefCountries, { "name": $this.questionNinth })?.length != 1;
        break;
      case "questionTenth":
        $this.disableNextButton = _.where($this.sefCountries, { "name": $this.questionTenth })?.length != 1;
        break;
      case "questionEleventh":
        $this.disableNextButton =  $this.questionEleventh == null || $this.questionEleventh.length <= 0 || $this.questionSeventh.length > 30;
        break;
      case "questionTwelfth":
        $this.disableNextButton = $this.questionTwelfth == null || $this.questionTwelfth.length <= 0;
        break;
      case "questionThirteenth":
        $this.disableNextButton = !$this.validateEmail($this.questionThirteenth);
        break;
      case "questionFourteenth":
        $this.disableNextButton = $this.questionFourteenth == null || $this.questionFourteenth.length <= 0;
        break;
    }
  }

  exit() {
    const $this = this;
    $this.exitCheckinService.openPopup();
  }

  validateEmail(email): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}
