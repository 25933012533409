<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <form>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Nome (*)</mat-label>
              <input matInput [(ngModel)]="email.name" (keyup)="checkFields()" name="name">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Subempresa (*)</mat-label>
              <mat-select [(ngModel)]="email.subcompanyId" name="subcompanyId" (selectionChange)="checkFields()">
                <mat-option *ngFor="let subcompany of subcompanies" [value]="subcompany.id">
                  {{ subcompany.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Tipo de Email (*)</mat-label>
              <mat-select [(ngModel)]="email.emailTypeId" name="emailTypeId" (selectionChange)="checkFields()">
                <mat-option *ngFor="let emailType of emailTypes" [value]="emailType.id">
                  {{ emailType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Idioma (*)</mat-label>
              <mat-select [(ngModel)]="email.languageId" name="languageId" (selectionChange)="checkFields()">
                <mat-option *ngFor="let language of languages" [value]="language.id">
                  {{ language.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Assunto (*)</mat-label>
              <input matInput [(ngModel)]="email.subject" (keyup)="checkFields()" name="subject">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <table mat-table [dataSource]="dataSourceTags" class="mat-elevation-z8">
              <ng-container matColumnDef="tag">
                <th mat-header-cell *matHeaderCellDef> TAG </th>
                <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> DESCRIÇÃO </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="NgxEditor__Wrapper ngxeditor-container">
              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
              <ngx-editor [editor]="editor" [(ngModel)]="html" (focusout)="checkFields()" name="body"
                placeholder="Descrição (*)">
              </ngx-editor>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button
              color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
