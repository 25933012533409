import { Component, OnInit } from '@angular/core';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
import { Router } from '@angular/router';
import { TermService } from '../services/term/term.service';
import { LoadingService } from '../services/loading/loading.service';
import { StoreDataService } from '../services/storeData/store-data.service';
import { UtilitiesService } from '../services/utilities-service/utilities.service';

@Component({
  selector: 'app-result-view',
  templateUrl: './result-view.component.html',
  styleUrls: ['./result-view.component.css']
})
export class ResultViewComponent implements OnInit {

  currentBookingData: any;
  guestOwner: string;
  bookingNumber: string;
  checkinCheckout: string;
  numberOfGuests: string;
  numberOfNights: string;

  constructor(private exitCheckinService: ExitCheckinService, private router: Router,
    private termService: TermService, private loadingService: LoadingService, private storeDataService: StoreDataService, private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {

    const $this = this;

    /*let currentBooking = localStorage.getItem('cb');*/
    let currentBooking = $this.storeDataService.cb;

    if (!$this.utilitiesService.isNullOrEmpty(currentBooking)) {
      $this.currentBookingData = JSON.parse(currentBooking);
      $this.guestOwner = $this.currentBookingData.ownerGuest;
      $this.bookingNumber = $this.currentBookingData.bookingNumber;
      $this.checkinCheckout = $this.currentBookingData.checkin + ' / ' + $this.currentBookingData.checkout;
      $this.numberOfGuests = $this.currentBookingData.numberOfGuests;
      $this.numberOfNights = $this.currentBookingData.numberOfNights;
    }

  }

  nextPage() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.termService.TermsAcceptedInBooking($this.currentBookingData.yKioskBookingId).subscribe(termsAccepted => {
      $this.loadingService.toggle("");
      if (termsAccepted) {
        $this.router.navigate(['/guest-number-edit']);
      } else {
        $this.router.navigate(['/terms']);
      }

    });

  }

  prevPage() {
    const $this = this;
    /*localStorage.removeItem('cb');
    localStorage.removeItem('guests');
    localStorage.removeItem('currentGuest');*/

    $this.storeDataService.cb = '';
    $this.storeDataService.guests = '';
    $this.storeDataService.currentGuest = '';
    
    $this.router.navigate(['/search-booking']);
  }

  exit() {
    const $this = this;
    $this.exitCheckinService.openPopup();
  }

}
