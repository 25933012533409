import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MoneyLevelsModel } from 'src/app/shared/models/money-levels-model';

@Injectable({
  providedIn: 'root'
})
export class MoneyLevelsService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public GetMoneyLevels(): Observable<MoneyLevelsModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<MoneyLevelsModel>(environment.apiUrl + 'api/v1/MoneyLevels', header).pipe();
  }

}
