<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px;">
        <div class="row row-form-container">
          <form>
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome</mat-label>
                  <input matInput [(ngModel)]="local.name" (keyup)="checkFields()" name="name">
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Subempresa</mat-label>
                  <mat-select [(ngModel)]="local.subcompanyId" (selectionChange)="checkFields()" name="subcompanyId">
                    <mat-option *ngFor="let subcompany of subcompanies" [value]="subcompany.id">
                      {{ subcompany.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>PMS a Integrar</mat-label>
                  <mat-select [(ngModel)]="local.pmsIntegrationType" (selectionChange)="checkFields()"
                    name="pmsIntegrationType">
                    <mat-option *ngFor="let pmsType of pmsTypes | enumToArray" [value]="pmsType.index">
                      {{ pmsType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="hasSEF" class="col-xl-6 col-md-6 col-sm-12">
                <div class="input-full-width mat-form-field-flex slide-container">
                  <mat-slide-toggle [(ngModel)]="local.sefIntegrationEnable" (change)="checkFields()"
                    name="sefIntegrationEnable">Integração com o SEF?
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
            <div *ngIf="local.pmsIntegrationType == 2" class="row" style="margin-bottom: 20px;">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-toolbar>
                  <span>Dados Integração PMS Protel</span>
                </mat-toolbar>
              </div>
            </div>
            <div *ngIf="local.pmsIntegrationType == 2" class="row">
              <div class="col-xl-9 col-md-9 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>EndPoint Protel</mat-label>
                  <input matInput [(ngModel)]="local.protelEndPoint" (keyup)="checkFields()" name="protelEndPoint">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Id do Hotel Protel</mat-label>
                  <input matInput [(ngModel)]="local.protelHotelId" (keyup)="checkFields()" name="protelHotelId">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="local.pmsIntegrationType == 2" class="row" style="margin-bottom: 20px;">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-toolbar>
                  <span>Estados a disponibilizar cartão</span>
                </mat-toolbar>
              </div>
            </div>
            <div *ngIf="local.pmsIntegrationType == 2" class="row">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Estados a Disponibilizar Cartão</mat-label>
                  <mat-select [(ngModel)]="local.protelStatusToProvideCard" name="protelStatusToProvideCard" multiple (selectionChange)="checkFields()">
                    <mat-option *ngFor="let protelStatus of lstProtelStatus" [value]="protelStatus.id">{{protelStatus.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="local.pmsIntegrationType == 1" class="row" style="margin-bottom: 20px;">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-toolbar>
                  <span>Dados Integração PMS Colibri</span>
                </mat-toolbar>
              </div>
            </div>
            <div *ngIf="local.pmsIntegrationType == 1" class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Utilizador de Acesso (Colibri)</mat-label>
                  <input matInput [(ngModel)]="local.locationKeyColibri" (keyup)="checkFields()"
                    name="locationKeyColibri">
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Chave de Acesso (Colibri)</mat-label>
                  <input matInput [(ngModel)]="local.accessKeyColibri" (keyup)="checkFields()" name="accessKeyColibri">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="local.sefIntegrationEnable" class="row" style="margin-bottom: 20px;">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-toolbar>
                  <span>Dados Integração SEF</span>
                </mat-toolbar>
              </div>
            </div>
            <div *ngIf="local.sefIntegrationEnable" class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Unidade Hoteleira (Código SEF)</mat-label>
                  <input matInput [(ngModel)]="local.hotelUnitCodeSEF" (keyup)="checkFields()" name="hotelUnitCodeSEF">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Código de Acesso (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.accessKeySEF" (keyup)="checkFields()" name="accessKeySEF">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Estabelecimento (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.establishmentSEF" (keyup)="checkFields()" name="establishmentSEF">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Abrevietura (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.abbreviationSEF" (keyup)="checkFields()" name="abbreviationSEF">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="local.sefIntegrationEnable" class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Localidade (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.localitySEF" (keyup)="checkFields()" name="localitySEF">
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Endereço (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.addressSEF" (keyup)="checkFields()" name="addressSEF">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="local.sefIntegrationEnable" class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome de Contacto (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.contactNameSEF" (keyup)="checkFields()" name="contactNameSEF">
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Email de Contacto (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.contactEmailSEF" (keyup)="checkFields()" name="contactEmailSEF">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="local.sefIntegrationEnable" class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Código Postal (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.postalCodeSEF" (keyup)="checkFields()" name="postalCodeSEF">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Zona Postal (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.postalZoneSEF" (keyup)="checkFields()" name="postalZoneSEF">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Telefone (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.telephoneSEF" (keyup)="checkFields()" name="telephoneSEF">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Fax (SEF)</mat-label>
                  <input matInput [(ngModel)]="local.faxSEF" (keyup)="checkFields()" name="faxSEF">
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button
              color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
