import { Injectable, EventEmitter, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ExitCheckinService {

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() changeTerms: EventEmitter<any> = new EventEmitter();
  @Output() renderTerm: EventEmitter<any> = new EventEmitter();

  constructor() { }

  openPopup() {
    const $this = this;
    $this.change.emit();
  }

  openTermsPopup() {
    const $this = this;
    $this.changeTerms.emit();
  }

  renderTerms(html: string) {
    const $this = this;
    $this.renderTerm.emit(html);
  }

}
