import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { forkJoin } from 'rxjs';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { AppMessageService } from 'src/app/services/app-message/app-message.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { CreateAppMessageModel } from 'src/app/shared/models/CreateAppMessageModel.model';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { AppMessageModel } from 'src/app/shared/models/AppMessageModel.model';
import * as _ from "underscore";
import { MessageType, MessageTypeLabel } from 'src/app/shared/models/Utilities/MessageType';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';


@Component({
  selector: 'app-app-msg-form',
  templateUrl: './app-msg-form.component.html',
  styleUrls: ['./app-msg-form.component.scss']
})
export class AppMsgFormComponent implements OnInit, OnDestroy {

  constructor(private appMessageService: AppMessageService, private dictionariesService: DictionariesService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService, private router: Router, private utilitiesService: UtilitiesService) { }

  appMessage: CreateAppMessageModel = {
    id: 0,
    name: null,
    body: null,
    messageType: 0,
    languageId: 0,
    subcompanyId: 0
  };
  currentAppMessage: AppMessageModel = new AppMessageModel();
  languages: DictionaryModel[];
  subcompanies: DictionaryModel[];
  enableSave: boolean;
  editor: Editor;
  html: string;
  messageTypes = MessageType;
  messageTypesL = MessageTypeLabel;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  arrayOfRequests: any[] = [];


  ngOnInit(): void {
    const $this = this;
    $this.editor = new Editor();
    $this.html = '';
    $this.arrayOfRequests.push($this.dictionariesService.GetLanguageDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());

    $this.loadingService.toggle("");

    if ($this.editDataServiceService.appMessageIdToEdit != 0) {
      $this.arrayOfRequests.push($this.appMessageService.GetAppMessageToSendByIdOnline($this.editDataServiceService.appMessageIdToEdit));
    }

    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {
        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];
          switch (i) {
            case 0:
              $this.languages = resp as DictionaryModel[];
              break;
            case 1:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 2:
              $this.appMessage = resp as AppMessageModel;
              $this.html = decodeURIComponent(escape(window.atob($this.appMessage.body)));
              $this.currentAppMessage = resp as AppMessageModel;
              $this.editDataServiceService.appMessageIdToEdit = 0;
              break;
          }
        }
        $this.checkFields();
        $this.loadingService.toggle("");
      });
  }

  checkFields() {
    const $this = this;
    if (!$this.utilitiesService.isNullOrEmpty($this.html)) {
      $this.appMessage.body = window.btoa(unescape(encodeURIComponent($this.html)));
    }
    const objKeys = _.keys($this.appMessage);
    const notRequiredFields = ['id', 'language', 'messageType'];
    const requiredNumber = ['subcompanyId', 'languageId'];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.appMessage[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.appMessage[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/app-msgs"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.appMessageService.SaveAppMessageOnline($this.appMessage).subscribe((resp) => {
      $this.appMessage.id = resp.id;
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/app-msgs"]);
    });
  }

  ngOnDestroy(): void {
    const $this = this;
    $this.editor.destroy();
  }

}
