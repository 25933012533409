import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { UserListModel } from 'src/app/shared/models/UserListModel.model';
import { UserModel } from 'src/app/shared/models/UserModel.model';
import { CreateUserModel } from 'src/app/shared/models/CreateUserModel.model';
import { ChangePasswordModel } from 'src/app/shared/models/ChangePasswordModel.model';
import { ChangePinModel } from 'src/app/shared/models/ChangePinModel.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/User/GetAllColumnNames', header).pipe();
  }

  public GetAllUsers(filterParameters: FiltersModel): Observable<UserListModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<UserListModel>(environment.apiUrl + 'api/v1/User/GetAllUsers', filterParameters, header).pipe();
  }

  public DeleteListOfUsers(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
        .set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/User', options).pipe();
  }

  public GetUserById(id:number): Observable<UserModel>{
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.get<UserModel>(environment.apiUrl + 'api/v1/User/' + id, header).pipe();
  }

  public SaveUser(argUserData: CreateUserModel): Observable<string> {

    const $this = this;

    return $this.http.post<string>(environment.apiUrl + 'api/v1/User', argUserData).pipe();

  }

  

  public ChangePassword(changePassword: ChangePasswordModel):Observable<boolean> {
    const $this = this;
    
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }
    return $this.http.post<boolean>(environment.apiUrl + 'api/v1/User/ChangePassword', changePassword, header).pipe();
  }

  public ChangePin(changePin: ChangePinModel):Observable<boolean> {
    const $this = this;
    
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }
    return $this.http.post<boolean>(environment.apiUrl + 'api/v1/User/ChangePin', changePin, header).pipe();
  }
}

