import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language/language.service';
import { LoadingService } from '../services/loading/loading.service';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrls: ['./footer-home.component.css']
})
export class FooterHomeComponent implements OnInit {

  listOfLanguages: any[] = [];

  constructor(private languageService: LanguageService, private translate: TranslateService, private loadingService: LoadingService,
    private exitCheckinService: ExitCheckinService) {
    let $this = this;

    $this.languageService.GetLanguages().subscribe(resp => {
      if (resp != null) {

        for (let i = 0; i < resp.length; i++) {
          const language = resp[i];
          $this.listOfLanguages.push({ id: language.id, description: language.description, languageImage: "data:image/png;base64," + language.languageImage, iso: language.iso });
        }

        setTimeout(() => {
          let el = document.querySelectorAll('img.language-img');
          let currentLanguage = $this.translate.currentLang != undefined ? JSON.parse($this.translate.currentLang).id : undefined;
          if (currentLanguage === undefined) {
            el[0].classList.add('active');
          } else {
            let langIndex = _.findLastIndex($this.listOfLanguages, { id: currentLanguage });
            el[langIndex].classList.add('active');
          }
        }, 0);

      }

    });
  }

  ngOnInit(): void {
  }

  changeLanguage(event: any, language: any) {

    const $this = this;

    let el = document.querySelectorAll('img.language-img');
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove('active');
    }

    const hasClass = event.target.classList.contains('active');

    if (!hasClass) {
      event.target.classList.add('active');
    }

    $this.loadingService.toggle("");
    $this.translate.use(JSON.stringify(language));

    setTimeout(() => { $this.loadingService.toggle(""); }, 3000);

  }

  partteam(){
    window.open("https://www.partteams.com", "_blank");
  }

  terms(){
    const $this = this;
    $this.exitCheckinService.openTermsPopup();
  }

}