<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="row">
      <div class="col-xl-2 col-md-2 col-sm-12">
        <mat-form-field>
          <mat-label>Pesquisar Por...</mat-label>
          <mat-select>
            <mat-option *ngFor="let column of columnList" [value]="column">
              {{ column }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xl-7 col-md-6 col-sm-12">
        <mat-form-field>
          <mat-label>Filtro</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            [(ngModel)]="filters.SearchText"
            #input
          />
        </mat-form-field>
      </div>
      <div class="col-xl-3 col-md-4 col-sm-12">
        <div class="search-buttons-col">
          <button mat-flat-button color="primary" (click)="search()">
            <mat-icon>search</mat-icon>
          </button>
          <button mat-flat-button color="primary">
            <mat-icon>filter_list</mat-icon>
          </button>
          <button
            *ngIf="numSelected != 1"
            (click)="addNew()"
            [disabled]="numSelected >= 1"
            mat-flat-button
            color="primary"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            *ngIf="numSelected == 1"
            mat-flat-button
            color="primary"
            (click)="edit()"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-flat-button
            color="primary"
            (click)="delete()"
            [disabled]="numSelected <= 0"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            [matMenuTriggerFor]="menuPmsSync"
            #menuTrigger
            mat-flat-button
            color="secundary"
            [disabled]="!hasPMSSettings"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuPmsSync="matMenu">
            <button (click)="initializePMSSync()" mat-menu-item>
              <mat-icon>play_arrow</mat-icon>Iniciar o Integrador
            </button>
            <button (click)="forceSyncManual()" mat-menu-item>
              <mat-icon>sync</mat-icon>Forçar o Integrador
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="mat-elevation-z8 search-container">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="select">
            <th mat-header-cell [ngClass]="'custom-w-75'" *matHeaderCellDef>
              <mat-checkbox
                (keyup)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell [ngClass]="'custom-w-75'" *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th
              mat-header-cell
              [ngClass]="'custom-w-75'"
              *matHeaderCellDef
              mat-sort-header
            >
              ID
            </th>
            <td mat-cell [ngClass]="'custom-w-75'" *matCellDef="let row">
              {{ row.id }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="pmsType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PMSType</th>
            <td mat-cell *matCellDef="let row">
              {{ row.pmsType | pmsTypeEnumDescriptionPipe }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="syncHour">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Hora de Sincronização
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.pmsSettings?.hourToSync }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selection.toggle(row)"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
  </mat-card>
</div>
