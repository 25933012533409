import { Pipe, PipeTransform } from '@angular/core';
import { PMSType } from '../shared/models/Utilities/pmstype';

@Pipe({
  name: 'pmsTypeEnumDescriptionPipe'
})
export class PMSTypeEnumDescriptionPipe implements PipeTransform {

  transform(value: number): string {
    return PMSType[value];
  }

}
