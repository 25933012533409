import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LicenseLimitsModel } from 'src/app/shared/models/LicenseLimitsModel.model';
import { CompaniesListModel } from 'src/app/shared/models/CompaniesListModel.model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { CompanyModel } from 'src/app/shared/models/CompanyModel.model';
import { CreateCompanyModel } from 'src/app/shared/models/CreateCompanyModel.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    if ($this.authenticationService.currentUserValue != undefined) {
      $this.userToken = $this.authenticationService.currentUserValue.Token;
    }
  }

  public GetCompanyLimits(): Observable<LicenseLimitsModel> {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<LicenseLimitsModel>(environment.apiUrl + 'api/v1/Company/GetCompanyLimits/' + $this.authenticationService.currentUserValue?.CompanyId, header).pipe();
  }

  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/Company/GetAllColumnNames', header).pipe();
  }

  public GetAllCompanies(filterParameters: FiltersModel): Observable<CompaniesListModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<CompaniesListModel>(environment.apiUrl + 'api/v1/Company/GetAllCompanies', filterParameters, header).pipe();
  }

  public DeleteListOfCompanies(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
        .set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/Company', options).pipe();
  }

  public GetCompanyById(id: number): Observable<CompanyModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<CompanyModel>(environment.apiUrl + 'api/v1/Company/' + id, header).pipe();
  }

  public SaveCompany(argCompanyData: CreateCompanyModel): Observable<string> {

    const $this = this;

    return $this.http.post<string>(environment.apiUrl + 'api/v1/Company', argCompanyData).pipe();

  }
}
