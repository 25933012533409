import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { KioskPeriodService } from 'src/app/services/kioskPeriod/kiosk-period.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { PaymentMethod, PaymentMethodLabel } from 'src/app/shared/models/enums/payment-method';
import { PeriodType, PeriodTypeLabel } from 'src/app/shared/models/enums/period-type';
import { KioskPeriodModel } from 'src/app/shared/models/kiosk-period-model';
import { ReceiptDialogComponent } from '../receipt-dialog/receipt-dialog.component';

@Component({
  selector: 'app-cash-operations',
  templateUrl: './cash-operations.component.html',
  styleUrls: ['./cash-operations.component.scss']
})
export class CashOperationsComponent implements AfterViewChecked {
  displayedColumns: string[] = ['code', 'snapTime', 'kiosk', 'operationType', 'paymentMethod', 'totalEmptiedCashbox', 'totalEmptiedTubes', 'totalLoadedTubes', 'totalMovedToCashbox', 'totalReceived', 'totalRefunded', 'atmReceipt'];
  dataSource: MatTableDataSource<KioskPeriodModel>;
  editMode: boolean = false;
  numSelected: number;
  columnList: string[];
  filters: FiltersModel = {
    ColumnsToSearch: [],
    CurrentPage: 0,
    PageSize: 10,
    OrderColumn: null,
    OrderType: null,
    SearchText: null,
    FilterTags: []
  };
  kioksLimits: number;
  addKiosk: boolean;
  startDate = new FormControl();
  endDate = new FormControl();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService, private kioskPeriodService: KioskPeriodService, private cdRef: ChangeDetectorRef, private dialog: MatDialog) {

    const $this = this;

    $this.startDate.valueChanges.subscribe(value => {
      $this.filters.StartDate = value;
    });

    $this.endDate.valueChanges.subscribe(value => {
      $this.filters.EndDate = value;
    });

    $this.loadingService.toggle("");

    $this.kioskPeriodService.GetAllColumnNames().subscribe((resp: string[]) => {
      $this.columnList = resp;
      $this.refreshList();
    });
  }

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const $this = this;
    if ($this.dataSource == null || $this.dataSource == undefined) {
      return false;
    } else {
      $this.numSelected = $this.selection.selected.length;
      const numRows = $this.dataSource.data.length;
      return $this.numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngAfterViewChecked() {
    const $this = this;
    $this.cdRef.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  search() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.refreshList();
  }

  refreshList() {
    const $this = this;


    if ($this.paginator != null) {
      $this.filters.PageSize = $this.paginator.pageSize;
      $this.filters.CurrentPage = $this.paginator.pageIndex;
    }

    $this.selection = new SelectionModel<any>(true, []);
    $this.numSelected = 0;
    $this.kioskPeriodService.GetKioskPeriods($this.filters).subscribe((resp) => {
      if (resp != null) {
        for (let index = 0; index < resp.kioskPeriods.length; index++) {
          const transaction: KioskPeriodModel = resp.kioskPeriods[index];
          transaction.snapTime = moment(transaction.snapTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY  HH:mm:ss');
        }
        $this.dataSource = new MatTableDataSource(resp.kioskPeriods);
        $this.dataSource.paginator = $this.paginator;
        $this.dataSource.sort = $this.sort;
        $this.loadingService.toggle("");
      }
    });
  }

  getPaymentMethodLabel(type: PaymentMethod): string {
    return PaymentMethodLabel.get(type) || '-';
  }

  getPeriodTypeLabel(type: PeriodType): string {
    return PeriodTypeLabel.get(type) || '-';
  }

  showReceipt(receipt: string): void {
    this.dialog.open(ReceiptDialogComponent, {
      data: { receipt }
    });
  }

  exportToCSV() {
    const $this = this;
    const csvData = $this.convertToCSV($this.dataSource.filteredData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none');
    a.href = url;
    a.download = 'cash-operations-' + moment().format('YYYYMMDD-HHmmss') + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private convertToCSV(dataToConvert: KioskPeriodModel[]): string {
    const $this = this;
    const headers = ['CÓDIGO PERIODO', 'DATA', 'QUIOSQUE', 'TIPO DE OPERAÇÃO', 'MÉTODO DE PAGAMENTO', 'TOTAL RETIRADO DO COFRE', 'TOTAL RETIRADO DO RECICLADORES', 'TOTAL CARREGADO NOS RECICLADORES', 'TOTAL MOVIDO PARA O COFRE', 'TOTAL RECEBIDO', 'TOTAL DEVOLVIDO'];
    const rows = [];
    for (let i = 0; i < dataToConvert.length; i++) {
      const data = dataToConvert[i];
      const row = [];
      row.push(data.code);
      row.push(data.snapTime);
      row.push(data.kiosk.name);
      row.push($this.getPeriodTypeLabel(data.periodType));
      row.push($this.getPaymentMethodLabel(data.paymentMethod));
      if (data.totalEmptiedCashbox > 0) {
        row.push(data.totalEmptiedCashbox.toFixed(2));
      } else {
        row.push("-");
      }
      if (data.totalEmptiedTubes > 0) {
        row.push(data.totalEmptiedTubes.toFixed(2));
      } else {
        row.push("-");
      }
      if (data.totalLoadedTubes > 0) {
        row.push(data.totalLoadedTubes.toFixed(2));
      } else {
        row.push("-");
      }
      if (data.totalMovedToCashbox > 0) {
        row.push(data.totalMovedToCashbox.toFixed(2));
      } else {
        row.push("-");
      }
      if (data.totalReceived > 0) {
        row.push(data.totalReceived.toFixed(2));
      } else {
        row.push("-");
      }
      if (data.totalRefunded > 0) {
        row.push(data.totalRefunded.toFixed(2));
      } else {
        row.push("-");
      }
      rows.push(row.join(','));
    }
    return `${headers}\n${rows.join('\n')}`;
  }
}
