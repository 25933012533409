import { AfterViewInit, Component, SecurityContext } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company/company.service';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { SefConnectionService } from 'src/app/services/sefConnection/sef-connection.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { CountriesDictionaryModel } from 'src/app/shared/models/CountriesDictionaryModel.model';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { LicenseLimitsModel } from 'src/app/shared/models/LicenseLimitsModel.model';
import { SEFDocumentModel } from 'src/app/shared/models/SEFDocumentModel.model';
import { SEFModel } from 'src/app/shared/models/SEFModel.model';
import { StateModel } from 'src/app/shared/models/StateModel.model';
import * as _ from 'underscore';

@Component({
  selector: 'app-sef-document-form',
  templateUrl: './sef-document-form.component.html',
  styleUrls: ['./sef-document-form.component.scss']
})
export class SefDocumentFormComponent implements AfterViewInit {
  currentSEFDocument: SEFDocumentModel = {
    id: 0,
    document: null,
    errorMessage: null,
    numberOfAttempts: null,
    attachment: null,
    sEFDocumentTypeId: 0,
    bookingNumber: null,
    channelBookingNumber: null,
    stateId: 0,
    state: new StateModel()
  };
  states: DictionaryModel[];
  countries: CountriesDictionaryModel[];
  documentTypes: DictionaryModel[];
  locals: DictionaryModel[];
  filteredcountryOfResidence: Observable<CountriesDictionaryModel[]>;
  filteredissuingCountry: Observable<CountriesDictionaryModel[]>;
  filterednationality: Observable<CountriesDictionaryModel[]>;
  countryOfResidence = new FormControl<string | CountriesDictionaryModel>('');
  issuingCountry = new FormControl<string | CountriesDictionaryModel>('');
  nationality = new FormControl<string | CountriesDictionaryModel>('');
  arrayOfRequests: any[] = [];
  enableSave: boolean = true;
  selectedFile: File;
  selectedImagePreview: string;
  hasFile: boolean = false;
  documentInput: string;

  constructor(private sefConnectionService: SefConnectionService, private loadingService: LoadingService, private editDataServiceService: EditDataServiceService,
    private dictionariesService: DictionariesService, private companyService: CompanyService, private router: Router, private imageCompress: NgxImageCompressService, private sanitizer: DomSanitizer
    , private utilitiesService: UtilitiesService) {
  }

  sefDocument: SEFModel = {
    guestName: null,
    guestFirstName: null,
    guestLastName: null,
    nationality: null,
    dateOfBirth: null,
    birthplace: null,
    documentIdentification: null,
    documentType: null,
    issuingCountry: null,
    checkin: null,
    checkout: null,
    countryOfResidence: null,
    residenceCity: null,
    mobileNumber: null,
    email: null,
    documentImage: null,
    yKioskBookingId: 0,
    yKioskSEFDocumentId: 0,
    yKioskStateId: 0,
    isAdult: 0,
    localId: 0,
    isProductionMode: 0,
    colibriGuestId: 0,
    guestGender: null,
    idExpireDate: null
  };
  hasSEF: boolean;

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.sefDocument);
    const notRequiredFields = ['guestGender', 'iDExpireDate', 'isProductionMode', 'isAdult', 'colibriGuestId', 'yKioskBookingId', 'yKioskSEFDocumentId', 'yKioskStateId'];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.sefDocument[key])) {
          $this.enableSave = false;
          return false;
        } else {
          if ($this.sefDocument[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  displayFn(country: string): string {
    const $this = this;
    let currentCountry = "";

    if (country != null && country != "") {
      currentCountry = _.findWhere($this.countries, { icaoCode: country })?.name;
    }

    return currentCountry;
  }

  private _filter(country: string): CountriesDictionaryModel[] {
    const filterValue = country.toLowerCase();

    return this.countries.filter(optionCountry => optionCountry.name.toLowerCase().includes(filterValue));
  }

  onFileChanged(event) {
    const $this = this;
    $this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($this.selectedFile);
    reader.onload = () => {
      $this.selectedImagePreview = reader.result.toString();
      if ($this.selectedImagePreview.length > 0 && $this.selectedImagePreview.split(',').length > 0 && $this.selectedImagePreview.length <= 10000000) {

        $this.imageCompress.compressFile($this.selectedImagePreview, -2, 100, 50).then(result => {
          $this.sefDocument.documentImage = result.split(',')[1];
          $this.hasFile = true;
          $this.checkFields();
        });
      } else {
        $this.clear();
      }
    };
  }

  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.companyService.GetCompanyLimits());
    $this.arrayOfRequests.push($this.dictionariesService.GetICAOCountriesDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetSEFDocumentTypesSEFCodeDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetLocalsDictionary());

    if ($this.editDataServiceService.sefDocumentIdToEdit != 0) {
      $this.arrayOfRequests.push($this.sefConnectionService.GetSEFDocumentById($this.editDataServiceService.sefDocumentIdToEdit));
      $this.editDataServiceService.sefDocumentIdToEdit = 0;
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              let licenseLimits = resp as LicenseLimitsModel;
              $this.hasSEF = licenseLimits.hasSEFIntegration;
              break;
            case 1:
              $this.countries = resp as CountriesDictionaryModel[];

              $this.filteredcountryOfResidence = $this.countryOfResidence.valueChanges.pipe(
                startWith(''),
                map(value => {
                  const name = typeof value === 'string' ? value : value?.name;
                  return name ? $this._filter(name as string) : $this.countries.slice();
                }),
              );

              $this.filteredissuingCountry = $this.issuingCountry.valueChanges.pipe(
                startWith(''),
                map(value => {
                  const name = typeof value === 'string' ? value : value?.name;
                  return name ? $this._filter(name as string) : $this.countries.slice();
                }),
              );

              $this.filterednationality = $this.nationality.valueChanges.pipe(
                startWith(''),
                map(value => {
                  const name = typeof value === 'string' ? value : value?.name;
                  return name ? $this._filter(name as string) : $this.countries.slice();
                }),
              );
              break;
            case 2:
              $this.documentTypes = resp as DictionaryModel[];
              break;
            case 3:
              $this.locals = resp as DictionaryModel[];
              break;
            case 4:
              let sEFDocumentModel = resp as SEFDocumentModel;
              let sefDocumentResponse = new SEFModel();
              if (sEFDocumentModel.document != "" && sEFDocumentModel.document != null) {
                sefDocumentResponse = $this.toLowerKeys(JSON.parse(sEFDocumentModel.document)) as SEFModel;
              }

              $this.sefDocument = sefDocumentResponse;
              $this.currentSEFDocument = sEFDocumentModel;

              if (sEFDocumentModel.attachment != null && sEFDocumentModel.attachment != "") {
                $this.selectedImagePreview = $this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, $this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + sEFDocumentModel.attachment));
                $this.sefDocument.documentImage = $this.selectedImagePreview.split(',')[1];
                $this.hasFile = true;
              }

              $this.checkFields();
              break;
          }
        }
        $this.loadingService.toggle("");
      });

  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  clear() {
    const $this = this;
    $this.selectedImagePreview = "../../assets/imgs/scan_erro.png";
    $this.documentInput = "";
    $this.sefDocument.documentImage = "";
    $this.hasFile = false;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/sef-documents"]);
  }

  saveData() {
    const $this = this;
    let arrayToSave: SEFModel[] = [];

    arrayToSave.push($this.sefDocument);
    $this.loadingService.toggle("");
    $this.sefConnectionService.SaveInDataBase(arrayToSave).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/sef-documents"]);
    });
  }

  saveAndEmitToSEFData() {
    const $this = this;
    let arrayToSave: SEFModel[] = [];

    arrayToSave.push($this.sefDocument);
    $this.loadingService.toggle("");
    $this.sefConnectionService.SendDataToSEF(arrayToSave).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/sef-documents"]);
    });
  }
}

