<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px;">
        <div class="row row-form-container">
          <form>
            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Data de Criação</mat-label>
                  <input matInput [(ngModel)]="booking.CreateDate" readonly name="CreateDate">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Data de Edição</mat-label>
                  <input matInput [(ngModel)]="booking.EditDate" readonly name="EditDate">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nº Reserva</mat-label>
                  <input matInput [(ngModel)]="booking.BookingNumber" readonly name="BookingNumber">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nº Reserva do Canal</mat-label>
                  <input matInput [(ngModel)]="booking.ChannelBookingNumber" readonly name="ChannelBookingNumber">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome do Hóspede Principal</mat-label>
                  <input matInput [(ngModel)]="booking.Name" readonly name="Name">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nº de Noites</mat-label>
                  <input matInput [(ngModel)]="booking.NumberOfNights" readonly name="NumberOfNights">
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Quiosque</mat-label>
                  <mat-select [(ngModel)]="booking.KioskId" [disabled]="true" name="KioskId">
                    <mat-option *ngFor="let kiosk of kiosks" [value]="kiosk.id">
                      {{ kiosk.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Tags</mat-label>
                  <input matInput [(ngModel)]="booking.Tags" readonly name="Tags">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="booking.DataToSend != null" class="row">
              <div class="col-xl-12 col-md-12 col-sm-12">
                <mat-toolbar>
                  <span>Informação do PMS</span>
                </mat-toolbar>
              </div>
            </div>
            <div *ngIf="booking.DataToSend != null" style="margin-top: 20px;" class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Data de Check-in</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.checkin" readonly name="checkin">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Data de Check-out</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.checkout" readonly name="checkout">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nº de Hóspedes</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.numberOfGuests" readonly name="numberOfGuests">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="booking.DataToSend != null" class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Email</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.email" readonly name="email">
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Contacto Telefónico</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.phoneNumber" readonly name="phoneNumber">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="booking.DataToSend != null" class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Máximo de Adultos</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.maxAdult" readonly name="maxAdult">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Máximo de Crianças</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.maxChildren" readonly name="maxChildren">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Preço Total da Reserva</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.totalBookingPrice" readonly name="totalBookingPrice">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Unidade Monetária</mat-label>
                  <input matInput [(ngModel)]="booking.DataToSend.currencyCodeTotalBooking" readonly name="currencyCodeTotalBooking">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="booking.DataToSend != null" class="row" style="margin-bottom: 40px;">
                <div class="col-xl-3 col-md-3 col-sm-12">
                  <mat-form-field class="input-full-width">
                    <mat-label>Montante que falta pagar</mat-label>
                    <input matInput [(ngModel)]="booking.DataToSend.totalToPayDue" readonly name="totalToPayDue">
                  </mat-form-field>
                </div>
                <div class="col-xl-3 col-md-3 col-sm-12">
                  <mat-form-field class="input-full-width">
                    <mat-label>Unidade Monetária</mat-label>
                    <input matInput [(ngModel)]="booking.DataToSend.currencyCodeDue" readonly name="currencyCodeDue">
                  </mat-form-field>
                </div>
              </div>
          </form>
        </div>

        <div *ngIf="booking?.TermId > 0" class="row mat-elevation-z8 row-term-container">
          <mat-toolbar>
            <span>Termo & Condição Aceite</span>
          </mat-toolbar>

          <div class="row row-term-content">
            <div class="col-xl-12 col-md-12 col-sm-12">
              <div class="term-container" [innerHTML]="acceptedTerm">

              </div>
            </div>
          </div>
          <div *ngIf="clientSign != null" class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 sign-container">
              <img [src]="clientSign">
            </div>
          </div>
        </div>

        <div *ngIf="transactions?.length > 0" class="row">
          <mat-toolbar>
            <span>Transações</span>
          </mat-toolbar>

          <table mat-table [dataSource]="transactions" class="mat-elevation-z8">

            <!-- Description -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> Descrição </th>
              <td mat-cell *matCellDef="let row"> {{row.description}}</td>
            </ng-container>

            <!-- Amount Paid Column -->
            <ng-container matColumnDef="amountPaid">
              <th mat-header-cell *matHeaderCellDef> Valor pago </th>
              <td mat-cell *matCellDef="let row"> {{row.amountPaid}} €</td>
            </ng-container>

            <!-- State Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Estado </th>
              <td mat-cell *matCellDef="let row"> {{row.status}} </td>
            </ng-container>

            <!-- Message Error Column -->
            <ng-container matColumnDef="messageError">
              <th mat-header-cell *matHeaderCellDef> Mensagem de Erro </th>
              <td mat-cell *matCellDef="let row"> {{row.messageError}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTransactions"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTransactions;"></tr>
          </table>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">

          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
