import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProperty'
})
export class FilterByPropertyPipe implements PipeTransform {

  transform(items: any[], property: string, value: any, propertyToReturn: string): unknown {
    if (!items) {
      return [];
    }
    return items.find(item => item[property] === value)[propertyToReturn];
  }

}
