<h1 mat-dialog-title>
  <mat-icon>preview</mat-icon>
  <span [@fade]="fadeState"
    >Pré-visualizar (<small>{{ data.photos[data.currentIndex].fileName }}</small
    >)</span
  >

  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</h1>
<div mat-dialog-content>
  <img
    [@fade]="fadeState"
    [src]="data.photos[data.currentIndex].fileToDisplay"
    [alt]="data.photos[data.currentIndex].fileName"
    class="photo-preview"
  />
</div>
<div mat-dialog-actions>
  <button
    color="accent"
    mat-raised-button
    (click)="prevPhoto()"
    [disabled]="data.currentIndex === 0"
  >
    <mat-icon>skip_previous</mat-icon>
    Anterior
  </button>
  <button
    class="next-button"
    mat-raised-button
    color="accent"
    (click)="nextPhoto()"
    [disabled]="data.currentIndex === data.photos.length - 1"
  >
    Seguinte
    <mat-icon>skip_next</mat-icon>
  </button>
</div>
