import { Component, OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { BookingColibriModel } from '../shared/models/BookingColibriModel.model';
import { ColibriConnectionService } from '../services/colibriConnection/colibri-connection.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { StoreDataService } from '../services/storeData/store-data.service';
import { LoadingService } from '../services/loading/loading.service';
import { UtilitiesService } from '../services/utilities-service/utilities.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  @ViewChild('contentError', { static: false }) private contentError;
  listOfMonths: Array<any> = moment.months();
  listOfYears: Array<any> = [];
  currentBookingData: BookingColibriModel;
  totalToPayDue: string;
  cardName: string;
  cardNumber: string;
  cvv: string;
  cardMonth: string;
  cardYear: string;
  enablePayButton: boolean = false;
  closeResult = '';
  adultCounter: number = 0;
  kidCounter: number = 0;

  constructor(private colibriConnectionService: ColibriConnectionService, private router: Router,
    private modalService: NgbModal, private storeDataService: StoreDataService, private loadingService: LoadingService, private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
    const $this = this;
    $this.loadingService.toggle("");
    let currentBooking = $this.storeDataService.cb;

    if (!$this.utilitiesService.isNullOrEmpty(currentBooking)) {
      $this.currentBookingData = JSON.parse(currentBooking);

      let guestCounts = $this.currentBookingData.guestCounts;

      for (let index = 0; index < guestCounts.length; index++) {
        const element = guestCounts[index];

        if (element.isAdult) {
          $this.adultCounter += element.count;
        }
        else {
          $this.kidCounter += element.count;
        }
      }
      
      $this.colibriConnectionService.TotalToPay($this.currentBookingData.bookingNumber, $this.adultCounter.toString(), $this.kidCounter.toString()).subscribe(total => {
        
        if(total.totalToPayDue != null){
          $this.currentBookingData.totalToPayDue = total.totalToPayDue;
        }

        $this.totalToPayDue = $this.currentBookingData.totalToPayDue;

        $this.loadingService.toggle("");
      });
    
    }

    const dateStart = moment();
    const dateEnd = moment().add(20, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      $this.listOfYears.push(dateStart.format('YYYY'));
      dateStart.add(1, 'year');
    }
  }

  changeValue(event: any) {
    const $this = this;
    $this.enablePayButton = $this.cardName?.length > 0 && $this.cardNumber?.toString().length == 16 && $this.cvv?.toString().length == 3 && $this.cardMonth?.length > 0 && $this.cardYear?.length > 0;
  }

  pay() {

    const $this = this;
    let expireDate = $this.cardMonth.length == 1 ? "0" + $this.cardMonth : $this.cardMonth;
    expireDate = expireDate + $this.cardYear.slice(-2);
    
    $this.loadingService.toggle("");

    $this.colibriConnectionService.PayBookingWithCreditCard($this.currentBookingData.bookingNumber, $this.currentBookingData.totalToPayDue, $this.currentBookingData.yKioskBookingId.toString(),
      $this.cvv, $this.cardNumber, expireDate, $this.cardName).subscribe(resp => {
        
        $this.loadingService.toggle("");
        
        if (resp) {
          $this.router.navigate(['finish']);
        } else {
          $this.open();
        }

      });
  }

  open() {
    const $this = this;
    $this.modalService.open($this.contentError);
  }

  prevPage() {
    const $this = this;
    /*localStorage.removeItem('cb');
    localStorage.removeItem('guests');
    localStorage.removeItem('currentGuest');*/

    $this.storeDataService.cb = '';
    $this.storeDataService.guests = '';
    $this.storeDataService.currentGuest = '';
    
    $this.router.navigate(['/guest-number-edit']);
  }

}
