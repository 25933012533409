import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExitCheckinService } from './services/exitCheckin/exit-checkin.service';
import { LoadingService } from './services/loading/loading.service';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { StoreDataService } from './services/storeData/store-data.service';
import { TermService } from './services/term/term.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'underscore';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Menu } from './shared/models/menu/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { UserAuthenticated } from './shared/models/user-authenticated/user-authenticated';
import { MediaMatcher } from '@angular/cdk/layout';
import { CompanyService } from './services/company/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  @ViewChild('content', { static: false }) private content;
  @ViewChild('contentTerms', { static: false }) private contentTerms;
  loadingData: any;
  public isCustomLayout: number;
  termContent: SafeHtml;
  private currentUser: UserAuthenticated;
  currentTerm: any;
  pageTitle: string;
  pageIcon: string;
  qmMenuDashboard: Menu[] = [];
  qmMenuMoney: Menu[] = [];
  qmMenuUsers: Menu[] = [];
  qmMenuOrganizations: Menu[] = [];
  qmMenuSettings: Menu[] = [];
  qmMenuWalkin: Menu[] = [];
  sidenavOpen: boolean;
  @ViewChild('sidenav') private sidenav: MatSidenav;
  mobileQuery: MediaQueryList;
  qmMenuDashboardExpanded: boolean = false;
  qmMenuMoneyExpanded: boolean = false;
  qmMenuUsersExpanded: boolean = false;
  qmMenuOrganizationsExpanded: boolean = false;
  qmMenuSettingsExpanded: boolean = false;
  qmMenuWalkinExpanded: boolean = false;
  hasWalkin: boolean = false;

  constructor(private translate: TranslateService, config: NgbModalConfig, private modalService: NgbModal,
    private exitCheckinService: ExitCheckinService, private router: Router, private loadingService: LoadingService,
    private titleService: Title, private storeDataService: StoreDataService, private route: ActivatedRoute, private termService: TermService,
    private domSanitizer: DomSanitizer, private authenticationService: AuthenticationService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private companyService: CompanyService) {
    translate.setDefaultLang(JSON.stringify({ id: 2, iso: "en-GB" }));
    translate.currentLang = JSON.stringify({ id: 2, iso: "en-GB" });
    const $this = this;

    $this.storeDataService.cb = '';
    $this.storeDataService.guests = '';
    $this.storeDataService.currentGuest = '';

    $this.authenticationService.currentUser.subscribe(user => {
      $this.currentUser = user;

      if ($this.currentUser != null) {

        $this.qmMenuDashboard = [
          {
            Name: "Reservas",
            Url: "backoffice/bookings",
            Icon: "calendar_today",
            IsDefaultIcon: true,
            IsActive: true
          }, {
            Name: "Lista de Hóspedes",
            Url: "backoffice/sef-documents",
            Icon: "groups",
            IsDefaultIcon: true,
            IsActive: true
          }, {
            Name: "Quiosques",
            Url: "backoffice/kiosks",
            Icon: "desktop_windows",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          }
        ];

        $this.qmMenuDashboard = $this.qmMenuDashboard.filter(x => x.IsActive);

        $this.qmMenuWalkin = [
          {
            Name: "Tipos de Quarto",
            Url: "backoffice/room-types",
            Icon: "bed",
            IsDefaultIcon: true,
            IsActive: true
          },
          {
            Name: "Tipos de Pensão",
            Url: "backoffice/board-types",
            Icon: "brunch_dining",
            IsDefaultIcon: true,
            IsActive: true
          }
        ]

        $this.qmMenuWalkin = $this.qmMenuWalkin.filter(x => x.IsActive);

        $this.qmMenuMoney = [{
          Name: "Manutenções",
          Url: "backoffice/cash-holdings",
          Icon: "attach_money",
          IsDefaultIcon: true,
          IsActive: $this.currentUser.IsAdministrator
        }, {
          Name: "Operações de Caixa",
          Url: "backoffice/cash-operations",
          Icon: "savings",
          IsDefaultIcon: true,
          IsActive: $this.currentUser.IsAdministrator
        }, {
          Name: "Operações",
          Url: "backoffice/transactions",
          Icon: "payments",
          IsDefaultIcon: true,
          IsActive: $this.currentUser.IsAdministrator
        }, {
          Name: "Niveis",
          Url: "backoffice/levels",
          Icon: "stacked_bar_chart",
          IsDefaultIcon: true,
          IsActive: $this.currentUser.IsAdministrator
        }];

        $this.qmMenuMoney = $this.qmMenuMoney.filter(x => x.IsActive);

        $this.qmMenuUsers = [
          {
            Name: "Utilizadores",
            Url: "backoffice/users",
            Icon: "account_circle",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          }
        ];

        $this.qmMenuUsers = $this.qmMenuUsers.filter(x => x.IsActive);

        $this.qmMenuOrganizations = [
          {
            Name: "Empresas",
            Url: "backoffice/companies",
            Icon: "apartment",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsSuperUser
          },
          {
            Name: "Subempresas",
            Url: "backoffice/subcompanies",
            Icon: "corporate_fare",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsSuperUser
          },
          {
            Name: "Locais",
            Url: "backoffice/locals",
            Icon: "location_on",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          }
        ];

        $this.qmMenuOrganizations = $this.qmMenuOrganizations.filter(x => x.IsActive);

        $this.qmMenuSettings = [
          {
            Name: "Integrações",
            Url: "backoffice/integrations",
            Icon: "integration_instructions",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          },
          {
            Name: "Termos & Condições",
            Url: "backoffice/terms",
            Icon: "grading",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          },
          {
            Name: "Emails a Enviar",
            Url: "backoffice/emails",
            Icon: "email",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          },
          {
            Name: "Idiomas",
            Url: "backoffice/languages",
            Icon: "language",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          },
          {
            Name: "Traduções",
            Url: "backoffice/translations",
            Icon: "translate",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          },
          {
            Name: "Mensagens Aplicacionais",
            Url: "backoffice/app-msgs",
            Icon: "textsms",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          },
          {
            Name: "Configurações",
            Url: "backoffice/config",
            Icon: "settings",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsAdministrator
          },
          {
            Name: "Importação de Dados",
            Url: "backoffice/import-data",
            Icon: "import_contacts",
            IsDefaultIcon: true,
            IsActive: $this.currentUser.IsSuperUser
          }
        ];

        $this.qmMenuSettings = $this.qmMenuSettings.filter(x => x.IsActive);

      } else {
        $this.qmMenuDashboard = [];
        $this.qmMenuSettings = [];
        $this.qmMenuUsers = [];
      }
    });

    config.backdrop = 'static';
    config.centered = true;
    $this.mobileQuery = media.matchMedia('(max-width: 600px)');
    $this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    $this.mobileQuery.addListener(this._mobileQueryListener);
  }

  private _mobileQueryListener: () => void;

  ngOnInit() {

    const $this = this;

    if ($this.authenticationService.currentUserValue != undefined && $this.authenticationService.currentUserValue.Token != undefined) {
      $this.companyService.GetCompanyLimits().subscribe(resp => {
        $this.hasWalkin = resp.hasWalkinFunctionality;

        $this.qmMenuWalkin = $this.qmMenuWalkin.filter(x => x.IsActive && $this.hasWalkin);
      });
    }

    $this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      var myDiv = document.getElementById('yk-page-container');
      myDiv?.scrollTo(0, 0);
    });

    if (!window.location.pathname.includes('direct-search') && !window.location.pathname.includes('backoffice')) {
      $this.router.navigate(['']);
    }

    $this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let title = 'PARTTEAM | YKioskHOTEL - ' + $this.getTitle($this.router.routerState, $this.router.routerState.root).join('-').replace(',', '');
        let customTitle = $this.getTitle($this.router.routerState, $this.router.routerState.root).join('-').replace(',', '');
        $this.titleService.setTitle(title);
        $this.pageTitle = customTitle;
        $this.pageIcon = $this.getIcon($this.router.routerState, $this.router.routerState.root).join('-').replace(',', '');
        $this.updatePanelStates();
      }
    });

    $this.exitCheckinService.change.subscribe(() => {
      $this.open();
    });

    $this.exitCheckinService.changeTerms.subscribe(() => {
      $this.openTerms();
    });

    $this.loadingService.change.subscribe((loading: any) => {
      setTimeout(() => {
        $this.loadingData = loading;

        let myDiv = document.getElementById('yk-page-container');
        myDiv?.scrollTo(0, 0);
        document.body.style.overflow = loading.IsLoading ? "hidden" : "auto";

        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 0);
    });

  }

  private updatePanelStates() {
    const $this = this;
    const url = $this.router.url;
    $this.qmMenuDashboardExpanded = $this.checkPanelDashboardUrls(url);
    $this.qmMenuMoneyExpanded = $this.checkPanelMoneyUrls(url);
    $this.qmMenuUsersExpanded = $this.checkPanelUserUrls(url);
    $this.qmMenuOrganizationsExpanded = $this.checkPanelOrganizationUrls(url);
    $this.qmMenuSettingsExpanded = $this.checkPanelSettingUrls(url);
    $this.qmMenuWalkinExpanded = $this.checkPanelWalkinUrls(url);
  }

  private checkPanelDashboardUrls(url: string): boolean {
    return url.includes('/bookings') || url.includes('/sef-documents') || url.includes('/kiosks');
  }

  private checkPanelMoneyUrls(url: string): boolean {
    return url.includes('/cash-holdings') || url.includes('/cash-operations') || url.includes('/transactions') || url.includes('/levels');
  }

  private checkPanelUserUrls(url: string): boolean {
    return url.includes('/users');
  }

  private checkPanelOrganizationUrls(url: string): boolean {
    return url.includes('/locals') || url.includes('/subcompanies') || url.includes('/companies');
  }

  private checkPanelSettingUrls(url: string): boolean {
    return url.includes('/integrations') || url.includes('/terms') || url.includes('/emails') || url.includes('/languages') || url.includes('/translations') || url.includes('/app-msgs') || url.includes('/config');
  }

  private checkPanelWalkinUrls(url: string): boolean {
    return url.includes('/room-types') || url.includes('/board-types');
  }

  getTitle(state, parent) {

    let $this = this;
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.pageTitle) {
      data.push(parent.snapshot.data.pageTitle);
      $this.isCustomLayout = parent.snapshot.data.customLayout;
    }

    if ($this.isCustomLayout == 3 || $this.isCustomLayout == 2) {
      const $body = document.body;
      $body.classList.add('backoffice-body');
    }

    if (state && parent) {
      data.push($this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  getIcon(state, parent) {

    let $this = this;
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.pageIcon) {
      data.push(parent.snapshot.data.pageIcon);
    }

    if (state && parent) {
      data.push($this.getIcon(state, state.firstChild(parent)));
    }
    return data;
  }

  endSession() {
    const $this = this;
    $this.authenticationService.logout();
    $this.router.navigate(['/backoffice']);
  }


  openTerms() {
    const $this = this;
    $this.loadingService.toggle("");
    if ($this.exitCheckinService.renderTerm.observers.length == 0) {

      $this.exitCheckinService.renderTerm.subscribe((safeHtml: string) => {
        $this.termContent = $this.domSanitizer.bypassSecurityTrustHtml(safeHtml);
        $this.loadingService.toggle("");
        $this.modalService.open($this.contentTerms);
      });

    }

    $this.termService.GetAllActiveTerms().subscribe(resp => {

      let currentLanguage = JSON.parse($this.translate.currentLang);

      if (currentLanguage != null) {
        $this.currentTerm = _.findWhere(resp, { languageId: currentLanguage.id });
      } else {
        $this.currentTerm = resp[0];
      }

      $this.termContent = $this.domSanitizer.bypassSecurityTrustHtml($this.currentTerm.text);
      $this.loadingService.toggle("");
      $this.modalService.open($this.contentTerms);

    });
  }


  open() {
    const $this = this;
    $this.modalService.open($this.content);
  }

  exitBooking() {
    const $this = this;

    $this.storeDataService.cb = '';
    $this.storeDataService.guests = '';
    $this.storeDataService.currentGuest = '';

    $this.modalService.dismissAll();
    $this.router.navigate(['/']);
  }

  sidenavToggle($eventTarget) {
    let $this = this;

    if (!$this.mobileQuery.matches && $eventTarget.currentTarget.classList.contains('mat-toolbar')) {
      return;
    }

    $this.sidenav.toggle();

    $this.sidenavOpen = $this.sidenav.opened;
  }
}
