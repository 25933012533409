<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px;">
        <div class="row row-form-container">
          <form>
            <div class="row">
              <div class="col-xl-9 col-md-9 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome</mat-label>
                  <input matInput [(ngModel)]="company.name" (keyup)="checkFields()" name="name">
                </mat-form-field>
              </div>

              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>NIF</mat-label>
                  <input matInput [(ngModel)]="company.nif" (keyup)="checkFields()" name="nif">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-md-4 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Email</mat-label>
                  <input matInput [(ngModel)]="company.email" (keyup)="checkFields()" name="email">
                </mat-form-field>
              </div>

              <div class="col-xl-4 col-md-4 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Contacto</mat-label>
                  <input matInput [(ngModel)]="company.contact" (keyup)="checkFields()" name="contact">
                </mat-form-field>
              </div>

              <div class="col-xl-4 col-md-4 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Data de Expiração da Licença</mat-label>
                  <input matInput [matDatepicker]="licenseExpirationDatepicker"
                    [(ngModel)]="company.licenseExpirationDate" (change)="checkFields()" name="licenseExpirationDate">
                  <mat-datepicker-toggle matSuffix [for]="licenseExpirationDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #licenseExpirationDatepicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div class="input-full-width mat-form-field-flex slide-container">
                  <mat-slide-toggle [(ngModel)]="company.hasSEFIntegration" (change)="checkFields()"
                    name="hasSEFIntegration">
                    Integração com o SEF?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div class="input-full-width mat-form-field-flex slide-container">
                  <mat-slide-toggle [(ngModel)]="company.hasWalkinFunctionality" (change)="checkFields()"
                    name="hasWalkinFunctionality">
                    Walk-in?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-2 col-md-2 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Limite de Backoffices</mat-label>
                  <input matInput [(ngModel)]="company.backofficeLimits" (keyup)="checkFields()" name="backofficeLimits">
                </mat-form-field>
              </div>
              <div class="col-xl-2 col-md-2 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Limite de Quiosques</mat-label>
                  <input matInput [(ngModel)]="company.kioskLimits" (keyup)="checkFields()" name="kioskLimits">
                </mat-form-field>
              </div>
              <div class="col-xl-2 col-md-2 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Limite de Locais</mat-label>
                  <input matInput [(ngModel)]="company.localsLimits" (keyup)="checkFields()" name="localsLimits">
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button
              color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
