<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="row">
      <div class="col-xl-8 col-md-6 col-sm-12">
        <mat-form-field>
          <mat-label>Filtro</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            [(ngModel)]="filters.SearchText"
            #input
          />
        </mat-form-field>
      </div>
      <div class="col-xl-3 col-md-4 col-sm-12">
        <mat-form-field>
          <mat-label>Intervalo de Datas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [formControl]="startDate"
              placeholder="Data Início"
            />
            <input matEndDate [formControl]="endDate" placeholder="Data Fim" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-xl-1 col-md-2 col-sm-12">
        <div class="search-buttons-col">
          <button mat-flat-button color="primary" (click)="exportToCSV()">
            <mat-icon>download</mat-icon>
          </button>
          <button mat-flat-button color="primary" (click)="search()">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="mat-elevation-z8 search-container">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Progress Column -->
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              CÓD. PERIODO
            </th>
            <td mat-cell *matCellDef="let row">{{ row.code }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="snapTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DATA</th>
            <td mat-cell *matCellDef="let row">{{ row.snapTime }}</td>
          </ng-container>

          <!-- Kiosk Column -->
          <ng-container matColumnDef="kiosk">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>QUIOSQUE</th>
            <td mat-cell *matCellDef="let row">
              {{ row.kiosk.name }}
            </td>
          </ng-container>

          <!-- Operation Type Column -->
          <ng-container matColumnDef="operationType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TIPO DE OPERAÇÃO
            </th>
            <td mat-cell *matCellDef="let row">
              {{ getPeriodTypeLabel(row.periodType) }}
            </td>
          </ng-container>

          <!-- Payment Method Column -->
          <ng-container matColumnDef="paymentMethod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              MÉTODO DE PAGAMENTO
            </th>
            <td mat-cell *matCellDef="let row">
              {{ getPaymentMethodLabel(row.paymentMethod) }}
            </td>
          </ng-container>

          <!-- Total Emptied Cashbox Column -->
          <ng-container matColumnDef="totalEmptiedCashbox">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TOTAL RETIRADO DO COFRE
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.totalEmptiedCashbox > 0">
                {{ row.totalEmptiedCashbox | number : "1.2-2" }} €</span
              >
              <span *ngIf="row.totalEmptiedCashbox <= 0">-</span>
            </td>
          </ng-container>

          <!-- Total Emptied Tubes Column -->
          <ng-container matColumnDef="totalEmptiedTubes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TOTAL RETIRADO DO RECICLADORES
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.totalEmptiedTubes > 0">
                {{ row.totalEmptiedTubes | number : "1.2-2" }} €</span
              >
              <span *ngIf="row.totalEmptiedTubes <= 0">-</span>
            </td>
          </ng-container>

          <!-- Total Loaded Tubes Column -->
          <ng-container matColumnDef="totalLoadedTubes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TOTAL CARREGADO NOS RECICLADORES
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.totalLoadedTubes > 0">
                {{ row.totalLoadedTubes | number : "1.2-2" }} €</span
              >
              <span *ngIf="row.totalLoadedTubes <= 0">-</span>
            </td>
          </ng-container>

          <!-- Total Moved to Cashbox Column -->
          <ng-container matColumnDef="totalMovedToCashbox">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TOTAL MOVIDO PARA O COFRE
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.totalMovedToCashbox > 0">
                {{ row.totalMovedToCashbox | number : "1.2-2" }} €</span
              >
              <span *ngIf="row.totalMovedToCashbox <= 0">-</span>
            </td>
          </ng-container>

          <!-- Total Received Column -->
          <ng-container matColumnDef="totalReceived">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TOTAL RECEBIDO
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.totalReceived > 0">
                {{ row.totalReceived | number : "1.2-2" }} €</span
              >
              <span *ngIf="row.totalReceived <= 0">-</span>
            </td>
          </ng-container>

          <!-- Total REFUNDED Column -->
          <ng-container matColumnDef="totalRefunded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TOTAL DEVOLVIDO
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.totalRefunded > 0">
                {{ row.totalRefunded | number : "1.2-2" }} €</span
              >
              <span *ngIf="row.totalRefunded <= 0">-</span>
            </td>
          </ng-container>

          <!-- Total REFUNDED Column -->
          <ng-container matColumnDef="atmReceipt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>TALÃO</th>
            <td mat-cell *matCellDef="let row">
              <button (click)="showReceipt(row.receipt)" *ngIf="row.receipt != null && row.receipt.length" mat-icon-button aria-label="Talão">
                <mat-icon>preview</mat-icon>
              </button>
              <span *ngIf="row.receipt == null || !row.receipt.length">-</span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selection.toggle(row)"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="11">
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
  </mat-card>
</div>
