<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'YourGuestsTitleFrontOffice' | translate }}</h3>
  </div>

  <div class="row">
    <div class="col">
      <div class="kiosk-container">

        <div class="result-container booking-guests-container">
          <div class="guest-list-container">
            <ul class="list-group">
              <li class="list-group-item" *ngFor="let guest of listOfGuests; let i = index">
                {{ 'GuestLabelFrontOffice' | translate }} {{ i+1 }}: <b>{{ guest.guestName }}</b></li>
            </ul>
          </div>
        </div>

        <div *ngIf="!displayChangeBookingButton" class="add-guests-controller">

          <p>{{ 'AddNewGuestsQuestionFrontOffice' | translate }}</p>
          <ul class="list-group">
            <li class="list-group-item">
              <p class="guests-limits">
                <b>{{ adultCounter }}/{{ maxAdult }}</b>
                {{ 'AdultsLabelFrontOffice' | translate }}
              </p>
              <div class="button-container">
                <button [disabled]="!nyRemoveAdult" (click)="removeAdult()" type="button"
                  class="btn btn-dark">-</button>
                <button [disabled]="!nyAddAdult" (click)="addAdult()" type="button" class="btn btn-dark">+</button>
              </div>
            </li>
            <li class="list-group-item">
              <p class="guests-limits">
                <b>{{ kidCounter }}/{{ maxKid }}</b>
                {{ 'KidsLabelFrontOffice' | translate }}
              </p>
              <div class="button-container">
                <button [disabled]="!nyRemoveKid" type="button" (click)="removeKid()" class="btn btn-dark">-</button>
                <button [disabled]="!nyAddKid" type="button" (click)="addKid()" class="btn btn-dark">+</button>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="!displayChangeBookingButton" class="row">
    <div class="col text-center">
      <button (click)="prevPage()" type="button"
        class="btn btn-lg btn-secondary">{{ 'ButtonBackFrontOffice' | translate }}</button>
    </div>
    <div class="col text-center">
      <button (click)="nextPage()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonNextFrontOffice' | translate }}</button>
    </div>
  </div>

  <div *ngIf="displayChangeBookingButton" class="row">
    <div class="col text-center">
      <button (click)="prevPage()" type="button"
        class="btn btn-lg btn-secondary">{{ 'ButtonBackFrontOffice' | translate }}</button>
    </div>
    <div class="col text-center">
      <button *ngIf="showPayButton && !showNextButton" (click)="pay()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonPayFrontOffice' | translate }}</button>
        <button *ngIf="!showPayButton && !showNextButton" (click)="finishCheckin()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonFinishFrontOffice' | translate }}</button>
        <button *ngIf="showNextButton" (click)="nextPage()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonNextFrontOffice' | translate }}</button>
    </div>
  </div>

  <div class="row row-action-two row-action-two">
    <div class="col text-center">
      <p (click)="exit()" class="link-button btn-lg cancel-button">{{ 'ButtonCancelFrontOffice' | translate }}</p>
    </div>
    <div class="col text-center">
      <p *ngIf="displayChangeBookingButton" (click)="editBooking()" class="link-button btn-lg idontknow-button">
        {{ 'ButtonEditBookingFrontOffice' | translate }}</p>
    </div>
  </div>

</div>
