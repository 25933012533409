import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateService implements TranslateLoader {

  public genderByLanguage = [];

  constructor(private httpClient: HttpClient) {}
    getTranslation(lang: string): Observable<any> {

    const $this = this;

    let langObj = JSON.parse(lang);

    $this.changeGenderLanguage(langObj.iso);

    return $this.httpClient.get<any>(environment.apiUrl + 'api/v1/Translation/TranslationsForWeb/' + langObj.id).pipe();
    }

    changeGenderLanguage(langIsoCode: string){
      const $this = this;
  
      switch (langIsoCode) {
        case "pt-PT":
          $this.loadGenders([{value:"F", description: "Feminino"},{value:"M", description: "Masculino"}]);
          break;
      
        default:
          $this.loadGenders([{value:"F", description: "Female"},{value:"M", description: "Male"}]);
          break;
      }
    }

    @Output() change: EventEmitter<any> = new EventEmitter();

    loadGenders(genders: any) {
      
      const $this = this;

      $this.genderByLanguage = genders;

      $this.change.emit($this.genderByLanguage);

    }
  }