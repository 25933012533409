<app-nav-home *ngIf="isCustomLayout == 0"></app-nav-home>

<app-nav-menu *ngIf="isCustomLayout == 1"></app-nav-menu>

<div
  *ngIf="isCustomLayout == 1 || isCustomLayout == 0"
  id="yk-page-container"
  [className]="
    isCustomLayout == 0
      ? 'container home-page-container'
      : isCustomLayout == 1
      ? 'container page-container'
      : ''
  "
>
  <div
    [className]="
      isCustomLayout == 0 ? '' : isCustomLayout == 1 ? 'vertical-center' : ''
    "
  >
    <router-outlet></router-outlet>
  </div>
</div>

<router-outlet *ngIf="isCustomLayout == 2"></router-outlet>

<app-footer *ngIf="isCustomLayout == 1"></app-footer>
<app-footer-home *ngIf="isCustomLayout == 0"></app-footer-home>

<ng-template
  *ngIf="isCustomLayout == 0 || isCustomLayout == 1"
  #content
  let-c="close"
  let-d="dismiss"
>
  <div class="modal-header">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="currentColor"
      class="bi bi-info"
      viewBox="0 0 16 16"
    >
      <path
        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
      />
      <circle cx="8" cy="4.5" r="1" />
    </svg>
    <h6 class="modal-title" id="modal-basic-title">
      {{ "AlertMessageTitleFrontOffice" | translate }}
    </h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "AlertMessageTextFrontOffice" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-lg btn-danger"
      (click)="d('Cross click')"
    >
      {{ "ButtonNoFrontOffice" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-lg btn-success btn-confirm"
      (click)="exitBooking()"
    >
      {{ "ButtonYesFrontOffice" | translate }}
    </button>
  </div>
</ng-template>

<ng-template
  *ngIf="isCustomLayout == 0 || isCustomLayout == 1"
  #contentTerms
  let-c="close"
  let-d="dismiss"
>
  <div class="modal-header">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="currentColor"
      class="bi bi-info"
      viewBox="0 0 16 16"
    >
      <path
        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
      />
      <circle cx="8" cy="4.5" r="1" />
    </svg>
    <h6 class="modal-title" id="modal-basic-title">
      {{ "TitleTermsFrontOffice" | translate }}
    </h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body terms-modal-body" [innerHtml]="termContent"></div>
</ng-template>

<div class="body-custom" *ngIf="isCustomLayout == 3">
  <mat-sidenav-container
    class="qm-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
  >
    <mat-sidenav
      #sidenav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      class="qm-menu"
      [opened]="!mobileQuery.matches"
      [fixedInViewport]="true"
      [fixedTopGap]="0"
      [fixedBottomGap]="0"
    >
      <div class="sidenav-header">
        <div class="header-title">YKioskHotel</div>
        <mat-icon
          *ngIf="sidenavOpen"
          aria-hidden="false"
          class="qm-sidenav-toggle"
          (click)="sidenavToggle($event)"
        >
          menu_open
        </mat-icon>
        <mat-icon
          *ngIf="!sidenavOpen"
          aria-hidden="false"
          class="qm-sidenav-toggle"
          (click)="sidenavToggle($event)"
        >
          menu
        </mat-icon>
      </div>
      <mat-list>
        <mat-accordion class="menu-accordion">
          <mat-expansion-panel
            [expanded]="qmMenuDashboardExpanded"
            *ngIf="qmMenuDashboard.length > 0"
          >
            <mat-expansion-panel-header [ngClass]="{'qm-active-menu-expander': qmMenuDashboardExpanded, '': !qmMenuDashboardExpanded}">
              <mat-panel-title>
                <div class="menu-title">
                  <small>DASHBOARD</small>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item
                *ngFor="let item of qmMenuDashboard"
                matRipple
                [routerLink]="[item.Url]"
                routerLinkActive="qm-active-menu"
                class="qm-list-item"
              >
                <div class="qm-item-menu">
                  <mat-icon
                    *ngIf="item.IsDefaultIcon"
                    aria-hidden="false"
                    aria-label="item.Name"
                    >{{ item.Icon }}</mat-icon
                  >
                  <fa-icon
                    *ngIf="!item.IsDefaultIcon"
                    [icon]="item.CustomIcon"
                    mat-list-icon
                  ></fa-icon>
                  <small>{{ item.Name }}</small>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>

          <mat-expansion-panel
            [expanded]="qmMenuWalkinExpanded"
            *ngIf="qmMenuWalkin.length > 0"
          >
            <mat-expansion-panel-header [ngClass]="{'qm-active-menu-expander': qmMenuWalkinExpanded, '': !qmMenuWalkinExpanded}">
              <mat-panel-title>
                <div class="menu-title">
                  <small>GESTÃO DE WALK-IN</small>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item
                *ngFor="let item of qmMenuWalkin"
                matRipple
                [routerLink]="[item.Url]"
                routerLinkActive="qm-active-menu"
                class="qm-list-item"
              >
                <div class="qm-item-menu">
                  <mat-icon
                    *ngIf="item.IsDefaultIcon"
                    aria-hidden="false"
                    aria-label="item.Name"
                    >{{ item.Icon }}</mat-icon
                  >
                  <fa-icon
                    *ngIf="!item.IsDefaultIcon"
                    [icon]="item.CustomIcon"
                    mat-list-icon
                  ></fa-icon>
                  <small>{{ item.Name }}</small>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>

          <mat-expansion-panel
            *ngIf="qmMenuMoney.length > 0"
            [expanded]="qmMenuMoneyExpanded"
          >
            <mat-expansion-panel-header [ngClass]="{'qm-active-menu-expander': qmMenuMoneyExpanded, '': !qmMenuMoneyExpanded}">
              <mat-panel-title>
                <div class="menu-title">
                  <small>GESTÃO DE NUMERÁRIO</small>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item
                *ngFor="let item of qmMenuMoney"
                matRipple
                [routerLink]="[item.Url]"
                routerLinkActive="qm-active-menu"
                class="qm-list-item"
              >
                <div class="qm-item-menu">
                  <mat-icon
                    *ngIf="item.IsDefaultIcon"
                    aria-hidden="false"
                    aria-label="item.Name"
                    >{{ item.Icon }}</mat-icon
                  >
                  <fa-icon
                    *ngIf="!item.IsDefaultIcon"
                    [icon]="item.CustomIcon"
                    mat-list-icon
                  ></fa-icon>
                  <small>{{ item.Name }}</small>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>

          <mat-expansion-panel
            *ngIf="qmMenuUsers.length > 0"
            [expanded]="qmMenuUsersExpanded"
          >
            <mat-expansion-panel-header [ngClass]="{'qm-active-menu-expander': qmMenuUsersExpanded, '': !qmMenuUsersExpanded}">
              <mat-panel-title>
                <div class="menu-title">
                  <small>UTILIZADORES & PERMISSÕES</small>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item
                *ngFor="let item of qmMenuUsers"
                matRipple
                [routerLink]="[item.Url]"
                routerLinkActive="qm-active-menu"
                class="qm-list-item"
              >
                <div class="qm-item-menu">
                  <mat-icon
                    *ngIf="item.IsDefaultIcon"
                    aria-hidden="false"
                    aria-label="item.Name"
                    >{{ item.Icon }}</mat-icon
                  >
                  <fa-icon
                    *ngIf="!item.IsDefaultIcon"
                    [icon]="item.CustomIcon"
                    mat-list-icon
                  ></fa-icon>
                  <small>{{ item.Name }}</small>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>

          <mat-expansion-panel
            *ngIf="qmMenuOrganizations.length > 0"
            [expanded]="qmMenuOrganizationsExpanded"
          >
            <mat-expansion-panel-header [ngClass]="{'qm-active-menu-expander': qmMenuOrganizationsExpanded, '': !qmMenuOrganizationsExpanded}">
              <mat-panel-title>
                <div class="menu-title">
                  <small>ORGANIZAÇÃO</small>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item
                *ngFor="let item of qmMenuOrganizations"
                matRipple
                [routerLink]="[item.Url]"
                routerLinkActive="qm-active-menu"
                class="qm-list-item"
              >
                <div class="qm-item-menu">
                  <mat-icon
                    *ngIf="item.IsDefaultIcon"
                    aria-hidden="false"
                    aria-label="item.Name"
                    >{{ item.Icon }}</mat-icon
                  >
                  <fa-icon
                    *ngIf="!item.IsDefaultIcon"
                    [icon]="item.CustomIcon"
                    mat-list-icon
                  ></fa-icon>
                  <small>{{ item.Name }}</small>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>

          <mat-expansion-panel
            *ngIf="qmMenuSettings.length > 0"
            [expanded]="qmMenuSettingsExpanded"
          >
            <mat-expansion-panel-header [ngClass]="{'qm-active-menu-expander': qmMenuSettingsExpanded, '': !qmMenuSettingsExpanded}">
              <mat-panel-title>
                <div class="menu-title">
                  <small>DEFINIÇÕES</small>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item
                *ngFor="let item of qmMenuSettings"
                matRipple
                [routerLink]="[item.Url]"
                routerLinkActive="qm-active-menu"
                class="qm-list-item"
              >
                <div class="qm-item-menu">
                  <mat-icon
                    *ngIf="item.IsDefaultIcon"
                    aria-hidden="false"
                    aria-label="item.Name"
                    >{{ item.Icon }}</mat-icon
                  >
                  <fa-icon
                    *ngIf="!item.IsDefaultIcon"
                    [icon]="item.CustomIcon"
                    mat-list-icon
                  ></fa-icon>
                  <small>{{ item.Name }}</small>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-list>

      <div matRipple class="qm-user-login-container">
        <div
          *ngIf="currentUser"
          class="qm-item-menu"
          [matMenuTriggerFor]="menu"
        >
          <div>
            <mat-icon aria-hidden="false" aria-label="currentUser.UserName"
              >person</mat-icon
            >
            <small>{{ currentUser.UserName.split("@")[0] }}</small>
          </div>
        </div>
        <mat-menu #menu="matMenu">
          <button matRipple (click)="endSession()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            <span>Terminar Sessão</span>
          </button>
        </mat-menu>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar (click)="sidenavToggle($event)" class="qm-header">
        <mat-icon aria-hidden="false" style="margin-right: 10px">{{
          pageIcon
        }}</mat-icon>
        <small style="font-weight: 300; text-transform: uppercase">{{
          pageTitle
        }}</small>

        <span class="fill-header"></span>

        <ul *ngIf="showExtras" class="navbar-nav">
          <li matRipple class="nav-item">
            <mat-icon
              matBadge="2"
              matBadgeColor="warn"
              aria-hidden="false"
              aria-label="Notificações"
              >notifications
            </mat-icon>
          </li>
          <li matRipple class="nav-item">
            <mat-icon aria-hidden="false" aria-label="Menu">menu</mat-icon>
          </li>
          <li matRipple class="nav-item">
            <mat-icon aria-hidden="false" aria-label="Definições"
              >settings</mat-icon
            >
          </li>
        </ul>
      </mat-toolbar>

      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<app-loading
  [className]="
    isCustomLayout == 2 || isCustomLayout == 3 ? 'backoffice-loading' : ''
  "
  *ngIf="loadingData && loadingData.IsLoading"
></app-loading>
