export class SEFModel {
    guestName: string;
    guestFirstName: string;
    guestLastName: string;
    nationality: string;
    dateOfBirth: string;
    birthplace: string;
    documentIdentification: string;
    documentType: string;
    issuingCountry: string;
    checkin: string;
    checkout: string;
    countryOfResidence: string;
    residenceCity: string;
    mobileNumber: string;
    email: string;
    documentImage: string;
    yKioskBookingId: number;
    yKioskSEFDocumentId: number;
    yKioskStateId: number;
    isAdult: number;
    localId: number;
    isProductionMode: number;
    colibriGuestId: number;
    guestGender: string;
    idExpireDate: string;
}