import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreDataService {

  cb:string;
  guests:string;
  currentGuest:string;
  quiosqueInfo:any;

  @Output() change: EventEmitter<any> = new EventEmitter();

  setQuiosqueInfo(quiosqueInfo: any) {
    const $this = this;
    $this.quiosqueInfo = quiosqueInfo;
    $this.change.emit($this.quiosqueInfo);
  }

  constructor() { }
}
