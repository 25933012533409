export enum MessageType {
  EncodeError,
  NoAssociatedRoom,
  RoomUnavailable,
  EndMessage,
  EarlyCheckinFee
}

export const MessageTypeLabel = new Map<MessageType, string>([
  [MessageType.EncodeError, 'Erro no Encoding do Cartão'],
  [MessageType.NoAssociatedRoom, 'Sem Quarto Associado'],
  [MessageType.RoomUnavailable, 'Quarto Indisponível'],
  [MessageType.EndMessage, 'Mensagem Final do Check-in'],
  [MessageType.EarlyCheckinFee, 'Taxa check-in antecipado']
]);