<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="box">
      <mat-card-header>
        <mat-card-title>YKioskHotel</mat-card-title>
      </mat-card-header>
  
      <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onLogin()">
        <mat-card-content>
          <mat-form-field class="input-full-width">
            <input matInput formControlName="username" placeholder="Username">
          </mat-form-field>
  
          <mat-form-field class="input-full-width">
            <input type="password" formControlName="password" matInput placeholder="Password">
          </mat-form-field>
        </mat-card-content>
        <button mat-raised-button matRipple [disabled]="!loginForm.valid" class="btn-login" color="primary">LOGIN</button>
      </form>
    </mat-card>
  
  </div>