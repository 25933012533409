import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserAuthenticated } from 'src/app/shared/models/user-authenticated/user-authenticated';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public userAuthenticated = false;
  private currentUserSubject: BehaviorSubject<UserAuthenticated>;
  public currentUser: Observable<UserAuthenticated>;

  constructor(private http: HttpClient) {

    const $this = this;

    $this.currentUserSubject = new BehaviorSubject<UserAuthenticated>(JSON.parse(localStorage.getItem('currentUser')));
    $this.currentUser = $this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): UserAuthenticated {
    return this.currentUserSubject.value;
  }

  public login(login) {

    const $this = this;

    return $this.http.post(environment.apiUrl + 'api/v1/Login/LoginBackofficeOnline', login).pipe(map(resp => {
      $this.setSession(resp);
      return resp;
    }));

  }

  public getSecurityToken() {
    return localStorage.getItem("ykioskhotel_dashboard_token");
  }

  public logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  private setSession(authResult) {
    const currentUser: UserAuthenticated = {
      UserName: authResult.username,
      Token: authResult.token,
      IsAdministrator: authResult.isAdmin,
      IsSuperUser: authResult.isSuperUser,
      LanguageId: authResult.languageId,
      Expires: moment(authResult.expires),
      SubcompanyId: authResult.subcompanyId,
      CompanyId: authResult.companyId
    }

    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);

  }

  public get isExpired(): boolean {
    return moment() > moment(this.currentUserValue.Expires);
  }
}
