import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-view',
  templateUrl: './not-found-view.component.html',
  styleUrls: ['./not-found-view.component.css']
})
export class NotFoundViewComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  prevPage() {
    const $this = this;
    $this.router.navigate(['/search-booking']);
  }

}
