<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'MessageTitleNewGuestViewFrontOffice' | translate }}</h3>
  </div>

  <div class="row">
    <div class="col">
      <div class="kiosk-container">

        <div class="result-container">
          <svg xmlns="http://www.w3.org/2000/svg" class="new-user-svg" width="10em" height="10em" fill="currentColor"
            class="bi bi-person-plus-fill" viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            <path fill-rule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
          </svg>
          <p>{{ 'MessageDescriptionNewGuestViewFrontOffice' | translate }}</p>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
    </div>
    <div class="col text-center">
      <button (click)="nextPage()" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonNextFrontOffice' | translate }}</button>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">

    </div>
    <div class="col text-center">

    </div>
  </div>

</div>
