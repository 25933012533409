<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header"></h3>
  </div>

  <div class="row">
    <div class="col">
      <div class="kiosk-container">
        <div class="row">
          <p class="welcome-msg">{{ 'WelcomeFrontOffice' | translate }}</p>
        </div>
        <div class="row">
          <p class="local-name">{{ localName }}</p>
        </div>
        <div class="row">
          <button [routerLink]="['/search-booking']" type="button"
            class="btn btn-dark btn-regist">{{ 'CheckinButtonFrontOffice' | translate }}
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right-circle" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path fill-rule="evenodd"
                d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
    </div>
    <div class="col text-center">
    </div>
  </div>

  <div class="row">
    <div class="col text-center">

    </div>
    <div class="col text-center">

    </div>
  </div>

</div>
