import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { LocalService } from 'src/app/services/local/local.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LocalModel } from 'src/app/shared/models/LocalModel.model';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-locals',
  templateUrl: './locals.component.html',
  styleUrls: ['./locals.component.scss']
})
export class LocalsComponent implements AfterViewChecked {

  displayedColumns: string[] = ['select', 'id', 'name', 'pmsIntegrationType', 'sefIntegrationEnable'];
  dataSource: MatTableDataSource<LocalModel>;
  editMode: boolean = false;
  numSelected: number;
  columnList: string[];
  filters: FiltersModel = {
    ColumnsToSearch: [],
    CurrentPage: 0,
    PageSize: 10,
    OrderColumn: null,
    OrderType: null,
    SearchText: null,
    FilterTags: []
  };
  localsLimits: number;
  addLocal: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService, private localService: LocalService, private companyService: CompanyService,
    private editDataServiceService: EditDataServiceService, private cdRef: ChangeDetectorRef) {

    const $this = this;

    $this.loadingService.toggle("");

    $this.localService.GetAllColumnNames().subscribe((resp: string[]) => {
      $this.columnList = resp;
    });

    $this.companyService.GetCompanyLimits().subscribe(resp => {
      $this.localsLimits = resp.localsLimits;
      $this.refreshList();
    });
  }

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const $this = this;
    if ($this.dataSource == null || $this.dataSource == undefined) {
      return false;
    } else {
      $this.numSelected = $this.selection.selected.length;
      const numRows = $this.dataSource.data.length;
      return $this.numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngAfterViewChecked() {
    const $this = this;
    $this.cdRef.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete() {
    const $this = this;
    const selectedIds = $this.selection.selected.map(({ id }) => id);
    $this.loadingService.toggle("");
    $this.localService.DeleteListOfLocals(selectedIds).subscribe(() => {
      $this.refreshList();
    });
  }

  edit() {
    const $this = this;
    $this.editDataServiceService.localIdToEdit = $this.selection.selected[0]['id'];
    $this.router.navigate(["backoffice/locals/local-form"]);
  }

  addNew() {
    const $this = this;
    $this.editDataServiceService.localIdToEdit = 0;
    $this.router.navigate(["backoffice/locals/local-form"]);
  }

  search() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.refreshList();
  }

  refreshList() {
    const $this = this;

    if($this.paginator != null){
      $this.filters.PageSize = $this.paginator.pageSize;
      $this.filters.CurrentPage = $this.paginator.pageIndex;
    }

    $this.selection = new SelectionModel<any>(true, []);
    $this.numSelected = 0;
    $this.localService.GetAllLocals($this.filters).subscribe((resp) => {
      if (resp != null) {
        $this.dataSource = new MatTableDataSource(resp.locals);
        $this.addLocal = resp.itemsCounter < $this.localsLimits;
        $this.dataSource.paginator = $this.paginator;
        $this.dataSource.sort = $this.sort;
        $this.loadingService.toggle("");
      }
    });
  }
}
