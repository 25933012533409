import { LanguageModel } from "./LanguageModel.model";
import { MessageType } from "./Utilities/MessageType";

export class AppMessageModel {
    id: number;
    name: string;
    body: string;
    language: LanguageModel;
    languageId: number;
    subcompanyId: number;
    messageType: MessageType;
}