import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoadingService } from '../services/loading/loading.service';
import { ColibriConnectionService } from '../services/colibriConnection/colibri-connection.service';
import { StoreDataService } from '../services/storeData/store-data.service';
import { UtilitiesService } from '../services/utilities-service/utilities.service';

@Component({
  selector: 'app-direct-search',
  templateUrl: './direct-search.component.html',
  styleUrls: ['./direct-search.component.css']
})
export class DirectSearchComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private loadingService: LoadingService,
    private storeDataService: StoreDataService, private colibriConnectionService: ColibriConnectionService, private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
    let $this = this;
    $this.loadingService.toggle("");

    const bN = $this.route.snapshot.paramMap.get('bN');
    const gN = $this.route.snapshot.paramMap.get('gN');

    $this.colibriConnectionService.SearchReservation(bN, gN, '', '').subscribe((resp) => {
      $this.loadingService.toggle("");
      if (!$this.utilitiesService.isNullOrEmpty(resp.bookingNumber)) {
        $this.storeDataService.cb = JSON.stringify(resp);
        $this.router.navigate(['/search-result']);
      } else {
        $this.router.navigate(['/booking-not-found']);
      }

    });

  }

}
