import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoomPhotoModel } from 'src/app/shared/models/room-photo-model';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-photo-preview-dialog',
  templateUrl: './photo-preview-dialog.component.html',
  styleUrls: ['./photo-preview-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [
        animate('300ms ease-out')
      ]),
      transition('out => in', [
        animate('300ms ease-in')
      ])
    ])
  ]
})
export class PhotoPreviewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PhotoPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { photos: RoomPhotoModel[], currentIndex: number }
  ) { }


  fadeState: string = 'in';

  onNoClick(): void {
    this.dialogRef.close();
  }

  prevPhoto(): void {
    const $this = this;
    if ($this.data.currentIndex > 0 && $this.fadeState == 'in') {
      $this.toggleFade(() => {
        $this.data.currentIndex--;
      });
    }
  }

  nextPhoto(): void {
    const $this = this;
    if ($this.data.currentIndex < $this.data.photos.length - 1 && $this.fadeState == 'in') {
      $this.toggleFade(() => {
        $this.data.currentIndex++;
      });
    }
  }

  toggleFade(callback: () => void) {
    const $this = this;
    $this.fadeState = 'out';
    setTimeout(() => {
      callback();
      $this.fadeState = 'in';
    }, 300);
  }
}
