import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BoardTypesService } from 'src/app/services/boardTypes/board-types.service';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { LanguageModel } from 'src/app/shared/models/LanguageModel.model';
import { TranslationModel } from 'src/app/shared/models/TranslationModel.model';
import { BoardTypeModel } from 'src/app/shared/models/board-type-model';
import { DocumentStateType } from 'src/app/shared/models/enums/document-state-type';
import { TranslationStateType } from 'src/app/shared/models/enums/translation-state-type';
import { TranslationType } from 'src/app/shared/models/enums/translation-type';
import * as _ from 'underscore';

@Component({
  selector: 'app-board-type-form',
  templateUrl: './board-type-form.component.html',
  styleUrls: ['./board-type-form.component.scss']
})

export class BoardTypeFormComponent implements AfterContentChecked, OnInit {
  boardType: BoardTypeModel = {
    id: 0,
    name: null,
    subcompanyId: 0,
    state: DocumentStateType.Unknown
  };
  arrayOfRequests: any[] = [];
  enableSave: boolean = true;
  subcompanies: DictionaryModel[];
  languages: LanguageModel[];
  translations: TranslationModel[] = [];
  documentStateTypes = DocumentStateType;
  isEdit: boolean = false;

  constructor(private boardTypesService: BoardTypesService, private loadingService: LoadingService, private dictionariesService: DictionariesService,
    private editDataServiceService: EditDataServiceService, private router: Router, private cdref: ChangeDetectorRef, private utilitiesService: UtilitiesService,
    private languageService: LanguageService, private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    const $this = this;
    $this.loadingService.toggle("");


    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());
    $this.arrayOfRequests.push($this.languageService.GetLanguages());

    if ($this.editDataServiceService.boardTypeIdToEdit != 0) {
      $this.isEdit = true;
      $this.arrayOfRequests.push($this.boardTypesService.GetBoardTypeById($this.editDataServiceService.boardTypeIdToEdit));
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 1:
              $this.languages = resp as LanguageModel[];
              if ($this.editDataServiceService.boardTypeIdToEdit == 0) {
                $this.renderTranslations();
              }
              break;
            case 2:
              if ($this.editDataServiceService.boardTypeIdToEdit != 0) {
                let currentBoardType = resp as BoardTypeModel;
                if (currentBoardType != null) {
                  $this.boardType = $this.toLowerKeys(currentBoardType) as BoardTypeModel;
                }

                $this.editDataServiceService.boardTypeIdToEdit = 0;
                $this.renderTranslations();
                $this.checkFields();
              }
              break;
          }
        }
        $this.loadingService.toggle("");
      });
  }

  renderTranslations() {

    const $this = this;
    for (let i = 0; i < $this.languages.length; i++) {
      const currentLanguage = $this.languages[i];
      let currentTranslation: TranslationModel = null;
      if ($this.boardType.translations == null) {
        $this.boardType.translations = [];
      }
      currentTranslation = _.findWhere($this.boardType.translations, { languageId: currentLanguage.id });

      if (currentTranslation != null && currentTranslation != undefined) {
        $this.translations.push(currentTranslation);
      } else {
        $this.translations.push({
          id: 0,
          description: '',
          translationKey: 'BoardType_Name',
          languageId: currentLanguage.id,
          state: TranslationStateType.Active,
          type: TranslationType.BoardType,
          boardTypeId: 0
        });
      }

      this.translations.forEach(translation => {
        this.translationMap.set(translation.languageId, translation.description);
      });

      // Manually trigger change detection
      this.cdr.detectChanges();
    }

  }

  translationMap: Map<number, string> = new Map();

  getTranslation(languageId: number): string {
    return this.translationMap.get(languageId) || '';
  }

  setTranslation(languageId: number, description: string) {
    this.translationMap.set(languageId, description);
    const translation = this.translations.find(t => t.languageId === languageId);
    if (translation) {
      translation.description = description;
    }
    this.cdr.detectChanges();
  }

  trackByLanguageId(index: number, language: any): number {
    return language.id;
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.boardType);
    const notRequiredFields = ['id', 'translations', 'subcompany'];
    const requiredNumber = [];

    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.boardType[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.boardType[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  ngAfterContentChecked(): void {
    const $this = this;
    $this.checkFields();
    $this.cdref.detectChanges();
  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  clear() {
    const $this = this;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/board-types"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.boardType.translations = $this.translations;
    $this.boardTypesService.SaveBoardType($this.boardType).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/board-types"]);
    });
  }
}
