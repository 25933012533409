<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px;">
        <div class="row row-form">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <ngx-mat-file-input [(ngModel)]="fileInput" accept="application/JSON"
                placeholder="Ficheiro de Dados a Importar (*json)"></ngx-mat-file-input>
              <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div class="row row-form">
          <div class="col-xl-12 col-md-12 col-sm-12 align-center">
            <button class="form-button" (click)="uploadData()" [disabled]="fileInput == null" mat-raised-button
              color="accent">
              <mat-icon aria-hidden="false">upload_file</mat-icon>
              IMPORTAR
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">

      </div>
    </div>
  </mat-card>
</div>
