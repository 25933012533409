export enum PeriodType {
    None,
    OpenPeriod,
    ClosePeriod
}

export const PeriodTypeLabel = new Map<PeriodType, string>([
    [PeriodType.None, 'Erro no Encoding do Cartão'],
    [PeriodType.OpenPeriod, 'Abertura de Período'],
    [PeriodType.ClosePeriod, 'Fecho de Período']
  ]);
